import {
  Avatar,
  Box,
  Container,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import CardBackground from "../../Components/CardBackground";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../config/firebase";
import UserDataContext from "../../Context/UserDataContext";
import { defaultChat } from "../../data/defaultObejcts";
import { colours } from "../../config/colours";
import { componentDimensions } from "../../config/dimensions";
import {
  getDifferenceInDays,
  getDifferenceInHours,
  getTimestampAsOptimizedString,
  getTimestampAsString,
} from "../../Functions/utilFunctions";
import { Circle, Send } from "@mui/icons-material";
import {
  addFirestoreDoc,
  addMessage,
  useChats,
  useMessages,
} from "../../Functions/firebaseFunctions";
import { useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NavigatorContext } from "../../Context/NavigatorContext";

/**
 * @link https://blog.logrocket.com/how-to-build-chatroom-app-react-firebase/
 * @param {*} uid
 * @param {*} callback
 * @returns
 */
/* function getChats(uid, callback) {
  console.log("getting chats");
  const docRef = query(
    collection(db, "chats"),
    where("members", "array-contains", uid),
    orderBy("lastMessageTimestamp", "desc")
  );

  return onSnapshot(docRef, (querySnapshot) => {
    const chats = querySnapshot.docs.map((doc) => ({
      ...defaultChat,

      otherUserUID:
        doc.data().members[0] === uid
          ? doc.data().members[1]
          : doc.data().members[0],
      ...doc.data(),
      id: doc.id,
    }));

    callback(chats);
  });
}
function useChats(uid) {
  const [chats, setChats] = useState([]);
  useEffect(() => {
    const unsub = getChats(uid, setChats);
    return unsub;
  }, [uid]);
  return chats;
}
function getMessages(chatID, callback) {
  console.log(chatID == null);
  if (chatID != null) {
    try {
      console.log("chatID is " + chatID);
      const docRef = query(
        collection(db, "chats", chatID, "messages"),
        orderBy("timeStamp", "asc")
      );
      return onSnapshot(docRef, (querySnapshot) => {
        const messages = querySnapshot.docs?.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        callback(messages);
      });
    } catch (error) {
      console.log(error);
    }
  }
}
function useMessages(chatID) {
  const [messages, setMessages] = useState([]);
  useEffect(() => {
    const unsub = getMessages(chatID, setMessages);
    return unsub;
  }, [chatID]);
  return messages;
} */
const ChatListItem = ({
  onClick,
  primary,
  secondary,
  photoURL,
  selected,
  indicator,
}) => {
  return (
    <ListItemButton onClick={onClick} selected={selected}>
      {indicator && (
        <Circle
          style={{
            inlineSize: "6px",
            position: "absolute",
            left: "5px",
            color: colours.blue500,
          }}
        />
      )}
      <ListItemAvatar>
        <Avatar src={photoURL} />
      </ListItemAvatar>
      <ListItemText
        primaryTypographyProps={{
          fontsize: "14px",
          fontWeight: 500,
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        secondary={secondary}
        secondaryTypographyProps={{
          style: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "12px",
            fontWeight: 400,
            color: colours.black300,
          },
        }}
      >
        {primary}
      </ListItemText>
    </ListItemButton>
  );
};
const MessageBox = ({
  messageBody,
  date,
  position,
  onClick,
  photoURL,
  shouldDisplayDate,
  shouldDisplayTime,
}) => {
  return (
    <>
      {shouldDisplayDate && (
        <Divider>
          <Typography variant="body6">
            {getTimestampAsString(date, "MMM dd")}
          </Typography>
        </Divider>
      )}
      <Box sx={{ display: "flex", padding: "2px" }} onClick={onClick}>
        {position === "left" && (
          <Avatar src={photoURL} sx={{ marginRight: "10px" }} />
        )}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/**message display */}
          <Box
            sx={{
              backgroundColor:
                position === "right" ? colours.blue200 : colours.white500,
              borderRadius: "8px",
              borderTopRightRadius: position === "right" ? 0 : null,

              borderTopLeftRadius: position === "left" ? 0 : null,
              boxShadow: 2,
              padding: componentDimensions.spacing.small.pixels,
            }}
          >
            <Typography variant="body5">{messageBody}</Typography>
          </Box>

          {shouldDisplayTime && (
            <Typography variant="body6">
              {getTimestampAsString(date, "h:mm aaaa")}
            </Typography>
          )}
        </Box>
        {position === "right" && <Avatar sx={{ marginLeft: "10px" }} />}
      </Box>
    </>
  );
};
function MessageInput({ onSendMessage }) {
  const [message, setMessage] = useState();
  const fieldRef = useRef();
  return (
    <Box
      sx={{
        paddingY: componentDimensions.spacing.small.pixels,
      }}
    >
      <TextField
        ref={fieldRef}
        onChange={(e) => {
          setMessage(e.target.value);

          //console.log(e.target.value);
        }}
        placeholder="Type a message..."
        fullWidth
        multiline
        value={message}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                disabled={message == ""}
                onClick={() => {
                  //console.log(message);
                  onSendMessage(message);
                  setMessage("");
                }}
              >
                <Send />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
}
const getOtherUserData = (chat, userID) => {
  let userData = { displayName: "Anonymous", photoURL: null };
  try {
    if (chat?.members[0] === userID) {
      userData = { ...userData, ...chat.membersData[chat.members[1]] };
    } else {
      userData = { ...userData, ...chat.membersData[chat.members[0]] };
    }
  } catch (error) {
    console.log(error);
  }
  console.log(userData);
  return userData;
};
function Messages() {
  const { showPathTitle, setPathTitle } = useContext(NavigatorContext);

  const { userID } = useContext(UserDataContext);
  const navigate = useNavigate();
  const { chatid } = useParams();
  const chats = useChats(userID);
  const [selectedChatID, setSelectedChatID] = useState(null);
  const messages = useMessages(chatid);
  const [displayHeight, setDisplayHeight] = useState(0);
  useEffect(() => {
    setPathTitle("Messages");
  }, []);
  useEffect(() => {
    if (showPathTitle) {
      setDisplayHeight(
        window.innerHeight -
          componentDimensions.appbarHeight.default -
          componentDimensions.titleBarHeight.default -
          componentDimensions.spacing.small.default * 5 +
          "px"
      );
    } else {
      setDisplayHeight(
        window.innerHeight -
          componentDimensions.appbarHeight.default -
          componentDimensions.spacing.small.default * 5 +
          "px"
      );
    }
  }, [showPathTitle]);

  return (
    <Box
      sx={{
        display: "flex",
        height: displayHeight,
        minWidth:
          window.innerWidth -
          componentDimensions.drawerWidth.default -
          componentDimensions.spacing.small.default * 5,
      }}
    >
      {/**List of chats */}

      <CardBackground
        showTitleBar={showPathTitle}
        sx={{
          maxWidth: "300px",
          // /paddingRight: 0,
          marginRight: componentDimensions.spacing.small.pixels,
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: "100%",
            overflowX: "hidden",
            overflowY: "scroll",
          }}
        >
          <List
            sx={{
              "& .MuiListItemText": {
                primary: { fontsize: "16px", fontWeight: 500 },
                secondary: {
                  fontSize: "10px",
                  fontWeight: 400,
                  color: colours.black300,
                },
              },
            }}
          >
            {chats.length <= 0 && (
              <Typography variant="body4">
                You don't have any active chats. When you begin a conversation
                with a Nomad it will show up here!
              </Typography>
            )}
            {chats.map((chat, index) => {
              return (
                <ChatListItem
                  selected={chatid === chat.id}
                  primary={chat.membersData[chat.otherUserUID]?.displayName}
                  //primary={chat.id}
                  photoURL={chat.membersData[chat.otherUserUID]?.photoURL}
                  indicator={!chat.read && chat.lastMessageSender != userID}
                  secondary={chat.lastMessage}
                  key={index}
                  onClick={() => {
                    navigate("../messages/" + chat.id);
                    console.log(chats);
                  }}
                />
              );
            })}
          </List>
        </Box>
      </CardBackground>
      {/**Single chat view*/}

      <CardBackground sx={{ flex: 1 }}>
        {!chatid && <Typography variant="body4">No chat selected</Typography>}
        {chatid && (
          <Box
            sx={{
              // overflowY: "scroll",
              //paddingBottom: 30,
              flexDirection: "column",
              display: "flex",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <Container sx={{ overflowY: "scroll" }}>
              {messages.map((msg, index) => {
                return (
                  <>
                    {index > 0 &&
                      getDifferenceInHours(
                        messages[index - 1].timeStamp,
                        msg.timeStamp
                      ) >= 1 && (
                        <Divider
                          textAlign="left"
                          sx={{
                            marginBottom:
                              componentDimensions.spacing.small.pixels,
                          }}
                        >
                          <Typography variant="body6">
                            {getTimestampAsOptimizedString(msg.timeStamp)}
                          </Typography>
                        </Divider>
                      )}

                    <MessageBox
                      photoURL={msg.sender.photoURL}
                      messageBody={msg.messageBody}
                      date={msg.timeStamp}
                      position={msg.sender.uid == userID ? "right" : "left"}
                      key={index}
                      shouldDisplayDate={
                        getDifferenceInDays(
                          msg.timeStamp,
                          messages[index + 1]?.timeStamp
                        ) > 1
                      }
                      shouldDisplayTime={
                        getDifferenceInHours(
                          msg.timeStamp,
                          messages[index + 1]?.timeStamp
                        ) > 1 ||
                        messages[index + 1]?.sender?.uid != msg.sender.uid
                      }
                    />
                  </>
                );
              })}
            </Container>
            <MessageInput
              onSendMessage={(message) => {
                addMessage(chatid, userID, message, new Date());
              }}
            />
          </Box>
        )}
      </CardBackground>
    </Box>
  );
}

export default Messages;
