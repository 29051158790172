import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import { componentDimensions } from "../../config/dimensions";
import UserDataContext from "../../Context/UserDataContext";
import { NavigatorContext } from "../../Context/NavigatorContext";
import { Edit } from "@mui/icons-material";
import Spacer from "../../Components/Spacer";
import DataDisplay from "../../Components/DataDisplay";
import {
  getTimestampAsDate,
  getTimestampAsString,
} from "../../Functions/utilFunctions";
import { ScreenContainer } from "../../Components/ScreenContainer";
import AccountSetup from "./AccountSetup";
import ProfileSetupDialog from "../../Components/ProfileSetupDialog";
import EditProfileDialog from "../../Components/EditProfileDialog";

function MyAccount(props) {
  const { userData } = useContext(UserDataContext);
  const { setPathTitle, setSecondaryDrawerOpen } = useContext(NavigatorContext);
  const [editMode, setEditMode] = useState(false);
  useEffect(() => {
    setPathTitle("My Account");
    setSecondaryDrawerOpen(false);
  }, []);
  const handleEdit = () => {
    setEditMode(true);
  };
  return (
    <ScreenContainer>
      <EditProfileDialog
        open={editMode}
        onDismiss={() => {
          setEditMode(false);
        }}
      />
      <Grid container rowSpacing={componentDimensions.spacing.medium.pixels}>
        <Grid item>
          <Avatar
            sx={{ width: "159px", height: "159px" }}
            src={userData.photoURL}
          />
        </Grid>
        <Grid item>
          <Box sx={{ padding: componentDimensions.spacing.small.pixels }}>
            <Stack
              direction={"row"}
              spacing={componentDimensions.spacing.small.pixels}
              sx={{
                marginBottom: componentDimensions.spacing.small.pixels,
              }}
            >
              <Typography
                variant="h5"
                sx={{ display: "flex", justifyContent: "center" }}
              >
                {userData.displayName}
              </Typography>
            </Stack>
            <Stack
              spacing={componentDimensions.spacing.small.pixels}
              direction="row"
            >
              {/*  <ButtonGroup variant="contained">
      <Button
        onClick={handleClick}
        variant={tagged ? "text" : "contained"}
        startIcon={tagged == "favourite" ? <Favorite /> : null}
      >
        {" "}
        {tagged == "favourite" ? "Favourite" : "Make a Decision"}
      </Button>
      <Button onClick={handleClick} sx={{ padding: 0 }}>
        <ArrowDropDown />
      </Button>
    </ButtonGroup> */}
            </Stack>{" "}
            <Typography variant={"body4"}>{userData.bio}</Typography>
            <Spacer count={3} />
            <Button
              variant={"contained"}
              color={"secondary"}
              startIcon={<Edit />}
              onClick={handleEdit}
            >
              Edit Profile
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Spacer />
        </Grid>
        <Grid
          container
          item
          xs={6}
          rowSpacing={componentDimensions.spacing.medium.pixels}
        >
          <DataDisplay leftLabel={"Email"} rightLabel={userData.email} />
          <DataDisplay leftLabel={"Industry"} rightLabel={userData.industry} />
          <DataDisplay
            leftLabel={"Company Name"}
            rightLabel={userData.companyName}
          />
          <DataDisplay
            leftLabel={"Member since"}
            rightLabel={getTimestampAsString(userData.registerDate)}
          />
        </Grid>
      </Grid>
    </ScreenContainer>
  );
}

MyAccount.propTypes = {};

export default MyAccount;
