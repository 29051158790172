import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { ControlPointSharp } from "@mui/icons-material";
import { fromAddress, setDefaults } from "react-geocode";
import { componentDimensions } from "../config/dimensions";
import { defaultLocation } from "../data/defaultObejcts";
import UserDataContext from "../Context/UserDataContext";

function LocationForm({ locationValues, setLocationValues, control }) {
  // const [locationValues, setLocationValues] = useState(defaultLocation);

  const { locations } = useContext(UserDataContext);
  return (
    <Grid item container spacing={componentDimensions.spacing.small.pixels}>
      <Grid item xs={12}>
        <Typography variant="body" sx={{ wordWrap: "pre-line" }}>
          {
            "Start by adding a business location.\n For now employers are limited to one location, but in the future you will be able to add multiple. "
          }
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormLabel>Name of Business</FormLabel>
        <Controller
          control={control}
          name="name"
          render={({ field: { ref, ...rest } }) => (
            <TextField
              required
              fullWidth
              //value={locationValues.address}
              placeholder="Business Name"
              {...rest}
            ></TextField>
          )}
        ></Controller>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormLabel>Country</FormLabel>
        <Controller
          control={control}
          name="country"
          render={({ field: { ref, ...rest } }) => (
            <TextField
              required
              fullWidth
              //value={locationValues.address}
              placeholder="Country"
              {...rest}
            ></TextField>
          )}
        ></Controller>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormLabel>Province</FormLabel>
        <Controller
          control={control}
          name="province"
          render={({ field: { ref, ...rest } }) => (
            <TextField
              required
              fullWidth
              //value={locationValues.address}
              placeholder="Province"
              {...rest}
            ></TextField>
          )}
        ></Controller>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormLabel>City</FormLabel>
        <Controller
          control={control}
          name="city"
          render={({ field: { ref, ...rest } }) => (
            <TextField
              required
              fullWidth
              //value={locationValues.address}
              placeholder="City"
              {...rest}
            ></TextField>
          )}
        ></Controller>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormLabel>Postal Code</FormLabel>
        <Controller
          control={control}
          name="postalCode"
          render={({ field: { ref, ...rest } }) => (
            <TextField
              required
              fullWidth
              //value={locationValues.address}
              placeholder="Postal Code"
              {...rest}
            ></TextField>
          )}
        ></Controller>
      </Grid>
      <Grid item xs={12}>
        <FormLabel>Address</FormLabel>
        <Controller
          control={control}
          name="address"
          render={({ field: { ref, ...rest } }) => (
            <TextField
              required
              fullWidth
              //value={locationValues.address}
              placeholder="Enter Address..."
              {...rest}
            ></TextField>
          )}
        ></Controller>
      </Grid>
    </Grid>
  );
}

LocationForm.propTypes = {};

export default LocationForm;
