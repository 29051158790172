import React, { useContext } from "react";
import UserDataContext from "../Context/UserDataContext";
import { Button } from "@mui/material";

function DebugButtons({ children, onClick }) {
  const { debugMode } = useContext(UserDataContext);
  return (
    <Button
      style={{ display: debugMode ? "block" : "none" }}
      onClick={() => {
        console.log(children);
        onClick && onClick();
      }}
    >
      {typeof children == "string" ? children : "DEBUG"}
    </Button>
  );
}

export default DebugButtons;
