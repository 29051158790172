export const componentDimensions = {
  drawerWidth: { pixels: "104px", default: 104 },
  secondaryDrawerWidth: { pixels: "201px", default: 201 },
  appbarHeight: { pixels: "67px", default: 67 },
  titleBarHeight: { pixels: "88px", default: 88 },
  spacing: {
    xs: { pixels: "4px", default: 4 },
    small: { pixels: "14px", default: 14 },
    medium: { pixels: "24px", default: 24 },
    large: { pixels: "34px", default: 34 },
  },
  textSize: { small: "14px", medium: "20px", large: "24px" },
};
export const fullSize = {
  minHeight:
    window.innerHeight -
    componentDimensions.appbarHeight.default -
    componentDimensions.spacing.medium.default * 2,

  minWidth:
    window.innerWidth -
    componentDimensions.drawerWidth.default -
    componentDimensions.secondaryDrawerWidth.default -
    componentDimensions.spacing.medium.default * 2,
};
export const fullWidth =
  window.innerWidth -
  componentDimensions.drawerWidth.default -
  componentDimensions.secondaryDrawerWidth.default -
  componentDimensions.spacing.medium.default * 2;
