import { createBrowserRouter, Navigate, Outlet } from "react-router-dom";
import React from "react";
import ErrorPage from "../Screens/ErrorPage";
import Logout from "../Screens/Logout";
import ComingSoon from "../Screens/ComingSoon";
import Shifts from "../Screens/Employer/Shifts";
import Payments from "../Screens/Employer/Payments";
import EmployerDashboard from "../Screens/Employer/EmployerDashboard";
import Locations from "../Screens/Employer/Locations";
import Applicants from "../Screens/Employer/Applicants";
import Navigator from "../Components/Navigator";
import AddPosition from "../Screens/Employer/AddPosition";
import FilledShifts from "../Screens/Employer/FilledShifts";
import UnfilledShifts from "../Screens/Employer/UnfilledShifts";
import CompletedShifts from "../Screens/Employer/CompletedShifts";
import AccountSetup from "../Screens/Employer/AccountSetup";
import UnfilledShift from "../Screens/Employer/UnfilledShift";
import Positions from "../Screens/Employer/Positions";
import AddShift from "../Screens/Employer/AddShift";

import NomadApplicationAccount from "../Screens/Employer/NomadApplicationAccount";
import Messages from "../Screens/Employer/Messages";
import { NavigatorProvider } from "../Context/NavigatorContext";
import NomadAccountPreview from "../Screens/Employer/NomadAccountPreview";
import Notifications from "../Screens/Employer/Notifications";
import MyAccount from "../Screens/Employer/MyAccount";

const drawerWidth = "122px";

const NavigationUI = () => {
  return (
    <NavigatorProvider>
      <Navigator>
        <Outlet />
      </Navigator>
    </NavigatorProvider>
  );
};
export const employerRouter = createBrowserRouter([
  {
    element: <NavigationUI />,
    children: [
      { path: "/accountsetup", element: <AccountSetup /> },
      {
        path: "/EmployerDashboard",
        element: <EmployerDashboard />,
      },
      { path: "/Notifications", element: <Notifications /> },
      { path: "/Nomads/:nomadid", element: <NomadApplicationAccount /> },
      { path: "/MyAccount", element: <MyAccount /> },
      {
        path: "/Shifts",
        element: <Shifts></Shifts>,
        children: [
          {
            path: "",
            element: <Positions />,
          },
          { path: ":positionID/addshift", element: <AddShift /> },
          {
            path: "addposition",
            element: <AddPosition />,
          },
          {
            path: "filled",
            element: <FilledShifts />,
            children: [{ path: ":nomadid", element: <NomadAccountPreview /> }],
          },
          {
            path: "unfilled",
            element: <UnfilledShifts />,
          },
          {
            path: "unfilled/:shiftId",
            element: <UnfilledShift />,
            children: [
              { path: ":applicantid", element: <NomadApplicationAccount /> },
            ],
          },
          {
            path: "completed",
            element: <CompletedShifts />,
          },
          //{ path: "*", element: <Navigate to="." replace /> },
        ],
      },
      { path: "/Locations", element: <Locations /> },
      { path: "/Payments", element: <Payments /> },
      { path: "/Shifts/unfilled/sampleunfilled", element: <UnfilledShift /> },
      { path: "/Applicants", element: <Applicants /> },
      { path: "/messages", element: <Messages /> },
      { path: "/messages/:chatid", element: <Messages /> },
      { path: "/Logout", element: <Logout /> },
      // { path: "/signin", element: <Navigate to="/" replace /> },
      //{ path: "/register", element: <Navigate to="/" replace /> },

      { path: "*", element: <ErrorPage /> },
      { path: "/", element: <EmployerDashboard /> },
    ],
  },
]);
