import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import RightArrow from "@mui/icons-material/ChevronRight";
import BackArrow from "@mui/icons-material/ChevronLeft";
import { componentDimensions } from "../../config/dimensions";
import { Check } from "@mui/icons-material";
import { colours } from "../../config/colours";
import { useNavigate, useParams } from "react-router-dom";
import AddNewShift from "./AddNewShift";
import {
  generateStripeIDForExistingUser,
  newInvoice,
} from "../../Functions/stripeFunctions";
import { calculateShiftCost } from "../../Functions/utilFunctions";
import {
  addDocument,
  addFirestoreDoc,
  getFirestoreDoc,
  setFirestoreDoc,
  updateFirestoreDoc,
} from "../../Functions/firebaseFunctions";
import { AlertContext } from "../../App";
import Spacer from "../../Components/Spacer";
import { defaultShift } from "../../data/defaultObejcts";
import UserDataContext from "../../Context/UserDataContext";
import { arrayUnion } from "firebase/firestore";
import DebugButtons from "../../Components/DebugButtons";
import { geohashForLocation } from "geofire-common";
import { generateCheckInCode } from "../../Functions/shiftFunctions";
import { NavigatorContext } from "../../Context/NavigatorContext";
const sections = [
  { label: "Position Information", id: 1 },
  { label: "Location Selection", id: 2 },
  { label: "Shift Date", id: 3 },
  { label: "Confirmation", id: 4 },
];

/**
 * @function AddShift
 * @description This component acts as a wrapper and form control for the AddShiftForm component
 */
function AddShift() {
  const [currentSection, setCurrentSection] = useState(1);
  const [showDialog, setShowDialog] = useState(false);
  const [awaitingSubmit, setAwaitingSubmit] = useState(false);
  const navigate = useNavigate();
  const { showPathTitle } = useContext(NavigatorContext);
  const { toggleAlert } = useContext(AlertContext);
  const { positions, locations, userID, addShift, stripeID, userData } =
    useContext(UserDataContext);

  const [dialogData, setDialogData] = useState({
    title: "",
    subtitle: "",
    description: "",
  });
  const { positionID } = useParams();
  const [formData, setFormData] = useState({
    ...defaultShift,
    ...positions[positionID],
  });
  const [positionData, setPositionData] = useState(positions[positionID]);
  const handleNext = () => {
    console.log("handle next");
    if (currentSection < 5) {
      setCurrentSection(currentSection + 1);
    }
  };
  const handleAddShift = async (data) => {
    if (userData.stripeID === null || userData.stripeID === undefined) {
      generateStripeIDForExistingUser(
        userID,
        userData.email,
        userData.displayName
      );
      setAwaitingSubmit(false);
      toggleAlert(
        "Something went wrong, please try again in a moment.",
        "error"
      );
      return;
    }
    console.log("creating new invoice");
    const invoiceID = await newInvoice(
      userData.stripeID,
      formData.description,
      calculateShiftCost(formData.startTime, formData.endTime, formData.rate),
      0.2
    );
    console.log("invoice id is", invoiceID);
    if (invoiceID == undefined) {
      toggleAlert("Something went wrong...", "error");
    } else {
      await addFirestoreDoc("shifts", { ...data, invoiceID: invoiceID }).then(
        ({ id, error }) => {
          if (id) {
            toggleAlert("Successfully added new shift", "success");
            setShowDialog(true);
            //TODO: remove this (consistent with prev. firestore format)
            setFirestoreDoc("listings", id, {
              ...data,
              id: id,
              invoiceID: invoiceID,
              companyName: userData.companyName,
            })
              .then(() => {
                updateFirestoreDoc(positionID, "positions", {
                  unfilledShifts: arrayUnion(id),
                });
              })
              .catch((err) => {
                console.log("something went wrong", err);
              });
          }

          //setPositionData();

          error && toggleAlert("Something went wrong...", "error");
        }
      );
    }
    setAwaitingSubmit(false);
  };
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        height:
          window.innerHeight -
          componentDimensions.appbarHeight.default -
          componentDimensions.spacing.medium.default * 2 -
          (showPathTitle ? componentDimensions.titleBarHeight.default : 0),
        width:
          window.innerWidth -
          componentDimensions.drawerWidth.default -
          componentDimensions.secondaryDrawerWidth.default -
          componentDimensions.spacing.medium.default * 2,
      }}
    >
      <Dialog open={showDialog}>
        <DialogContent>
          <Alert color="success" variant="filled">
            The shift has been successfully created
          </Alert>

          {/*<Box
            sx={{
              backgroundColor: colours.black200,
              margin: componentDimensions.spacing.medium.pixels,
              padding: componentDimensions.spacing.medium.pixels,
              borderRadius: "8px",
            }}
          >
            <Typography variant="body3">Baker</Typography>
            <Spacer />
            <Typography variant="body4">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse augue nunc, finibus vitae efficitur non, ultricies ut
              libero.
          </Typography>
          </Box>*/}
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "space-evenly" }}>
          <Button
            onClick={() => {
              navigate("../unfilled");
            }}
          >
            View Shift
          </Button>
          <Button
            onClick={() => {
              setShowDialog(false);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Card
        className="companyCard"
        sx={{
          paddingX: "35px",
          paddingY: componentDimensions.spacing.small.pixels,
          borderRadius: 0.5,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginX: 5,
          marginY: 2,
        }}
      >
        <Avatar />
        <Typography fontSize={"20px"} style={{ marginLeft: "20px" }}>
          {"New Shift: " + positions[positionID]?.title}
        </Typography>
      </Card>

      <Stepper sx={{ marginX: 5 }} activeStep={currentSection - 1}>
        {sections.map((step, index) => {
          return (
            <Step key={index}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {formData && (
        <Card
          sx={{
            position: "absolute",
            right: 0,
            left: 0,
            bottom: 0,
            top: 150,
            flexDirection: "column",
            marginX: 5,
            padding: 5,
            marginY: 2,
          }}
        >
          <DebugButtons
            onClick={() => {
              newInvoice(
                stripeID,
                formData.description,
                calculateShiftCost(
                  formData.startTime,
                  formData.endTime,
                  formData.rate
                ),
                0.2
              );
            }}
          >
            {formData}
          </DebugButtons>
          <AddNewShift
            locations={locations}
            onSubmitSection={handleNext}
            positionData={positionData}
            formData={formData}
            setFormData={setFormData}
            currentSection={currentSection}
          ></AddNewShift>

          <Box
            sx={{
              position: "absolute",
              bottom: componentDimensions.spacing.small.pixels,
              left: componentDimensions.spacing.small.pixels,
              right: componentDimensions.spacing.small.pixels,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              disabled={currentSection <= 1}
              onClick={() => {
                setCurrentSection(currentSection - 1);
              }}
              variant="text"
              startIcon={<BackArrow />}
            >
              Back
            </Button>

            {currentSection >= 4 ? (
              <Button
                disabled={awaitingSubmit}
                onClick={() => {
                  setAwaitingSubmit(true);
                  let temp = defaultShift;
                  temp = {
                    ...defaultShift,
                    ...formData,
                    employerUID: userID,
                    employer: {
                      uid: userID,
                      displayName: userData.displayName,
                    },
                    wage: formData.rate,
                    checkinCode: generateCheckInCode(),
                    geohash: formData.locationData.geohash,
                    summary: formData.description,
                    value: calculateShiftCost(
                      formData.startTime,
                      formData.endTime,
                      formData.rate
                    ),
                    shiftDates: [
                      {
                        startTime: formData.startTime,
                        endTime: formData.endTime,
                      },
                    ],
                    positionID: positionID,
                    createdBy: userID, //TODO: remove this
                    createdAt: new Date(),
                  };
                  handleAddShift(temp);
                }}
                variant="contained"
                endIcon={<Check />}
              >
                SUBMIT
              </Button>
            ) : (
              <Button
                disabled={
                  currentSection >= 4 ||
                  (currentSection === 2 && formData.locationData.address === "")
                }
                onClick={() => {
                  console.log(formData);
                  setCurrentSection(currentSection + 1);
                }}
                variant="contained"
                endIcon={<RightArrow />}
              >
                Next
              </Button>
            )}
          </Box>
        </Card>
      )}

      <DebugButtons
        onClick={() => {
          throw new Error();
        }}
      >
        eh
      </DebugButtons>
    </Box>
  );
}

AddShift.propTypes = {};

export default AddShift;
