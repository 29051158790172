import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  Grid,
  InputLabel,
  Rating,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import Spacer from "./Spacer";
import DebugButtons from "./DebugButtons";
import { colours } from "../config/colours";
import { componentDimensions } from "../config/dimensions";
import {
  getTimestampAsDate,
  getTimestampAsString,
} from "../Functions/utilFunctions";
import { writeReviewOfNomad } from "../Functions/firebaseFunctions";
import UserDataContext from "../Context/UserDataContext";

function ReviewNomadPopup({
  nomadName,
  nomadUID,
  onCancel,
  open,
  shiftDate,
  shiftTitle,
  shiftID,
}) {
  const defaultValues = {
    rating: 3,
    review: "",
  };
  const { control, handleSubmit, reset } = useForm({
    defaultValues: defaultValues,
  });
  const { userID, userData } = useContext(UserDataContext);
  const handleData = (data) => {
    writeReviewOfNomad(
      nomadUID,
      userID,
      userData.displayName,
      shiftID,
      data.rating,
      data.review
    );
  };
  return (
    <Dialog open={open}>
      <form
        onSubmit={handleSubmit((data, e) => {
          e.preventDefault();
          console.log("submitted form");
          console.log(data);
          handleData(data);
          reset();
          onCancel();
        })}
      >
        <Box
          sx={{
            display: "flex",
            paddingTop: componentDimensions.spacing.small.pixels,
            paddingRight: componentDimensions.spacing.small.pixels,
          }}
        >
          <Avatar
            sx={{ margin: componentDimensions.spacing.small.pixels }}
          ></Avatar>
          <Box>
            <Typography>Reviewing {nomadName}</Typography>
            <Typography variant="body4">
              {shiftTitle} : {getTimestampAsString(shiftDate)}
            </Typography>
          </Box>
        </Box>
        <DialogContent dividers sx={{}}>
          <InputLabel>Rating</InputLabel>
          {
            <Controller
              control={control}
              name="rating"
              render={({ field: { ref, ...rest } }) => (
                <Rating {...rest}></Rating>
              )}
            ></Controller>
          }
          <Spacer />
          <InputLabel>Review</InputLabel>

          <Controller
            control={control}
            name="review"
            render={({ field: { ref, ...rest } }) => (
              <TextField
                required
                fullWidth
                placeholder="Leave a review..."
                {...rest}
              ></TextField>
            )}
          ></Controller>
        </DialogContent>
        <DialogActions>
          <DebugButtons>{nomadUID}</DebugButtons>
          <DebugButtons>{control._formValues}</DebugButtons>
          <Button
            onClick={() => {
              onCancel();
              reset();
            }}
          >
            Cancel
          </Button>
          <Button type={"submit"}>Submit</Button>
        </DialogActions>{" "}
      </form>
    </Dialog>
  );
}

ReviewNomadPopup.propTypes = {};

export default ReviewNomadPopup;
