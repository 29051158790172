//import { updateFirestoreDoc } from "./usersFirestore";

import { setStripeAccountID } from "./firebaseFunctions";

const APIKEY =
  // "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";
  "sk_test_51Mb6zuGOQqvNpzfAUy9H6sIrQNI3z8yUKNM7KycIk4mmBYUTPfhCr337hqZA2MWslNn8vikibX0xY271T3m5mdX900cgdLFih5";
const Stripe = require("stripe");
const stripe = Stripe(APIKEY);

/**
 * Set isPaid field in the listing document in firestore database
 * @param {*} listingID
 * @param {*} isPaid
 */
//export function setInvoicePaid(listingID, isPaid = true) {
//  updateFirestoreDoc(listingID, "listings", { isPaid: isPaid });
//}

/**
 * Checks if an invoice has been fully paid
 * @param {string} invoiceID
 * @returns true if fully paid, false if any amount remaining
 */
export async function checkInvoicePaid(invoiceID) {
  var invoiceDetails = await GET("/v1/invoices/" + invoiceID);
  var amountRemaining = invoiceDetails.amount_remaining;
  console.log("Invoice amount remaining", amountRemaining);
  if (amountRemaining == 0) {
    console.log("Invoice fully paid");
    return true;
  } else {
    console.log("Invoice is not fully paid");
    return false;
  }
}
export async function newInvoice(
  stripeID,
  shiftDescription,
  price,
  serviceFees
) {
  var truncatedDescription = shiftDescription.substring(0, 400);
  try {
    console.log("Creating new stripe invoice");
    let invoiceID = await createInvoice(stripeID);
    console.log("Adding item to invoice");

    await addItemToInvoice(stripeID, invoiceID.id, truncatedDescription, price);
    console.log("Adding service fees to invoice");

    await addItemToInvoice(
      stripeID,
      invoiceID.id,
      "Service Fees",
      price * serviceFees
    );
    console.log("Finalizing invoice");

    await finalizeInvoice(invoiceID.id);
    console.log("Created new invoice with id", invoiceID.id);
    await sendInvoice(invoiceID.id);

    return invoiceID.id;
  } catch (error) {
    console.log(error);
  }
}

export async function finalizeInvoice(invoiceID) {
  const finalizedInvoice = await stripe.invoices.finalizeInvoice(invoiceID);
  //var finalize = await POST("/v1/invoices/" + invoiceID + "/finalize");
  console.log(finalizedInvoice);
  return finalizedInvoice;
}
export async function sendInvoice(invoiceID) {
  const sentInvoice = await stripe.invoices.sendInvoice(invoiceID);
  console.log(sentInvoice);
  return sentInvoice;
}

export async function addItemToInvoice(
  employerStripeID,
  invoiceID,
  itemDescription,
  price
) {
  var parameters = {
    customer: employerStripeID,
    invoice: invoiceID,
    amount: Math.round(price * 100),
    description: itemDescription,
  };
  var createInvoice = await POST("/v1/invoiceitems", parameters);
  return createInvoice;
}

export async function createInvoice(employerStripeID) {
  var parameters = {
    collection_method: "send_invoice",
    customer: employerStripeID,
    days_until_due: 15,
  };
  var createInvoice = await POST("/v1/invoices", parameters);
  console.log(createInvoice);
  return createInvoice;
}

/**
 * Create a customer account (This is used for Employers)
 * @param {*} employerEmail
 * @param {*} employerName
 * @returns
 */
export async function createCustomerAccount(employerEmail, employerName) {
  var parameters = {
    email: employerEmail,
    name: employerName,
  };
  var customer = await POST("/v1/customers", parameters);
  return customer.id;
}
export async function generateStripeIDForExistingUser(
  uid,
  employerEmail,
  employerName
) {
  console.log("setting stripe id for existing user");
  let newStripeID = await createCustomerAccount(employerEmail, employerName);
  newStripeID && setStripeAccountID(uid, newStripeID);
}

/**
 *
 * @param {*} StripeID
 * @returns
 */
export async function getEmployerPaymentHistory(StripeID) {
  var payments = await GETwithCustomerID("/v1/invoices", StripeID);
  let objs = [];
  payments.data?.forEach((obj) => {
    objs.push(obj);
  });
  return { payments: objs };
}
export async function getInvoices(customerID) {
  const invoices = await stripe.invoices.list({
    customer: customerID,
  });
  return invoices.data;
}
/**
 * Gets the entire stripeAccount in json format
 * @param {*} StripeID
 * @returns
 */
export async function getStripeAccount(StripeID) {
  var accountDetails = await GET("/v1/accounts/" + StripeID);
  return accountDetails;
}

/**
 * Registers the input email for an express "Connect" Account
 * @param {*} email
 * @returns
 */
export async function createStripeAccount(email) {
  var parameters = {
    email: email,
    type: "express",
    business_type: "individual",
    country: "CA",
    "business_profile[product_description]": "Nomad account",
  };
  var account = await POST("/v1/accounts", parameters);

  return account.id;
}

/**
 * https://stripe.com/docs/api/invoices/create
 * @param {*} stripeID
 * @param {*} amount
 * @param {*} type
 * @returns
 */
export async function createShiftInvoice(stripeID, amount, type) {
  var parameters = {
    amount_due: amount,
    collection_method: type,
  };
  var invoice = await POST("/v1/invoices/" + stripeID, parameters);
  return invoice;
}

//~~~~~~~~~~~~~~~~~~~~~~~~~~~BASIC STRIPE REST FUNCTIONS BELOW HERE~~~~~~~~~~~~~~~~~~~~~~~~~~~
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
async function POST(EndPoint, params) {
  //Change params into x-www-form-urlencoded format
  var formBody = [];
  for (var property in params) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(params[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  //HTTPS Request
  var url = new URL("https://api.stripe.com" + EndPoint);
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + APIKEY,
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
    });
    let data = await response.json();
    //console.log(data);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function POSTwithHeaders(EndPoint, params) {
  //Change params into x-www-form-urlencoded format
  var formBody = [];
  for (var property in params) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(params[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  //HTTPS Request
  var url = new URL("https://api.stripe.com" + EndPoint);
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + APIKEY,
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
        "Stripe-Version": "2022-11-15",
      },
      body: formBody,
    });
    let data = await response.json();
    //console.log(data);
    return data;
  } catch (error) {
    console.error(error);
  }
}

async function POSTwithNomadID(EndPoint, params, nomadID) {
  //Change params into x-www-form-urlencoded format
  var formBody = [];
  for (var property in params) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(params[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  //HTTPS Request
  var url = new URL("https://api.stripe.com" + EndPoint);
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + APIKEY,
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
        "Stripe-Version": "2022-11-15",
        "Stripe-Account": nomadID,
      },
      body: formBody,
    });
    let data = await response.json();
    //console.log(data);
    return data;
  } catch (error) {
    console.error(error);
  }
}

/**
 * EndPoint should contain starting /
 *  e.g. /v1/balance
 * @param {*} EndPoint
 * @returns Data from get request
 */
async function GETwithHeaders(EndPoint, headers) {
  let url = "https://api.stripe.com" + EndPoint;
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + APIKEY,
        "Stripe-Account": headers,
      },
    });
    let data = await response.json();
    //console.log(data);
    return data;
  } catch (error) {
    console.error(error);
  }
}

/**
 * EndPoint should contain starting /
 *  e.g. /v1/balance
 * @param {*} EndPoint
 * @returns Data from get request
 */
async function GET(EndPoint) {
  var url = new URL("https://api.stripe.com" + EndPoint);
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + APIKEY,
      },
    });
    let data = await response.json();
    //console.log(data);
    return data;
  } catch (error) {
    console.error(error);
  }
}

/**
 * EndPoint should contain starting /
 *  e.g. /v1/balance
 * @param {*} EndPoint
 * @returns Data from get request
 */
async function GETwithCustomerID(EndPoint, customerID) {
  var url = new URL("https://api.stripe.com" + EndPoint);
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + APIKEY,
        customer: customerID,
      },
    });
    console.log(response);
    let data = await response.json();
    //console.log(data);
    return data;
  } catch (error) {
    console.error(error);
  }
}
