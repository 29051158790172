import React from 'react';
import logo from '../logo.png';
import { Link } from 'react-router-dom';

function ErrorPage(){
    return(
        <div>
            <img src={logo} className="App-logo" alt="logo" />

            <h1>ERROR 404 PAGE NOT FOUND</h1>
            <h4>Thank you for your interest in the Nomad Shifts Early Access</h4>
            <p>This page is coming soon... if you have any questions please contact info@nomadshifts.com</p>
            <Link to="/">Home</Link>
            <br/>
            <Link to="/logout">Logout</Link>
        </div>
    )
}

export default ErrorPage;