import { Grid, Typography } from "@mui/material";
import { componentDimensions } from "../config/dimensions";

const DataDisplay = ({ leftLabel, rightLabel, bold = false }) => {
  return (
    <>
      <Grid item xs={3}>
        <Typography
          fontSize={componentDimensions.textSize.small}
          fontWeight={500}
        >
          {leftLabel}{" "}
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography
          fontSize={componentDimensions.textSize.small}
          fontWeight={bold ? "bold" : 400}
        >
          {rightLabel}
        </Typography>
      </Grid>
    </>
  );
};

export default DataDisplay;
