import {
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  where,
  writeBatch,
} from "firebase/firestore";
import { addFirestoreDoc } from "./firebaseFunctions";
import { db } from "../config/firebase";
import { useEffect, useState } from "react";

export async function setNotificationsViewed(notificationIDS = [], uid) {
  const batch = writeBatch(db);
  notificationIDS.forEach((id) => {
    const docRef = doc(db, "notifications", uid, "notifications", id);
    batch.update(docRef, { viewed: true });
  });

  batch.commit();
}

export function getNotifications(uid, viewed = "false", callback) {
  if (uid == null || uid == undefined || uid == "") {
    console.log("no uid in getNotifications()");

    return;
  }
  console.log("getting notifications for user");
  const docRef = query(
    collection(db, "notifications", uid, "notifications"),
    where("viewed", "==", viewed),
    orderBy("createdAt", "desc")
  );
  return onSnapshot(docRef, (querySnapshot) => {
    const notifications = querySnapshot.docs?.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    callback(notifications);
  });
}
export function useNotifications(uid, viewed = false) {
  const [notifications, setNotifications] = useState([]);
  useEffect(() => {
    const unsub = getNotifications(uid, viewed, setNotifications);

    return unsub;
  }, [uid]);
  return notifications;
}

export async function addNotification(
  uid,
  title,
  message,
  notifType = "misc",
  specialData = {}
) {
  try {
    await addFirestoreDoc(
      "notifications",
      {
        createdAt: new Date(),
        title: title,
        message: message,
        viewed: false,
        notificationType: notifType,
        specialData: specialData,
      },
      [uid, "notifications"]
    );
  } catch (error) {
    console.log("Error sending notification", error);
  }
}
export function notifyWelcomeEmployer(uid, displayName) {
  addNotification(
    uid,
    "Welcome to NomadShifts!",
    "To get started creating shifts, head on over to the 'Shifts' page and create a position. ",
    {}
  );
}
export function notifyHired(applicantUID, listingTitle, shiftID) {
  addNotification(
    applicantUID,
    "You're Hired!",
    "Your application for " +
      listingTitle +
      " was accepted by the employer. Contact them for any questions you have regarding this position. ",
    "hired",
    { listingID: shiftID, shiftID: shiftID }
  );
}
