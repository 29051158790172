import { addHours } from "date-fns";

export const defaultShift = {
  title: "",
  available: true,
  applicants: {},
  category: "",
  description: "",
  type: "",
  checkinCode: "",
  categories: [],
  durationMintues: 0,
  invoiceID: "",
  expiryHandled: false,
  notified: false,
  value: 0,
  wage: 16.75,
  worker: null,
  geohash: "",
  employerUID: "",
  employer: { displayName: "", uid: "" },
  createdAt: new Date(),
  requirements: "",
  skills: [],
  stats: { views: 0, likes: 0 },
  shiftDates: [{ startTime: new Date(), endTime: new Date() }],
  documents: {
    Resume: false,
    "Cover Letter": false,
    Transcript: false,
    "Work Samples": false,
    Other: false,
  },
  questions: [{ prompt: "", inputType: "text" }],
  locationType: "onsite",
  locationData: {
    address: "",
    latitude: 0,
    longitude: 0,
    name: "",
    postalCode: "",
    province: "",
    city: "",
    phoneNumber: "",
  },
  date: new Date(),
  startTime: new Date(),
  endTime: addHours(new Date(), 4),
  recurring: false,
  repeatEvery: 1,
  repeatPeriod: "Week",
  rate: 16.75,
};
export const defaultNotification = {
  createdAt: new Date(),
  message: "",
  notificationType: "misc",
  specialData: {},
  title: "Notification",
  viewed: true,
};
export const defaultNomad = {
  DOB: null,
  bio: "",
  displayName: "",
  photoURL: null,
  email: "",
  gender: "",
  phone: null,
  registerDate: null,
  badges: [],
  experience: { pastShifts: [] },
  hiredShifts: [],
  skills: [],
  rating: null,
};
export const defaultUser = {
  DOB: null,
  bio: "",
  displayName: "",
  photoURL: null,
  email: "",
  gender: "",
  phone: null,

  skills: [],
  rating: null,
};
export const defaultChat = {
  id: null,
  lastMessageTimestamp: {
    seconds: 1681399340,
    nanoseconds: 744000000,
  },
  listing: "",
  lastMessage: "",
  read: true,
  members: [" ", ""],
  membersData: {
    uid1: { displayName: "Other person", photoURL: "" },
    uid2: { displayName: "Me", photoURL: "" },
  },
  lastMessageSender: "",
};
export const defaultMessage = {
  id: null,
  messageBody: null,
  timeStamp: null,
  sender: {
    uid: null,
    photoURL: null,
    displayName: null,
  },
};
export const defaultLocation = {
  address: "",
  address2: "",
  city: "",
  country: "Canada",
  postalCode: "",
  province: "",
  admins: [],
  email: "",
  imageURL: "",
  latitude: 0,
  longitude: 0,
  name: "",
  phoneNumber: "",
  verified: true,
};
