import React, { useState, useContext, useEffect } from "react";
import { useShifts, useUserData } from "../Functions/firebaseFunctions";
import { AlertContext } from "../App";

export const EmployerContext = React.createContext();

export function EmployerProvider({ children }) {
  const [userID, setUserID] = useState();
  const [signedIn, setSignedIn] = useState(false);
  const debugMode = true;
  const [stripeID, setStripeID] = useState("");
  const shifts = useShifts(userID);
  //const [shifts, setShifts] = useState({});
  const userData = useUserData(userID);
  const { toggleAlert, toggleDialog, closeDialog } = useContext(AlertContext);
  const [userType, setUserType] = useState("");
  const [usserData, setUserData] = useState({
    displayName: null,
    photoURL: null,
    userType: null,
    email: "",
  });

  return (
    <EmployerContext.Provider value={{ userID }}>
      {children}
    </EmployerContext.Provider>
  );
}
