import React from 'react';
import RepeatedBox from '../../Components/RepeatedBox';
import Popup from '../../Components/Popup';
import { useState, useContext } from 'react';
import { Button } from '@material-ui/core';
import { EmployerProvider } from '../../Context/EmployerContext';

function Locations(){
    const [buttonPopup, setButtonPopup] = useState(false);
    //const employerInfo = useContext(EmployerContext);
    return(
        <>
            <EmployerProvider>
                <div>
                    <h1>Locations</h1>
                    <div style={{paddingLeft: 100, display: 'grid', gridGap: 30, gridTemplateColumns: "1fr 1fr 1fr"}}>
                        <RepeatedBox/>
                        <Button onClick={()=> setButtonPopup(true)} style={{color: "white", fontSize: 50}}>+</Button>
                        <Popup trigger={buttonPopup} setTrigger={setButtonPopup}>
                            <p>Your account is limited to 1 location, please contact support@nomadshifts.com if you wish to add more locations</p>
                        </Popup>
                    </div>
                    
                </div>
            </EmployerProvider>
        </>
    )
}

export default Locations;