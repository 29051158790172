import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { NavigatorContext } from "../../Context/NavigatorContext";
import CardBackground from "../../Components/CardBackground";
import {
  Chip,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import UserDataContext from "../../Context/UserDataContext";
import { ScreenContainer } from "../../Components/ScreenContainer";
import ActivityChip from "../../Components/ActivityChip";
import { getTimestampAsString } from "../../Functions/utilFunctions";
import { componentDimensions } from "../../config/dimensions";
import { updateFirestoreDoc } from "../../Functions/firebaseFunctions";
import { setNotificationsViewed } from "../../Functions/notificationFunctions";

function Notifications() {
  const { setPathTitle, setSecondaryDrawerOpen } = useContext(NavigatorContext);
  const { newNotifications, viewedNotifications, userID } =
    useContext(UserDataContext);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [newIDs, setNewIDs] = useState([]);

  useEffect(() => {
    setPathTitle("Notifications");
    setSecondaryDrawerOpen(false);
    return () => {
      console.log("Unloading notifications screen");
      setNotificationsViewed(newNotifications);
      setNewIDs([]);
    };
  }, []);
  useEffect(() => {
    let temp = newIDs;
    newNotifications.forEach(({ viewed, id }) => {
      if (!viewed) {
        temp.push(id);
      }
    });
    setNewIDs(temp);
    setNotificationsViewed(newIDs, userID);
  }, [newNotifications]);
  return (
    <CardBackground fullSize>
      <Grid container>
        <Grid item xs={12}>
          <List>
            {newNotifications.map(
              ({ title, message, createdAt, viewed, id }) => {
                const isNew = !viewed || newIDs.includes(id);
                return (
                  <>
                    <ListItem
                      key={id}
                      secondaryAction={
                        <>
                          <Typography variant="body6">
                            {getTimestampAsString(createdAt)}
                          </Typography>
                          {isNew && (
                            <Chip
                              sx={{
                                marginLeft:
                                  componentDimensions.spacing.small.pixels,
                              }}
                              label={"New"}
                              color={"success"}
                            />
                          )}
                        </>
                      }
                    >
                      <ListItemText
                        primary={title}
                        secondary={message}
                        secondaryTypographyProps={{ variant: "body2" }}
                      ></ListItemText>
                    </ListItem>
                    <Divider variant="inset" />
                  </>
                );
              }
            )}
            {viewedNotifications.map(
              ({ title, message, createdAt, viewed, id }) => {
                const isNew = !viewed || newIDs.includes(id);
                return (
                  <>
                    <ListItem
                      key={id}
                      secondaryAction={
                        <>
                          <Typography variant="body6">
                            {getTimestampAsString(createdAt)}
                          </Typography>
                          {isNew && (
                            <Chip
                              sx={{
                                marginLeft:
                                  componentDimensions.spacing.small.pixels,
                              }}
                              label={"New"}
                              color={"success"}
                            />
                          )}
                        </>
                      }
                    >
                      <ListItemText
                        primary={title}
                        secondary={message}
                        secondaryTypographyProps={{ variant: "body2" }}
                      ></ListItemText>
                    </ListItem>
                    <Divider variant="inset" />
                  </>
                );
              }
            )}
          </List>
        </Grid>
      </Grid>
    </CardBackground>
  );
}

Notifications.propTypes = {};

export default Notifications;
