import logo from "../../logo.png";
import { auth } from "../../config/firebase";
//import "../Styles/App.css";
import { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import exampleDashboard from "../../assets/exampleDashboard.png";
import { Link as RouterLink } from "react-router-dom";
import { ReactComponent as AppBarLogo } from "../../assets/logos/appbar-logo-coloured.svg";
import { ReactComponent as SmallLogo } from "../../assets/logos/logo-small-coloured.svg";
import { colours } from "../../config/colours";
import { Google, RemoveRedEye, Visibility } from "@mui/icons-material";
import { componentDimensions } from "../../config/dimensions";
function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [formData, setFormData] = useState({
    companyName: "",
    companyEmail: "",
    industry: "",
    password: "",
  });
  const signIn = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
      console.error(err);
      return err;
    }
  };
  return (
    <Grid container style={{ overflow: "hidden" }}>
      {/**Master Container splits screen into 2 sections*/}

      <Grid xs={12} md={6} item>
        {/**login container */}
        <Grid
          container
          spacing={"16px"}
          sx={{ justifyContent: "center", padding: "100px" }}
        >
          {/**logo */}
          <Grid item xs={12} sx={{ alignItems: "center", display: "flex" }}>
            <SmallLogo />
            <AppBarLogo />
          </Grid>
          {/**Intro text */}
          <Grid item xs={12}>
            <Typography fontWeight={700} fontSize={"24px"}>
              Welcome back to
              <span style={{ color: colours.blue600 }}> Nomad Shifts.</span>
            </Typography>

            <Typography
              fontWeight={400}
              fontSize={componentDimensions.textSize.small}
            >
              {
                " Logging in as a Nomad? Desktop apps are currently only available for Nomad employers. Sign up for updates on our newest features "
              }
              <Link component={RouterLink}>HERE</Link>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Divider>
              <Typography
                fontSize={componentDimensions.textSize.small}
                fontWeight={400}
                color={colours.black300}
              >
                OR
              </Typography>
            </Divider>
          </Grid>

          <Grid item xs={12}>
            <InputLabel>Company Email*</InputLabel>
            <TextField
              type="email"
              value={formData.companyEmail}
              onChange={(event) => {
                setFormData({
                  ...formData,
                  companyEmail: event.target.value,
                });
              }}
              fullWidth
              variant="outlined"
              id="companyEmail"
            ></TextField>
          </Grid>

          <Grid item xs={12}>
            <InputLabel>Password:</InputLabel>
            <TextField
              type="password"
              value={formData.password}
              onChange={(event) => {
                setFormData({
                  ...formData,
                  password: event.target.value,
                });
              }}
              fullWidth
              variant="outlined"
              id="password"
              helperText="Must be at least 8 characters"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <Visibility />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            ></TextField>{" "}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <FormControlLabel control={<Checkbox />} label="Remember Me" />
            <Button>Forgot Password?</Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={() => {
                signInWithEmailAndPassword(
                  auth,
                  formData.companyEmail,
                  formData.password
                )
                  .then((value) => {
                    console.log(value);
                  })
                  .catch((error) => {
                    console.log(error.code);

                    if (
                      error.code === "auth/wrong-password" ||
                      error.code === "auth/user-not-found"
                    ) {
                      alert("The username or password is incorrect.");
                    }
                    if (error.code === "auth/too-many-requests") {
                      alert(
                        "Too many requests. Please wait a moment and try again."
                      );
                    }
                  });
              }}
            >
              Log In
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Typography>
              Don't have an account?
              <Link component={RouterLink} to={"/register"}>
                Sign up
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        xs={0}
        md={6}
        item
        sx={{
          height: "100vh",
          backgroundColor: colours.blue600,
          display: { xs: "none", md: "block" },
        }}
      >
        <h1 style={{ color: "white", paddingLeft: 100 }}>
          Post Shifts and Hire Nomads Efficiently
        </h1>
        <Box>
          <img
            src={exampleDashboard}
            style={{ height: "80vh", alignSelf: "flex-end" }}
          ></img>
        </Box>
      </Grid>
    </Grid>
  );
}

export default SignIn;
