import { db } from "../config/firebase";
import {
  getDocs,
  collection,
  doc,
  getDoc,
  setDoc,
  where,
  addDoc,
  updateDoc,
  query,
  orderBy,
  onSnapshot,
  writeBatch,
} from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import {
  defaultChat,
  defaultMessage,
  defaultShift,
  defaultNotification,
} from "../data/defaultObejcts";
import { useEffect, useState } from "react";
import { fromAddress } from "react-geocode";

/**
 * Check what classification of user is associated with a uID
 * @param {*} uID
 * @returns A string specifying the specific user classification
 */
export const checkUserType = async (uID) => {
  let employerData = await getDataID("employers");
  let adminData = await getDataID("admins");
  if (adminData.includes(uID)) {
    return "Admin";
  } else if (employerData.includes(uID)) {
    return "Employer";
  } else {
    return "Nomad";
  }
};

/**
 * setStripeAccountID - set stripe id in user doc in firestore
 * @param {*} uid
 * @param {*} stripeID
 */
export function setStripeAccountID(uid, stripeID) {
  updateFirestoreDoc(uid, "users", {
    stripeID: stripeID,
  });
}
/**
 * setStripeSetupComplete - set stripeSetupComplete field in user doc
 * @param {*} uid
 * @param {*} value
 */
export function setStripeSetupComplete(uid, value = true) {
  updateFirestoreDoc(uid, "users", { stripeSetupComplete: value });
}

/**
 *
 * @param {*} uid
 * @returns
 */
export async function getUserFromFirestore(uid) {
  try {
    const docRef = doc(db, "users", uid);
    const userDoc = await getDoc(docRef);
    if (userDoc.exists()) {
      const user = { ...userDoc.data(), uid: userDoc.id };
      console.log("Found user information while fetching user: " + user.uid);

      return { user: user };
    } else {
      console.log("The user document with id " + uid + " does not exist");
      return { user: null };
    }
  } catch (error) {
    console.log("Error while fetching user: ", error);
    return { error: error };
  }
}
export async function getFirestoreDoc(collectionName, docID) {
  try {
    const docRef = doc(db, collectionName, docID);
    let docSnapshot = await getDoc(doc(db, collectionName, docID));

    if (docSnapshot.exists()) {
      console.log("found data:", docSnapshot.data());
      return { data: { ...docSnapshot.data(), id: docSnapshot.id } };
    }
    return { data: null, id: null };
  } catch (error) {
    console.error(error);
  }
}
/**
 *
 * @param {*} uid
 * @param {*} newData
 */
export function addUserDoc(uid, newData) {
  console.log("Adding user... ");
  async function writeUserToFirestore() {
    try {
      const docRef = doc(db, "users", uid);
      const userDoc = await setDoc(docRef, newData);

      console.log("Successfully created/wrote to doc");
      console.log(newData);
      return;
    } catch (error) {
      console.log("Error while writing to document: ", error);
      return;
    }
  }
  writeUserToFirestore();
}
export async function addFirestoreDocByRef(docRef, docData) {
  try {
    const result = await addDoc(docRef, docData);
    return { id: result.id };
  } catch (error) {
    console.error("Error adding document: ", error);
    return { error };
  }
}
export async function addFirestoreDoc(
  collectionName,
  docData,
  extraPathSegments = []
) {
  console.log(
    "adding doc to ",
    collectionName + "/" + extraPathSegments.join("/")
  );
  try {
    const docRef = doc(collection(db, collectionName, ...extraPathSegments));
    const result = await setDoc(docRef, { ...docData, id: docRef.id });

    console.log("Document written with ID: ", docRef.id);
    return { id: docRef.id };
  } catch (error) {
    console.error("Error adding document: ", error);
    return { error };
  }
}
export async function setFirestoreDoc(
  collectionName,
  docID,
  docData,
  extraPathSegments = []
) {
  console.log(
    "setting doc with id " + docID + " io ",
    collectionName + "/" + extraPathSegments.join("/")
  );
  try {
    await setDoc(doc(db, collectionName, ...extraPathSegments, docID), docData);
    console.log("Document written with ID: ", docID);
    return { id: docID };
  } catch (error) {
    console.error("Error adding document: ", error);
    return { error };
  }
}
export const signOut = async () => {
  try {
    const auth = getAuth();

    auth.signOut();
    console.log("You were signed out of your account");
  } catch (error) {
    console.error("There was an error signing out");
  }
};
export async function addMessage(chatID, userID, messageBody, timestamp) {
  console.log("writing message: " + messageBody);
  let docRef = collection(db, "chats", chatID, "messages");

  await addFirestoreDocByRef(docRef, {
    ...defaultMessage,
    messageBody: messageBody,
    timeStamp: timestamp,
    sender: { uid: userID, photoURL: null, displayName: null },
  });
  await updateFirestoreDoc(chatID, "chats", {
    read: false,
    lastMessageSender: userID,
    lastMessage: messageBody,
    lastMessageTimestamp: new Date(),
  });
}
export async function writeReviewOfNomad(
  nomadUID,
  employerUID,
  employerName,
  shiftID,
  rating,
  review
) {
  const batch = writeBatch(db);
  const docRef = doc(db, "users", nomadUID, "reviews", shiftID);

  batch.set(docRef, {
    employer: { uid: employerUID, displayName: employerName },
    shiftID: shiftID,
    rating: rating,
    review: review,
    date: new Date(),
  });

  const docRef2 = doc(db, "shifts", shiftID);

  batch.update(docRef2, {
    reviewOfNomad: {
      rating: rating,
      review: review,
      date: new Date(),
    },
  });
  batch.commit();
}

export const getChatId = (user1, user2) => {
  const docId = user1 > user2 ? user1 + user2 : user2 + user1;
  return docId;
};
/**
 * Function that creates a chat document in the 'chats' collection
 * chat ID generated by user IDs
 * @function createNewChat
 * @async
 * @param {object} user1
 * @param {object} user2
 */
export function createNewChat(
  user1,
  user2,
  user1Data,
  user2Data,
  callback = () => {}
) {
  const docId = getChatId(user1, user2);
  console.log(docId);
  console.log(user1, user1Data, user2, user2Data);
  getFirestoreDoc("chats", docId).then((data) => {
    if (data.data) {
      console.log("chat already exists", data);
      callback(docId);
    } else {
      console.log("creating chat with users " + user1 + " and " + user2);
      const chatData = {
        ...defaultChat,
        membersData: {
          [user1]: {
            displayName: user1Data.displayName,
            photoURL:
              user1Data.photoURL === undefined ? null : user1Data.photoURL,
          },
          [user2]: {
            displayName: user2Data.displayName,
            photoURL:
              user2Data.photoURL === undefined ? null : user2Data.photoURL,
          },
        },
        members: [user1, user2],
      };
      console.log(chatData);

      setFirestoreDoc("chats", docId, chatData).then(callback(docId));
    }
  });
}
export async function updateFirestoreDoc(id, collectionName, newData) {
  const docRef = doc(db, collectionName, id);
  try {
    await updateDoc(docRef, { ...newData, lastUpdated: new Date() });
  } catch (error) {
    console.log("error while updating doc", error);
  }
}

export async function createUser(email, password, newData) {
  let newUID = await createNewAuthUser(email, password);
  console.log(newUID);
  addUserDoc(newUID, newData);
  return newUID;
}
/**
 *
 * @param {*} documentName
 * @param {*} collection
 * @param {*} newData
 */
export function addDocument(documentName, collection, newData) {
  console.log("Adding document to collection... ");
  async function writeDocumentToFirestore() {
    try {
      const docRef = doc(db, collection, documentName);
      const document = await setDoc(docRef, newData);

      console.log("Successfully created/wrote a doc to: " + collection);
      console.log(document);
      return;
    } catch (error) {
      console.log("Error while writing to document: ", error);
      return;
    }
  }
  writeDocumentToFirestore();
}
/**
 *
 * @param {*} collectionName
 * @returns
 */
export const getDataID = async (collectionName) => {
  const collectionRef = collection(db, collectionName);
  const data = await getDocs(collectionRef);
  const filteredData = data.docs.map((doc) => doc.id);
  return filteredData;
};
/**
 *
 * @param {*} collectionName
 * @param {*} fieldName
 * @param {*} requiredInclusion
 * @param {*} comparitiveSymbol
 * @returns
 */
export const searchData = async (
  collectionName,
  fieldName,
  requiredInclusion,
  comparitiveSymbol = "=="
) => {
  const collectionRef = collection(db, collectionName);
  const q = query(
    collectionRef,
    where(fieldName, comparitiveSymbol, requiredInclusion)
  );

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    console.log(doc.id, " => ", doc.data());
  });
  return querySnapshot;
};

/**
 * Creates a new firebase auth user with email and password
 * @param {*} email
 * @param {*} password
 */
export const createNewAuthUser = async (email, password) => {
  const auth = getAuth();
  var userID = "";
  await createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const user = userCredential.user;
      console.log("New User Created");
      userID = user.uid;
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(error);
      alert(error.message);
    });
  return userID;
};

/**
 * @link https://blog.logrocket.com/how-to-build-chatroom-app-react-firebase/
 * @param {*} uid
 * @param {*} callback
 * @returns
 */
function getChats(uid, callback) {
  console.log("getting chats");
  const docRef = query(
    collection(db, "chats"),
    where("members", "array-contains", uid),
    orderBy("lastMessageTimestamp", "desc")
  );
  return onSnapshot(docRef, (querySnapshot) => {
    const chats = querySnapshot.docs.map((doc) => ({
      ...defaultChat,

      otherUserUID:
        doc.data().members[0] === uid
          ? doc.data().members[1]
          : doc.data().members[0],
      ...doc.data(),
      id: doc.id,
    }));

    callback(chats);
  });
}
export function useChats(uid) {
  const [chats, setChats] = useState([]);
  useEffect(() => {
    const unsub = getChats(uid, setChats);
    return unsub;
  }, [uid]);
  return chats;
}
function getMessages(chatID, callback) {
  console.log(chatID == null);
  if (chatID != null) {
    try {
      console.log("chatID is " + chatID);
      const docRef = query(
        collection(db, "chats", chatID, "messages"),
        orderBy("timeStamp", "asc")
      );
      return onSnapshot(docRef, (querySnapshot) => {
        const messages = querySnapshot.docs?.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        callback(messages);
      });
    } catch (error) {
      console.log(error);
    }
  }
}
export function useMessages(chatID) {
  const [messages, setMessages] = useState([]);
  useEffect(() => {
    const unsub = getMessages(chatID, setMessages);
    return unsub;
  }, [chatID]);
  return messages;
}

function getPositions(uid, callback) {
  console.log("getting positions for ", uid);
  const docRef = query(
    collection(db, "positions"),
    where("uid", "==", uid),
    orderBy("createdAt", "desc")
  );

  return onSnapshot(docRef, (querySnapshot) => {
    const positions = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));

    callback(positions);
  });
}
export function usePositions(uid) {
  const [positions, setPositions] = useState([]);
  useEffect(() => {
    const unsub = getPositions(uid, setPositions);
    return unsub;
  }, [uid]);
  return positions;
}

export function getShifts(uid, callback) {
  console.log("getting shifts");
  const docRef = query(
    collection(db, "shifts"),
    where("employerUID", "==", uid),
    orderBy("startTime", "asc")
  );
  return onSnapshot(docRef, (querySnapshot) => {
    let obj = {};

    const shifts = querySnapshot.docs;
    shifts.forEach((shift) => {
      obj = {
        ...obj,
        [shift.id]: { ...defaultShift, ...shift.data(), id: shift.id },
      };
    });

    /* map((doc) => ({
      ...defaultShift,
      ...doc.data(),
      id: doc.id,
    })); */
    console.log(obj);
    callback(obj);
  });
}
export function useShifts(uid) {
  const [shifts, setShifts] = useState({});
  useEffect(() => {
    const unsub = getShifts(uid, setShifts);
    return unsub;
  }, [uid]);
  return shifts;
}
export function getUserData(uid, callback) {
  console.log("getting user data", uid);

  if (uid == null || uid == undefined || uid == "") {
    console.log("no uid");

    return;
  } else {
    const docRef = doc(db, "users", uid);
    return onSnapshot(docRef, (querySnapshot) => {
      let obj = {};

      obj = { ...querySnapshot.data(), id: uid };

      /* map((doc) => ({
        ...defaultShift,
        ...doc.data(),
        id: doc.id,
      })); */
      console.log(obj);
      callback(obj);
    });
  }
}
export function useUserData(uid) {
  const [userData, setUserData] = useState({});
  useEffect(() => {
    const unsub = getUserData(uid, setUserData);
    return unsub;
  }, [uid]);
  return userData;
}
export function geocodeAddress(address = "", callback = () => {}) {
  const results = fromAddress(address)
    .then(({ results }) => {
      const { lat, lng } = results[0].geometry.location;
      console.log(lat, lng);
      callback({ lat, lng });
      //return { lat: lat, lng: lng };
    })
    .catch(console.error);
}
