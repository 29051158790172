import logo from "../logo.svg";
import {
  Routes,
  Route,
  Link,
  createBrowserRouter,
  RouterProvider,
  Outlet,
  Navigate,
} from "react-router-dom";
import React from "react";
import ErrorPage from "../Screens/ErrorPage";
import Logout from "../Screens/Logout";
import ComingSoon from "../Screens/ComingSoon";
import Shifts from "../Screens/Employer/Shifts";
import Payments from "../Screens/Employer/Payments";
import EmployerDashboard from "../Screens/Employer/EmployerDashboard";
import Locations from "../Screens/Employer/Locations";
import Applicants from "../Screens/Employer/Applicants";
import NavTab from "../Components/NavTab";
import { AppBar, Box, IconButton, Toolbar } from "@mui/material";
import { createTheme, makeStyles } from "@mui/material/styles";
import Navigator from "../Components/Navigator";
import MenuIcon from "@mui/icons-material/Menu";
import { colours } from "../config/colours";
import AddPositionForm from "../Screens/Employer/AddPositionForm";
import AddPosition from "../Screens/Employer/AddPosition";
import FilledShifts from "../Screens/Employer/FilledShifts";
import UnfilledShifts from "../Screens/Employer/UnfilledShifts";
import CompletedShifts from "../Screens/Employer/CompletedShifts";

const drawerWidth = "122px";

const NavigationUI = () => {
  return (
    <Navigator>
      <Outlet />
    </Navigator>
  );
};
export const nomadRouter = createBrowserRouter([
  { path: "/", element: <ComingSoon /> },

  { path: "/Logout", element: <Logout /> },
  { path: "*", element: <ErrorPage /> },
]);
function Root() {
  return (
    <Box display={"flex"}>
      <div className="topNav">
        <img className="App-logo" src={logo} alt="Nomad Shifts Logo" />
        <h6 className="App-name">Nomad Shifts</h6>
      </div>
      <Box component="nav">
        <Navigator
          PaperProps={{ style: { width: drawerWidth } }}
          sx={{ display: { sm: "block", xs: "none" } }}
        />{" "}
      </Box>
      <div className="Secondary-side-nav">
        <h4 style={{ paddingTop: 70 }}>Shifts</h4>
        <h6 style={{ backgroundColor: "#EDF7FF", padding: 15 }}>Add Shifts</h6>
        <h6>Unfilled Shifts</h6>
        <h6>Filled Shifts</h6>
        <h6>Completed Shifts</h6>
      </div>
      {/* All elements must be rendered inside of the main div to show up in the correct section */}
      <div className="main">
        <Routes>
          <Route path="/" element={<ComingSoon />} />
          <Route path="/EmployerDashboard" element={<EmployerDashboard />} />
          <Route path="/Shifts" element={<Shifts />} />
          <Route path="/Locations" element={<Locations />} />
          <Route path="/Payments" element={<Payments />} />
          <Route path="/Applicants" element={<Applicants />} />
          <Route path="/Logout" element={<Logout />} />
          <Route path="*" element={<ErrorPage />} />
          {/*This is the catch all error page, must remain at the bottom of the routes list*/}
        </Routes>
      </div>
    </Box>
  );
}
export default function EmployerRoutes() {
  return (
    <>
      <Box display={"flex"}>
        <div className="topNav">
          <img className="App-logo" src={logo} alt="Nomad Shifts Logo" />
          <h6 className="App-name">Nomad Shifts</h6>
        </div>
        <Box component="nav">
          <Navigator
            PaperProps={{ style: { width: drawerWidth } }}
            sx={{ display: { sm: "block", xs: "none" } }}
          />{" "}
        </Box>
        <div className="Secondary-side-nav">
          <h4 style={{ paddingTop: 70 }}>Shifts</h4>
          <h6 style={{ backgroundColor: "#EDF7FF", padding: 15 }}>
            Add Shifts
          </h6>
          <h6>Unfilled Shifts</h6>
          <h6>Filled Shifts</h6>
          <h6>Completed Shifts</h6>
        </div>
        {/* All elements must be rendered inside of the main div to show up in the correct section */}
        <div className="main">
          <Routes>
            <Route path="/" element={<ComingSoon />} />
            <Route path="/EmployerDashboard" element={<EmployerDashboard />} />
            <Route path="/Shifts" element={<Shifts />} />
            <Route path="/Locations" element={<Locations />} />
            <Route path="/Payments" element={<Payments />} />
            <Route path="/Applicants" element={<Applicants />} />
            <Route path="/Logout" element={<Logout />} />
            <Route path="*" element={<ErrorPage />} />
            {/*This is the catch all error page, must remain at the bottom of the routes list*/}
          </Routes>
        </div>
      </Box>
    </>
  );
}
