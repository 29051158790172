import { auth } from "../config/firebase";
//import "../Styles/App.css";
import { useContext, useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { ReactComponent as AppBarLogo } from "../assets/logos/appbar-logo-coloured.svg";
import { ReactComponent as SmallLogo } from "../assets/logos/logo-small-coloured.svg";
import { colours } from "../config/colours";
import { Visibility } from "@mui/icons-material";
import { createUser, setStripeAccountID } from "../Functions/firebaseFunctions";
import { createCustomerAccount } from "../Functions/stripeFunctions";
import UserDataContext from "../Context/UserDataContext";

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { setUserData, userData, setUserType } = useContext(UserDataContext);
  const [formData, setFormData] = useState({
    companyName: "",
    companyEmail: "",
    industry: "",
    password: "",
  });
  const signIn = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
      console.error(err);
      return err;
    }
  };
  const createNewUserWithStripeID = async () => {
    let newUID = await createUser(formData.companyEmail, formData.password, {
      userType: "employer",
      email: formData.companyEmail,
      registerDate: new Date(),
      industry: formData.industry,
      companyName: formData.companyName,
      verified: false,
    });
    console.log("setting stripe id");
    try {
      let newStripeID = await createCustomerAccount(
        formData.companyEmail,
        formData.companyName
      );
      setStripeAccountID(newUID, newStripeID);
      //setUserData({ ...userData, stripeID: newStripeID });
    } catch (err) {
      console.error("error setting stripe id", err);
      return err;
    }
  };
  return (
    <Grid
      container
      rowSpacing={3}
      sx={{
        display: "flex",
        height: window.innerHeight,
      }}
    >
      {/**Master Container */}

      <Grid xs={12} md={6} item style={{ height: "100vh" }}>
        <Grid
          container
          spacing={"16px"}
          sx={{ justifyContent: "center", padding: "100px", height: "100vh" }}
        >
          {/**logo */}
          <Grid item xs={12} sx={{ alignItems: "center", display: "flex" }}>
            <SmallLogo />
            <AppBarLogo />
          </Grid>
          {/**Intro text */}
          <Grid item xs={12}>
            <Typography fontWeight={700} fontSize={"24px"}>
              Welcome to
              <span style={{ color: colours.blue600 }}> Nomad Shifts.</span>
            </Typography>
            <Typography fontWeight={700} fontSize={"24px"}>
              Get Started with us Now!
            </Typography>
            <Typography fontWeight={400} fontSize={"14px"}>
              Are you an employer looking to hire temporary employees? Lorem
              ipsum dolor sit amet, consectetur adipiscing elit.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Company Name*</InputLabel>
            <TextField
              value={formData.companyName}
              onChange={(event) => {
                setFormData({
                  ...formData,
                  companyName: event.target.value,
                });
              }}
              fullWidth
              variant="outlined"
              type="text"
              id="companyName"
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Company Email*</InputLabel>
            <TextField
              type="email"
              value={formData.companyEmail}
              onChange={(event) => {
                setFormData({
                  ...formData,
                  companyEmail: event.target.value,
                });
              }}
              fullWidth
              variant="outlined"
              id="companyEmail"
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Industry</InputLabel>
            <TextField
              type="text"
              value={formData.industry}
              onChange={(event) => {
                setFormData({
                  ...formData,
                  industry: event.target.value,
                });
              }}
              fullWidth
              variant="outlined"
              id="industry"
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Password*</InputLabel>
            <TextField
              type="password"
              value={formData.password}
              onChange={(event) => {
                setFormData({
                  ...formData,
                  password: event.target.value,
                });
              }}
              fullWidth
              variant="outlined"
              id="password"
              helperText="Must be at least 8 characters"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton>
                    <Visibility />
                  </IconButton>
                </InputAdornment>
              }
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={() => {
                console.log("Create Account");

                try {
                  createNewUserWithStripeID();
                  //navigate("../");
                } catch (error) {
                  console.log(error);
                  alert(error.message);
                }
              }}
            >
              Create Account
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Already have an account?{" "}
              <Link component={RouterLink} to={"/EmployerDashboard"}>
                Log In
              </Link>
            </Typography>
            <p>
              By signing up you agree to Nomad Shift's{" "}
              <a href="https://www.nomadshifts.com/privacy">Terms of Service</a>
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        xs={0}
        md={6}
        item
        sx={{
          backgroundColor: colours.blue600,
          display: { xs: "none", md: "block" },
        }}
      >
        <Box> </Box>
      </Grid>
    </Grid>
  );
}

export default Register;
