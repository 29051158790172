import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import PersonIcon from "@mui/icons-material/Person";
import { colours } from "../../config/colours";
import { Icon } from "@iconify/react";
import Mysvg from "../../assets/navigation/dashboard.svg";
import ClockIcon from "@mui/icons-material/AccessTime";
import isSvg from "is-svg";
import {
  getDifferenceInHours,
  getTimeRangeAsString,
  getTimeUntilAsString,
  getTimestampAsDate,
} from "../../Functions/utilFunctions";
import ActivityChip from "../ActivityChip";
import ShiftTypeChip from "./ShiftTypeChip";
import CountdownChip from "../CountdownChip";
import { componentDimensions } from "../../config/dimensions";
import {
  ArrowDropDown,
  ArrowDropUp,
  ArrowUpward,
  Delete,
  Visibility,
} from "@mui/icons-material";
import { differenceInHours, isPast } from "date-fns";

const SvgColor = require("react-svg-color");
const getStartDateLabel = (startDate) => {
  const expired = isPast(getTimestampAsDate(startDate));
  if (expired) {
    return (
      "Started " + getDifferenceInHours(startDate, new Date()) + " hours ago"
    );
  } else {
    return (
      "Starts in " + getDifferenceInHours(new Date(), startDate) + " hours"
    );
  }
};
const StatLabel = ({ count, children }) => {
  return (
    <Box textAlign={"center"} spacing={0} sx={{ minWidth: "49px" }}>
      <Typography
        color={colours.blue700}
        fontSize={componentDimensions.textSize.small}
      >
        {count}
      </Typography>
      <Typography fontSize={"10px"} color={colours.black700}>
        {children}
      </Typography>
    </Box>
  );
};
const StatsBox = ({ numApplicants = 0, onClick }) => {
  return (
    <Stack
      spacing={"9px"}
      direction={"row"}
      onClick={onClick}
      component={ButtonBase}
      disableRipple
    >
      {/* <StatLabel count={35}>New</StatLabel>
      <StatLabel count={35}>Viewed</StatLabel>
      <StatLabel count={35}>Contacting</StatLabel>
      <StatLabel count={35}>Shortlisted</StatLabel> */}
      <StatLabel count={numApplicants}>Applicants</StatLabel>
    </Stack>
  );
};
function ShiftCard({
  title = "",
  dateString,
  subtitle = "",
  chipTitle = "",
  ChipIcon = <></>,
  NomadAvatar,
  PrimaryChip = () => <></>,
  EndButton = () => <MoreVertIcon />,
  nomadName,
  DropdownContent = () => <></>,
  onClick = () => {},
  id = "missingId",
  sx,
  onClickNomad,
  onToggleDropdown,
  dropdownOpen = false,
  cost,
  children,
  shiftstatus = "active",
  onDelete,
  menuDisabled = false,
  menuItems = [{ label: "Delete", onClick: onDelete, Icon: () => <Delete /> }],
}) {
  const manageBtnRef = useRef();
  const [showMenu, setShowMenu] = useState(false);
  const [anchorel, setanchorel] = useState(null);

  return (
    <>
      <Menu
        open={showMenu}
        anchorEl={anchorel}
        onClose={() => {
          setShowMenu(false);
        }}
      >
        {menuItems?.map(({ label, onClick, Icon }) => (
          <MenuItem color="error" onClick={onClick}>
            {Icon && <Icon />}
            {label}
          </MenuItem>
        ))}
      </Menu>
      <Box
        sx={{
          //border: dropdownOpen ? 1 : 0,
          borderRadius: "8px",

          // borderColor: colours.white700,
          overflow: "hidden",
          boxShadow: 1,
          flexDirection: "column",
        }}
        style={{ elevation: 1 }}
      >
        <Stack
          //component={ButtonBase}
          //disableRipple
          //onClick={() => onClick(id)}
          direction="row"
          spacing={3}
          sx={{
            flexGrow: 1,
            width: "100%",
            minHeight: "88px",
            borderRadius: "8px",
            backgroundColor: dropdownOpen ? colours.white400 : colours.white200,
            alignItems: "center",
            borderBottomLeftRadius: dropdownOpen ? 0 : "8px",
            borderBottomRightRadius: dropdownOpen ? 0 : "8px",

            display: "flex",
            justifyContent: "space-between",
            paddingX: 2,
            // ...sx,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {onToggleDropdown && (
              <IconButton onClick={onToggleDropdown}>
                {dropdownOpen ? <ArrowDropUp /> : <ArrowDropDown />}
              </IconButton>
            )}
            <Stack>
              <Typography fontSize={"16px"}>{title}</Typography>
              <Typography fontSize={"12px"} noWrap textOverflow={"ellipsis"}>
                {dateString}
              </Typography>
              <Typography fontSize={"12px"} noWrap textOverflow={"ellipsis"}>
                {subtitle}
              </Typography>
            </Stack>
          </Box>
          <PrimaryChip />
          {nomadName && (
            <Box
              component={ButtonBase}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
              disableRipple
              onClick={onClickNomad ? onClickNomad : null}
            >
              <NomadAvatar />
              <Typography style={{ marginLeft: 3 }} fontSize={"10px"}>
                {nomadName}
              </Typography>
            </Box>
          )}
          {children}
          {false && shiftstatus && (
            <ActivityChip status={shiftstatus}></ActivityChip>
          )}

          <IconButton
            disabled={menuDisabled}
            ref={manageBtnRef}
            onClick={(e) => {
              setanchorel(e.currentTarget);
              setShowMenu(true);
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </Stack>
        {dropdownOpen && (
          <Box
            sx={{
              backgroundColor: colours.white,
              padding: componentDimensions.spacing.medium.pixels,
            }}
          >
            {<DropdownContent />}
          </Box>
        )}
      </Box>
    </>
  );
}

function ShiftCardFilled({
  title = "Filled Shift",
  shiftType = "One-time",
  startDate = new Date(),
  endDate = new Date(),
  nomadUID,

  nomadPhotoURL,
  nomadDisplayName,
  checkinCode,
  ...otherProps
}) {
  return (
    <>
      <ShiftCard
        PrimaryChip={() => {
          return (
            <CountdownChip
              shiftType="onetime"
              label={getStartDateLabel(startDate)}
            />
          );
        }}
        title={title}
        dateString={getTimeRangeAsString(startDate, endDate)}
        chipTitle={shiftType}
        NomadAvatar={() => (
          <Avatar
            sx={{ width: "25px", height: "25px", margin: 0 }}
            src={nomadPhotoURL}
          />
        )}
        nomadName={nomadDisplayName}
        {...otherProps}
      >
        <Tooltip title="Share this code with the hired Nomad so that they can check in to their shift">
          <Typography color={colours.blue700}>{checkinCode}</Typography>
        </Tooltip>
      </ShiftCard>
    </>
  );
}
function ShiftCardUnfilled({
  title = "Unfilled Shift",
  startDate,
  endDate,
  numApplicants,
  ...otherProps
}) {
  return (
    <>
      <ShiftCard
        title={title}
        dateString={getTimeRangeAsString(startDate, endDate)}
        menuItems={[
          {
            label: "View Applicants",
            onClick: otherProps.onClick,
            Icon: () => <Visibility />,
          },
          {
            label: "Delete",
            onClick: otherProps.onDelete,
            Icon: () => <Delete />,
          },
        ]}
        PrimaryChip={() => {
          return (
            <CountdownChip
              shiftType="onetime"
              label={getStartDateLabel(startDate)}
            />
          );
        }}
        {...otherProps}
      >
        <StatsBox numApplicants={numApplicants} onClick={otherProps.onClick} />
      </ShiftCard>
    </>
  );
}

/**Card to display position type, each one may have multiple shifts */
function PositionCard({
  title = "Position Title",
  numFilled = 0,
  numUnfilled = 0,
  onClickAddShift,
  ...otherProps
}) {
  return (
    <ShiftCard
      sx={{ borderWidth: 0, backgroundColor: "white" }}
      title={title}
      subtitle={null}
      shiftstatus={null}
      {...otherProps}
    >
      <Stack direction={"row"} sx={{ alignItems: "center" }}>
        <PersonIcon />
        <Typography noWrap fontSize={"12px"} color={colours.black400}>
          {numFilled + " Filled Shifts"}
        </Typography>
      </Stack>
      <Typography noWrap fontSize={"12px"} color={colours.black400}>
        {numUnfilled + " Unfilled Shifts"}
      </Typography>
      <Button
        style={{ whiteSpace: "nowrap" }}
        startIcon={<AddIcon />}
        variant="contained"
        onClick={onClickAddShift ? onClickAddShift : null}
      >
        Add Shift
      </Button>
    </ShiftCard>
  );
}
ShiftCard.propTypes = {};

export { ShiftCard, ShiftCardFilled, ShiftCardUnfilled, PositionCard };
