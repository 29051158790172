import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Chip,
  Divider,
  Tab,
  Tabs,
  Typography,
  IconButton,
} from "@mui/material";
import {
  ArrowLeft,
  CalendarMonth,
  LocationCity,
  Money,
} from "@mui/icons-material";
import { colours } from "../../config/colours";
import { useState } from "react";
import NomadApplicationCard from "../../Components/NomadApplicationCard";
import CardBackground from "../../Components/CardBackground";
import { componentDimensions } from "../../config/dimensions";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { defaultNomad } from "../../data/defaultObejcts";
import UserDataContext from "../../Context/UserDataContext";
import DebugButtons from "../../Components/DebugButtons";
import {
  getTimeRangeAsString,
  moneyFormatter,
} from "../../Functions/utilFunctions";
import { getFirestoreDoc } from "../../Functions/firebaseFunctions";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const sampleApplications = [
  {
    displayName: "John Pendleton",
    isNew: true,
    rating: 4.7,
    numMatchingSkills: 3,
    id: 0,
  },
  {
    displayName: "Nohj Pendletoon",
    isNew: false,
    rating: 4.9,
    numMatchingSkills: 6,
    id: 1,
  },
  {
    displayName: "Joohn Pendleon",
    isNew: true,
    rating: 4.7,
    numMatchingSkills: 1,
    id: 2,
  },
  {
    displayName: "Johon Pondlton",
    isNew: true,
    rating: 4.2,
    numMatchingSkills: 2,
    id: 3,
  },
];
export default function UnfilledShift({ onClickNomad }) {
  const { shifts, setHiredNomad } = useContext(UserDataContext);
  const [activeTab, setActiveTab] = useState(0);
  const { shiftId } = useParams();

  const [nomadData, setNomadData] = useState({
    ...defaultNomad,
  });
  const shiftData = shifts[shiftId];
  const navigate = useNavigate();
  return (
    <>
      <Outlet context={[nomadData]} />
      <CardBackground fullSize colouredTop>
        <Typography fontSize={"20px"} fontWeight={500}>
          {shiftData?.title}
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Chip
            label={moneyFormatter.format(shiftData.rate)}
            icon={<Money />}
            style={{ marginRight: "10px" }}
          ></Chip>
          <DebugButtons>{shiftData}</DebugButtons>
          <Chip
            label={shiftData.locationData.address}
            icon={<LocationCity />}
          ></Chip>
        </Box>
        <Typography
          fontSize={componentDimensions.textSize.small}
          fontWeight={400}
        >
          {shifts.description}
        </Typography>
        <Typography
          fontSize={componentDimensions.textSize.small}
          sx={{ alignItems: "center", display: "flex" }}
          color={colours.black400}
        >
          <CalendarMonth color={colours.black400} />
          {getTimeRangeAsString(shiftData.startTime, shiftData.endTime)}
        </Typography>
        <Box sx={{ borderBottom: 1, borderColor: colours.black400 }}>
          <Tabs
            value={activeTab}
            onChange={(e, val) => {
              setActiveTab(val);
            }}
          >
            <Tab
              label={"New " + Object.values(shiftData.applicants)?.length}
              {...a11yProps(0)}
            />
            <Tab disabled label={"Interview (0)"} {...a11yProps(1)} />
            <Tab disabled label={"Shortlisted (0)"} {...a11yProps(2)} />
          </Tabs>
        </Box>

        <CustomTabPanel value={activeTab} index={0}>
          {Object.keys(shiftData.applicants).map((id) => {
            const application = shiftData.applicants[id];
            return (
              <>
                <NomadApplicationCard
                  uid={id}
                  key={id}
                  worker={shiftData.worker}
                  photoURL={application.photoURL}
                  onHireNomad={() => {
                    setHiredNomad(
                      shiftData.id,
                      id,
                      shiftData.invoiceID,

                      application.displayName,
                      shiftData.title,
                      application.photoURL
                    );
                  }}
                  onClickNomad={() => {
                    getFirestoreDoc("users", id).then(({ data: userData }) => {
                      getFirestoreDoc("workers", id).then(
                        ({ data: workerData }) => {
                          setNomadData({
                            questions: shiftData.questions,
                            ...nomadData,
                            ...application,
                            ...userData,
                            ...workerData,
                          });
                        }
                      );
                    });

                    console.log(id);
                    navigate("" + id);
                  }}
                  displayName={application.displayName}
                  rating={application.rating}
                  isNew={application.isNew}
                  numMatchingSkills={application.numMatchingSkills}
                />
                <Divider />
              </>
            );
          })}
        </CustomTabPanel>
      </CardBackground>
    </>
  );
}

UnfilledShift.propTypes = {};
