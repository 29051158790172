import { Box } from "@mui/material";
import React, { useContext } from "react";
import { componentDimensions } from "../config/dimensions";
import { NavigatorContext } from "../Context/NavigatorContext";

export function ScreenContainer({ children }) {
  const { showPathTitle, secondaryDrawerOpen } = useContext(NavigatorContext);
  return (
    <Box
      sx={{
        minHeight:
          window.innerHeight -
          componentDimensions.appbarHeight.default -
          componentDimensions.spacing.medium.default * 2 -
          (showPathTitle ? componentDimensions.titleBarHeight.default : 0),
        width:
          window.innerWidth -
          componentDimensions.drawerWidth.default -
          (secondaryDrawerOpen
            ? componentDimensions.secondaryDrawerWidth.default
            : 0) -
          componentDimensions.spacing.medium.default * 2,
      }}
    >
      {children}
    </Box>
  );
}
