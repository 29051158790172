import React, { useState, useContext } from "react";

export const NavigatorContext = React.createContext();

export function NavigatorProvider({ children }) {
  const [pathTitle, setPathTitle] = useState(null);
  const [showPathTitle, setShowPathTitle] = useState(true);
  const [secondaryDrawerOpen, setSecondaryDrawerOpen] = useState(false);

  return (
    <NavigatorContext.Provider
      value={{
        pathTitle,
        setPathTitle,
        showPathTitle,
        setShowPathTitle,
        secondaryDrawerOpen,
        setSecondaryDrawerOpen,
      }}
    >
      {children}
    </NavigatorContext.Provider>
  );
}
