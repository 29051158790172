//import "./Styles/App.css";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { useContext, useEffect, useState } from "react";
import { RouterProvider } from "react-router-dom";
import { auth } from "./config/firebase";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import {
  addFirestoreDoc,
  checkUserType,
  getUserFromFirestore,
  updateFirestoreDoc,
  usePositions,
  useShifts,
  useUserData,
} from "./Functions/firebaseFunctions";

import {
  Alert,
  Box,
  Button,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Typography,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { colours } from "./config/colours";
import AdminRoutes from "./routes/AdminRoutes";
import { authRouter } from "./routes/AuthRoutes";
import { employerRouter } from "./routes/EmployerRoutes";
import { nomadRouter } from "./routes/NomadRoutes";
import UserDataContext from "./Context/UserDataContext";
import { searchData } from "./Functions/firebaseFunctions";
import ProfileSetupDialog from "./Components/ProfileSetupDialog";
import { setDefaults, setKey } from "react-geocode";
import { defaultLocation, defaultShift } from "./data/defaultObejcts";
import { arrayRemove, arrayUnion, collection, doc } from "firebase/firestore";
import { createNewChat } from "./Screens/Employer/NomadApplicationAccount";
import {
  notifyHired,
  useNotifications,
} from "./Functions/notificationFunctions";
import { ErrorBoundary } from "./Components/ErrorBoundary";
import {
  checkInvoicePaid,
  createCustomerAccount,
  generateStripeIDForExistingUser,
} from "./Functions/stripeFunctions";
import DebugButtons from "./Components/DebugButtons";
import TermsAndConditions from "./Components/TermsAndConditions";
import VerificationBlockerDialog from "./Components/VerificationBlockerDialog";
import { EmployerProvider } from "./Context/EmployerContext";
let theme = createTheme({
  palette: {
    primary: { main: "#134D82" },
    background: { default: colours.white300 },
    secondary: { main: "#EDF7FF", contrastText: colours.blue700 },
    success: { main: "#E6F3E5", contrastText: "#17845E" },
    inverted: {
      main: "white",
      contrastText: "#134D82",
    },
    error: {
      main: "#FDE4E1",
      contrastText: "#F54336",
    },
  },

  typography: {
    body3: { fontSize: "16px", fontWeight: 500 },
    body4: { fontSize: "16px", fontWeight: 400 },
    body5: { fontSize: "16px", fontWeight: 600 },
    body6: { fontSize: "14px", fontWeight: 500 },
    body7: {
      fontSize: "14px",
      fontWeight: 500,
      textDecorationLine: "underline",
    },
    body4: { fontSize: "14px", fontWeight: 400, lineHeight: "20px" },
  },
  components: {
    MuiFormControlLabel: {
      styleOverrides: { label: { fontWeight: 400, fontSize: "14px" } },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          color: "white",
          backgroundColor: colours.red700,
        },
      },
    },

    MuiTypography: {
      styleOverrides: {
        h1: {
          fontSize: "40px",
          fontWeight: 700,
        },
        h2: { fontSize: "32px", fontWeight: 700 },
        h3: { fontSize: "32px", fontWeight: 600 },
        h4: { fontSize: "24px", fontWeight: 700 },
        h5: { fontSize: "24px", fontWeight: 600 },
        subtitle1: { fontSize: "20px", fontWeight: 500 },
        subtitle2: { fontSize: "20px", fontWeight: 400 },
        body1: { fontSize: "20px", fontWeight: 500 },
        body3: { fontSize: "20px", fontWeight: 400 },
        body4: { fontSize: "14px", fontWeight: 400 },
        body5: { fontSize: "12px", fontWeight: 400, color: colours.black500 },
        body6: { fontSize: "10px", fontWeight: 400, color: colours.black400 },
        small1: {
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "normal",
        },
        small4: { fontSize: "10px", fontWeight: 400, color: colours.black400 },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: colours.black600,
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: { height: "67px" },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 3,
        },
      },
    },
  },
});
export const AlertContext = React.createContext({});

function Main() {
  const [signedIn, setSignedIn] = useState(false);
  const debugMode = false;
  const [userID, setUserID] = useState("");
  const [stripeID, setStripeID] = useState("");
  const shifts = useShifts(userID);
  const newNotifications = useNotifications(userID, false);
  const viewedNotifications = useNotifications(userID, true);

  //const [shifts, setShifts] = useState({});
  const userData = useUserData(userID);
  const { toggleAlert, toggleDialog, closeDialog } = useContext(AlertContext);
  const [userType, setUserType] = useState("");
  const [usserData, setUserData] = useState({
    displayName: null,
    photoURL: null,
    userType: null,
    email: "",
  });

  setKey("AIzaSyBoHq3-4VZLva6NNttaZlD-K10mTPdlsgU");
  /**Employer data */
  const [positions, setPositions] = useState({});
  //const [shifts, setShifts] = useState({});
  const [locations, setLocations] = useState([]);

  /**
   *
   * @todo many of the functions in this file should eventually be moved over to EmployerContext.jsx
   * for better organization of files
   */
  const addPosition = (positionData, callback = () => {}) => {
    addFirestoreDoc("positions", {
      ...positionData,
      createdAt: new Date(),
    }).then(({ id, error }) => {
      if (id) {
        callback(id);
        console.log("Added position with id", id);
        toggleAlert("Successfully added new shift", "success");
      }
      error && toggleAlert("Something went wrong...", "error");
      setPositions((prev) => ({ ...prev, [id]: { ...positionData, id: id } }));
    });
  };
  /**Employer functions */
  const checkExistingPositions = async (userID) => {
    console.log("checking for existing positions");
    var positionData = await searchData("positions", "uid", userID, "==");
    let tempPos = {};
    positionData.forEach((doc) => {
      let docData = doc.data();
      tempPos = {
        ...tempPos,
        [doc.id]: {
          id: doc.id,
          title: docData.title,
          numFilled: 0,
          numUnfilled: 0,
          ...doc.data(),
        },
      };
    });
    setPositions(tempPos);
  };
  const checkExistingLocations = async (userID) => {
    console.log("checking for existing locations");
    var locationData = await searchData(
      "locations",
      "admins",
      userID,
      "array-contains"
    );
    let tempLocs = [];
    locationData.forEach((doc) => {
      let docData = doc.data();
      tempLocs = [...tempLocs, { ...docData, id: doc.id }];
    });
    console.log("found locations", tempLocs);
    setLocations(tempLocs);
  };
  const addUserLocation = (newData) => {
    console.log("Adding user locaiton", newData);
    setLocations([...locations, { ...defaultLocation, ...newData }]);
    addFirestoreDoc("locations", { ...defaultLocation, ...newData }).then(
      () => {
        toggleAlert("Added Location");
      }
    );
  };

  const setHiredNomad = (
    shiftID,
    applicantUID,
    invoiceID,
    applicantDisplayName = "A Nomad",
    shiftTitle = "A Listing",
    applicantPhotoURL = null
  ) => {
    checkInvoicePaid(invoiceID).then((isPaid) => {
      if (isPaid || userData.devMode) {
        const shiftData = shifts[shiftID]
          ? { ...defaultShift, ...shifts[shiftID] }
          : defaultShift;
        console.log(applicantUID);
        if (shiftData.available) {
          updateFirestoreDoc(shiftID, "shifts", {
            available: false,
            worker: {
              uid: applicantUID,
              displayName: applicantDisplayName,
              photoURL:
                applicantPhotoURL == undefined ? null : applicantPhotoURL,
            },
          })
            .then(() => {
              toggleAlert("You successfully hired this Nomad.");
            })
            .catch(() => {
              toggleAlert("An error occurred.", "error");
            });

          updateFirestoreDoc(shiftID, "listings", {
            //TODO: remove this
            available: false,
            worker: applicantUID,
          });
          const posID = shifts[shiftID].positionID;
          if (posID !== null && posID !== undefined && posID !== "") {
            updateFirestoreDoc(posID, "positions", {
              filledShifts: arrayUnion(shiftID),
            });
            updateFirestoreDoc(posID, "positions", {
              unfilledShifts: arrayRemove(shiftID),
            });
          }

          createNewChat(
            applicantUID,
            userID,
            {
              displayName: applicantDisplayName,
              photoURL: applicantPhotoURL ? applicantPhotoURL : null,
            },
            {
              displayName: userData.displayName ? userData.displayName : null,
              photoURL: userData.photoURL ? userData.photoURL : null,
            }
          );
          notifyHired(applicantUID, shiftData.title, shiftID);
          updateFirestoreDoc(applicantUID, "workers", {
            hiredShifts: arrayUnion(shiftID),
          });
        }
      } else {
        toggleDialog(
          "Incomplete Payment",
          "You need to fully pay the invoice for this shift before you can hire a Nomad."
        );
      }
    });
  };
  const archiveShift = (shiftID) => {
    updateFirestoreDoc(shiftID, "listings", {
      available: false,
      archived: true,
      applicants: [],
    });
    updateFirestoreDoc(shiftID, "shifts", {
      available: false,
      archived: true,
      applicants: [],
    });
    updateFirestoreDoc(shifts[shiftID].positionID, "positions", {
      unfilledShifts: arrayRemove(shiftID),
    });
  };
  const handleArchiveShift = (shiftID, title) => {
    toggleDialog(
      "Delete Shift",
      "Are you sure you want to delete " +
        (title ? title + "?" : " this shift?"),
      [
        {
          title: "Delete",
          onClick: () => {
            archiveShift(shiftID);
          },
        },
        {
          title: "Cancel",
        },
      ]
    );
  };
  const agreeToTerms = () => {
    updateFirestoreDoc(userID, "users", { agreedToTerms: true });
  };
  const archivePosition = (id) => {
    updateFirestoreDoc(id, "positions", { archived: true });
    const temp = positions;
    temp[id] = { ...temp[id], archived: true };
    setPositions(temp);
  };
  const handleArchivePosition = (positionID, title) => {
    toggleDialog(
      "Delete Position",
      "Are you sure you want to delete " +
        (title ? title + "?" : " this position?"),
      [
        {
          title: "Delete",
          onClick: () => {
            archivePosition(positionID);
          },
        },
        {
          title: "Cancel",
        },
      ]
    );
  };
  const checkExistingShifts = async (userID) => {
    var shiftData = await searchData("shifts", "uid", userID, "==");
    let tempShifts = [];
    shiftData.forEach((doc) => {
      let docData = doc.data();
      tempShifts.push({
        id: doc.id,
        positionID: docData.id,
        title: docData.title,
        address: docData.address,
        category: docData.category,
        startDate: docData.date,
        description: docData.description,
        endDate: docData.endTime,
        locationType: docData.locationType,
        rate: docData.rate,
        type: docData.type,
      });
    });
    //setShifts(tempShifts);
  };

  useEffect(() => {
    if (userData.userType === "employer") {
      console.log("getting user positions & locations");
      checkExistingPositions(userID);
      checkExistingLocations(userID);
      //checkExistingShifts(userID);
    }
  }, [userData.userType, userID]);
  useEffect(() => {
    //Ensure a user is logged in or out
    auth.onAuthStateChanged((user) => {
      console.log("auth state change detected", user);
      if (user) {
        console.log("user found", user);
        setSignedIn(true);
        setUserID(user.uid);
        getUserFromFirestore(user.uid).then(({ user: data }) => {
          data && setUserData(data);
          data && setUserType(data.userType);
          data && setStripeID(data.stripeID);
          if (data.stripeID === null || data.stripeID === undefined) {
            generateStripeIDForExistingUser(
              user.uid,
              data.email,
              data.displayName
            );
          }
          console.log("Found user data", data);
        });
      } else {
        console.log("no user found");
        setSignedIn(false);
        setUserType("");
        setUserID("");
        setPositions([]);
        setLocations([]);
      }
    });
  }, []);

  if (signedIn && userData.userType === "Admin") {
    return <AdminRoutes />;
  }
  if (signedIn && userData.userType === "employer") {
    return (
      <UserDataContext.Provider
        value={{
          userID,
          userData,
          displayName: userData.displayName,
          photoURL: userData.photoURL,
          positions,
          locations,
          shifts,
          newNotifications,
          viewedNotifications,
          setUserType,
          setUserData,
          addUserLocation,
          addPosition,
          agreeToTerms,
          setHiredNomad,
          handleArchiveShift,
          handleArchivePosition,
          stripeID,
          shifts,
          debugMode,
        }}
      >
        {userData?.setupComplete ? (
          <VerificationBlockerDialog />
        ) : (
          <ProfileSetupDialog />
        )}

        {userData && !userData.agreedToTerms && <TermsAndConditions />}
        <RouterProvider router={employerRouter} />
      </UserDataContext.Provider>
    );
  }
  if (signedIn && userData.userType === "worker") {
    return (
      <UserDataContext.Provider value={{ userID }}>
        <RouterProvider router={nomadRouter}></RouterProvider>
      </UserDataContext.Provider>
    );
  }
  if (signedIn === false) {
    console.log("signed in false");
    return (
      <UserDataContext.Provider
        value={{
          userID,
          userData,
          displayName: userData.displayName,
          photoURL: userData.photoURL,
          positions,
          locations,
          shifts,
          setUserType,
          setUserData,
          addUserLocation,
          addPosition,
          setHiredNomad,
          stripeID,
          shifts,
          debugMode,
        }}
      >
        <RouterProvider router={authRouter}></RouterProvider>
      </UserDataContext.Provider>
    );
  }
}
export default function App() {
  const [alert, setAlert] = useState({
    title: "",
    variant: "success",
    color: "info",
  });
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
    content: <Typography>{""}</Typography>,
    buttons: [{ title: "Dismiss", onClick: () => {} }],
  });
  const toggleDialog = (
    title,
    content,
    buttons = [
      {
        title: "Dismiss",
        onClick: closeDialog,
      },
    ]
  ) => {
    setDialog({
      open: true,
      title: title,
      content: <Typography variant="body">{content}</Typography>,
      buttons: buttons,
    });
  };
  const closeDialog = () => {
    setDialog({
      open: false,
      title: "",
      content: <Typography>{""}</Typography>,
      buttons: [{ title: "Dismiss", onClick: closeDialog }],
    });
  };
  //setDefaults({ key: "AIzaSyDxiFnFT2lQzNJIbxsadUkhbv8xcQJMm34" });
  const [showAlert, setShowAlert] = useState(false);
  const toggleAlert = (title, color = "info") => {
    setAlert({ title: title, color: color });
    setShowAlert(true);
  };
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <Snackbar
          variant="outlined"
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          open={showAlert}
          autoHideDuration={6000}
          onClose={() => {
            setShowAlert(false);
          }}
        >
          <Alert
            elevation={3}
            sx={{ zIndex: 10 }}
            severity={alert.color}
            variant="filled"
          >
            {alert?.title}
          </Alert>
        </Snackbar>
        <AlertContext.Provider
          value={{ toggleAlert, toggleDialog, closeDialog }}
        >
          <ErrorBoundary>
            <Dialog open={dialog.open}>
              <DialogTitle>{dialog.title}</DialogTitle>
              <DialogContent>{dialog.content}</DialogContent>

              {Symbol.iterator in Object(dialog.buttons) && (
                <DialogActions>
                  {dialog.buttons.map((btn, index) => (
                    <Button
                      onClick={() => {
                        btn.onClick && btn.onClick();
                        closeDialog();
                      }}
                    >
                      {btn.title}
                    </Button>
                  ))}
                </DialogActions>
              )}
            </Dialog>

            <Main></Main>
          </ErrorBoundary>
        </AlertContext.Provider>
      </LocalizationProvider>
    </ThemeProvider>
  );
  /* if (userType === "Admin") {
    return <AdminRoutes />;
  }

  if (userType === "Employer") {
    return (
      <ThemeProvider theme={theme}>
        <RouterProvider router={employerRouter}></RouterProvider>
      </ThemeProvider>
    );
  }
  if (userType === "Nomad") {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<ComingSoon />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Router>
    );
  }
  if (signedIn === false) {
    return <SignInBox />;
  } */
}
