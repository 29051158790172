import { db } from "../config/firebase";
import { getDocs, collection } from "firebase/firestore";
import React, { useEffect, useState } from "react";

export default function ViewAllAccounts() {
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    const userCollectionRef = collection(db, "users");
    const getUserList = async () => {
      //READ the data
      const data = await getDocs(userCollectionRef);
      const filteredData = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setUserList(filteredData);
    };
    getUserList();
  }, []);
  return (
    <>
      <div>
        <h1 style={{ color: "white" }}> View All Registered Accounts: </h1>
        <div
          style={{
            display: "grid",
            gridGap: 50,
            gridTemplateColumns: "1fr 1fr 1fr 1fr ",
          }}
        >
          {userList.map((user) => (
            <div
              class="UserBox"
              style={{
                backgroundColor: "white",
                borderRadius: 10,
                height: 475,
                width: 350,
                justifyContent: "center",
              }}
              onmouseover="this.style.backgroundColor='grey'"
            >
              <img
                style={{
                  width: 125,
                  height: 125,
                  borderRadius: "50%",
                  marginTop: 50,
                }}
                src={
                  user.photoURL
                    ? user.photoURL
                    : "https://i.giphy.com/media/4WETSuON0ubbOpZcz5/200w.gif"
                }
                alt="User profile pic"
              ></img>
              <p style={{ color: "black", fontSize: 28, fontWeight: "bold" }}>
                {user.displayName}
              </p>
              <p style={{ color: "black", fontSize: 20 }}> {user.email}</p>
              <p style={{ color: "black", fontSize: 20 }}>
                {" "}
                Gender: {user.gender}
              </p>
              <p style={{ color: "black", fontSize: 20 }}> Bio: {user.bio}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
