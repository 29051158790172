import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import UserDataContext from "../Context/UserDataContext";
import FormSection from "./FormSection";
import { colours } from "../config/colours";
import LocationForm from "./LocationForm";
import { defaultLocation, defaultUser } from "../data/defaultObejcts";
import { Controller, useForm } from "react-hook-form";
import {
  geocodeAddress,
  updateFirestoreDoc,
} from "../Functions/firebaseFunctions";
import Spacer from "./Spacer";
import DebugButtons from "./DebugButtons";
import { geohashForLocation } from "geofire-common";
import UserProfileForm from "./UserProfileForm";
import { componentDimensions } from "../config/dimensions";

function EditProfileDialog({ open, onDismiss }) {
  const { userData, setUserData, addUserLocation, locations, userID } =
    useContext(UserDataContext);
  const [currentSection, setCurrentSection] = useState(0);
  const [completedSections, setCompletedSections] = useState({
    0: true,
    1: true,
    2: false,
  });
  const sectionCount = 3;
  const {
    control: userControl,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      displayName: userData.displayName,
      industry: userData.industry,
      companyName: userData.companyName,
      bio: userData.bio,
    },
  });
  const handleUserData = (data) => {
    console.log(data);
    updateFirestoreDoc(userID, "users", {
      ...data,
    });
    //setUserData({ ...userData, displayName: data.displayName, bio: data.bio });
    //setCompletedSections({ ...completedSections, [currentSection]: true });
  };
  const FormInput = ({ placeholder, label, name }) => {
    return (
      <Grid item xs={12} sm={12}>
        <FormLabel>{label}</FormLabel>
        <Controller
          control={userControl}
          name={name}
          render={({ field: { ref, ...rest } }) => (
            <TextField
              required
              fullWidth
              multiline
              //value={locationValues.address}
              placeholder={placeholder}
              {...rest}
            ></TextField>
          )}
        ></Controller>
      </Grid>
    );
  };
  return (
    <Dialog open={open}>
      <form
        onSubmit={handleSubmit((data, e) => {
          e.preventDefault();
          console.log("submitted form");
          console.log(data);
          handleUserData(data);
          onDismiss();
        })}
      >
        <Box sx={{ paddingX: componentDimensions.spacing.small.pixels }}>
          <DialogContent>
            {" "}
            <DebugButtons>{userControl._formValues}</DebugButtons>
            <Grid
              item
              container
              spacing={componentDimensions.spacing.small.pixels}
            >
              <FormInput
                placeholder={"Display Name"}
                name={"displayName"}
                label={"Display Name"}
              />
              <FormInput
                placeholder={"Company Name"}
                name={"companyName"}
                label={"Company Name"}
              />
              <FormInput placeholder={"Bio"} name={"bio"} label={"Bio"} />{" "}
              <FormInput
                placeholder={"Industry"}
                name={"industry"}
                label={"Industry"}
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                onDismiss();
                reset();
              }}
            >
              Cancel
            </Button>
            <Button type="submit" variant="contained">
              Submit
            </Button>
          </DialogActions>
        </Box>
      </form>
    </Dialog>
  );
}

EditProfileDialog.propTypes = {};

export default EditProfileDialog;
