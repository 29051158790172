import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import UserDataContext from "../Context/UserDataContext";
import FormSection from "./FormSection";
import { colours } from "../config/colours";
import LocationForm from "./LocationForm";
import { defaultLocation, defaultUser } from "../data/defaultObejcts";
import { useForm } from "react-hook-form";
import {
  geocodeAddress,
  updateFirestoreDoc,
} from "../Functions/firebaseFunctions";
import Spacer from "./Spacer";
import DebugButtons from "./DebugButtons";
import { geohashForLocation } from "geofire-common";
import UserProfileForm from "./UserProfileForm";
import { componentDimensions } from "../config/dimensions";

function ProfileSetupDialog() {
  const { userData, setUserData, addUserLocation, locations, userID } =
    useContext(UserDataContext);
  const [currentSection, setCurrentSection] = useState(0);
  const [completedSections, setCompletedSections] = useState({
    0: true,
    1: true,
    2: false,
  });
  const sectionCount = 3;
  const { control, handleSubmit } = useForm({ defaultValues: defaultLocation });
  const { control: userControl, handleSubmit: handleSubmitUser } = useForm({
    defaultValues: defaultUser,
  });

  const handleData = (data) => {
    const geocodeString =
      (data.address ? data.address + " " : "") +
      (data.postalCode ? data.postalCode + " " : "") +
      (data.city ? data.city + ", " : "") +
      (data.province ? data.province : "") +
      ", Canada";
    geocodeAddress(geocodeString, ({ lat, lng }) => {
      let temp = { ...defaultLocation, ...data };
      temp.dateCreated = new Date();
      temp.admins = [userID];
      temp.latitude = lat;
      temp.longitude = lng;
      temp.geohash = geohashForLocation([lat, lng]);
      //set latitude and longitude of the location
      //temp.email = userData.email ? userData.email : null;
      //temp.phoneNumber = userData.phone ? userData.phone : null;
      setCompletedSections({ ...completedSections, [currentSection]: true });
      addUserLocation(temp);
    });
  };
  const handleUserData = (data) => {
    console.log(data);
    updateFirestoreDoc(userID, "users", {
      displayName: data.displayName,
      bio: data.bio,
    });
    //setUserData({ ...userData, displayName: data.displayName, bio: data.bio });
    //setCompletedSections({ ...completedSections, [currentSection]: true });
  };
  return (
    <Dialog open={!userData.setupComplete}>
      <Box sx={{ paddingX: componentDimensions.spacing.small.pixels }}>
        <DialogContent>
          <FormSection
            currentSection={currentSection}
            section={0}
            title={"Welcome to NomadShifts!"}
          >
            <Grid item>
              <Typography variant="body2">
                Let's finish setting up your account before you get started.
              </Typography>
            </Grid>
          </FormSection>
          <FormSection
            currentSection={currentSection}
            section={1}
            title={"Employer Information"}
          >
            {" "}
            <DebugButtons>{userControl._formValues}</DebugButtons>
            <form
              onSubmit={handleSubmitUser((data) => {
                handleUserData(data);
              })}
            >
              <UserProfileForm control={userControl}></UserProfileForm>
            </form>
          </FormSection>
          <FormSection
            currentSection={currentSection}
            section={2}
            title={"Business Location(s)"}
          >
            <DebugButtons
              onClick={() => {
                geocodeAddress("33672 Arcadian Way Abbotsford BC Canada");
              }}
            >
              {process.env.GOOGLE_MAPS}
            </DebugButtons>
            <form
              onSubmit={handleSubmit((data) => {
                handleData(data);
              })}
            >
              <LocationForm control={control} /> <Spacer />
              <Button
                variant="contained"
                disabled={completedSections[2]}
                type="submit"
              >
                Add Location
              </Button>
            </form>
          </FormSection>
          <FormSection
            currentSection={currentSection}
            section={3}
            title={"Setup Complete!"}
          >
            <Grid item>
              <Typography variant="body2">
                Almost there! Your account will need to be verified before you
                can start creating shifts.
              </Typography>
            </Grid>
          </FormSection>
        </DialogContent>
        <DialogActions>
          {/* <Button
            disabled={currentSection <= 0}
            onClick={() => {
              setCurrentSection((prev) => prev - 1);
            }}
          >
            Back
          </Button> */}
          {currentSection < sectionCount && (
            <Button
              disabled={!completedSections[currentSection]}
              onClick={() => {
                if (currentSection == 1) {
                  handleUserData(userControl._formValues);
                }
                setCurrentSection((prev) => prev + 1);
              }}
            >
              Next
            </Button>
          )}
          {currentSection >= sectionCount && (
            <Button
              onClick={() => {
                updateFirestoreDoc(userID, "users", { setupComplete: true });
                setUserData({ ...userData, setupComplete: true });
              }}
            >
              Finish
            </Button>
          )}
        </DialogActions>
      </Box>
    </Dialog>
  );
}

ProfileSetupDialog.propTypes = {};

export default ProfileSetupDialog;
