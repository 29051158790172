import React from "react";
import logo from "../logo.png";
import { auth } from "../config/firebase";
import { Button } from "@mui/material";
import { ExitToApp } from "@mui/icons-material";

function Logout() {
  const signOut = async () => {
    try {
      auth.signOut();
      console.log("You were signed out of your account");
    } catch (error) {
      console.error("There was an error signing out");
    }
  };

  return (
    <div>
      <img src={logo} className="App-logo" alt="logo" />

      <h1>Are you sure you want to Logout?</h1>
      <Button
        variant="contained"
        onClick={() => signOut()}
        startIcon={<ExitToApp />}
      >
        Logout
      </Button>
    </div>
  );
}

export default Logout;
