import { auth } from "../../config/firebase";
//import "../Styles/App.css";
import { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { ReactComponent as AppBarLogo } from "../../assets/logos/appbar-logo-coloured.svg";
import { ReactComponent as SmallLogo } from "../../assets/logos/logo-small-coloured.svg";
import { colours } from "../../config/colours";
import { Visibility } from "@mui/icons-material";

function AccountSetup() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    companyName: "",
    position: "",
    industry: "",
  });
  const signIn = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
      console.error(err);
      return err;
    }
  };
  return (
    <Grid
      container
      rowSpacing={3}
      sx={{
        display: "flex",
        height: window.innerHeight,
      }}
    >
      {/**Master Container */}

      <Grid xs={12} md={6} item>
        <Grid
          container
          spacing={1.25}
          sx={{ justifyContent: "center", padding: "100px" }}
        >
          {/**logo */}
          <Grid item xs={12} sx={{ alignItems: "center", display: "flex" }}>
            <SmallLogo />
            <AppBarLogo />
          </Grid>
          {/**Intro text */}
          <Grid item xs={12}>
            <Typography fontWeight={700} fontSize={"24px"}>
              Tell us about yourself so we can help you get the most of{" "}
              <span style={{ color: colours.blue600 }}> Nomad Shifts.</span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <InputLabel>First Name*</InputLabel>
            <TextField
              value={formData.firstname}
              onChange={(event) => {
                setFormData({
                  ...formData,
                  firstname: event.target.value,
                });
              }}
              fullWidth
              variant="outlined"
              type="text"
              id="firstname"
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Last Name*</InputLabel>
            <TextField
              value={formData.firstname}
              onChange={(event) => {
                setFormData({
                  ...formData,
                  lastname: event.target.value,
                });
              }}
              fullWidth
              variant="outlined"
              type="text"
              id="lastname"
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Company Name*</InputLabel>
            <TextField
              value={formData.companyName}
              onChange={(event) => {
                setFormData({
                  ...formData,
                  companyName: event.target.value,
                });
              }}
              fullWidth
              variant="outlined"
              id="companyName"
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Position*</InputLabel>
            <TextField
              value={formData.position}
              onChange={(event) => {
                setFormData({
                  ...formData,
                  position: event.target.value,
                });
              }}
              fullWidth
              variant="outlined"
              type="text"
              id="position"
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Industry</InputLabel>
            <OutlinedInput
              type="text"
              value={formData.industry}
              onChange={(event) => {
                setFormData({
                  ...formData,
                  industry: event.target.value,
                });
              }}
              fullWidth
              variant="outlined"
              id="industry"
            ></OutlinedInput>
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Password*</InputLabel>
            <OutlinedInput
              type="password"
              value={formData.password}
              onChange={(event) => {
                setFormData({
                  ...formData,
                  password: event.target.value,
                });
              }}
              fullWidth
              variant="outlined"
              id="password"
              helperText="Must be at least 8 characters"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton>
                    <Visibility />
                  </IconButton>
                </InputAdornment>
              }
            ></OutlinedInput>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained">Next</Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        xs={0}
        md={6}
        item
        sx={{
          backgroundColor: colours.blue600,
          display: { xs: "none", md: "block" },
        }}
      >
        <Box>item</Box>
      </Grid>
    </Grid>
  );
}

export default AccountSetup;
