import React, { useState, useContext } from "react";
import AddPositionForm from "./AddPositionForm";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import RightArrow from "@mui/icons-material/ChevronRight";
import BackArrow from "@mui/icons-material/ChevronLeft";
import {
  componentDimensions,
  fullWidth,
  fullSize,
} from "../../config/dimensions";
import { Check } from "@mui/icons-material";
import { colours } from "../../config/colours";
import { useNavigate } from "react-router-dom";
import {
  addDocument,
  addFirestoreDoc,
} from "../../Functions/firebaseFunctions";
import UserDataContext from "../../Context/UserDataContext";
import { AlertContext } from "../../App";

const sections = [
  { label: "Basic Information", id: 1 },
  { label: "Position Requirements", id: 2 },
  { label: "Application Questions", id: 3 },
  { label: "Confirmation", id: 4 },
];

/**
 * @function AddPosition
 * @description This component acts as a wrapper and form control for the AddPositionForm component
 */
function AddPosition() {
  const { userID, addPosition, userData } = useContext(UserDataContext);
  const { toggleAlert } = useContext(AlertContext);
  const [positionData, setPositionData] = useState(null);
  const [formData, setFormData] = useState({
    uid: userID,
    title: "",
    category: "",
    description: "",
    type: "",
    requirements: "",
    skills: [],
    documents: {
      Resume: false,
      "Cover Letter": false,
      Transcript: false,
      "Work Samples": false,
      Other: false,
    },
    questions: [{ prompt: "", inputType: "text" }],
  });
  const [currentSection, setCurrentSection] = useState(1);
  const [showDialog, setShowDialog] = useState(false);
  const navigate = useNavigate();
  const [dialogData, setDialogData] = useState({
    title: "",
    subtitle: "",
    description: "",
  });
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        height:
          window.innerHeight -
          componentDimensions.appbarHeight.default -
          componentDimensions.spacing.medium.default * 2 -
          80,
        width:
          window.innerWidth -
          componentDimensions.drawerWidth.default -
          componentDimensions.secondaryDrawerWidth.default -
          componentDimensions.spacing.medium.default * 2,
      }}
    >
      {" "}
      <Dialog open={showDialog}>
        <DialogContent>
          <Alert color="success" variant="filled">
            The position has been successfully created
          </Alert>
          <Typography variant="body3">
            Would you like to post a shift now?
          </Typography>
          <Box
            sx={{
              backgroundColor: colours.black200,
              margin: componentDimensions.spacing.small.pixels,

              padding: componentDimensions.spacing.small.pixels,
            }}
          >
            {formData.title}
          </Box>
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "space-evenly" }}>
          <Button
            onClick={() => {
              navigate("../");
            }}
          >
            Add Shift
          </Button>
          <Button
            onClick={() => {
              setShowDialog(false);
            }}
          >
            Not Now
          </Button>
        </DialogActions>
      </Dialog>
      <Card
        className="companyCard"
        sx={{
          paddingX: "35px",
          paddingY: componentDimensions.spacing.small.pixels,
          borderRadius: 0.5,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginX: 5,
          marginY: 2,
        }}
      >
        <Avatar />
        <Typography fontSize={"20px"} style={{ marginLeft: "20px" }}>
          {userData.displayName}
        </Typography>
      </Card>
      <Stepper sx={{ marginX: 5 }} activeStep={currentSection - 1}>
        {sections.map((step, index) => {
          return (
            <Step key={index}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Card
        sx={{
          position: "absolute",
          right: 0,
          left: 0,
          bottom: 50,
          top: 150,
          flexDirection: "column",
          marginX: 5,
          padding: 5,
          marginY: 2,
          overflow: "scroll",
        }}
      >
        <AddPositionForm
          positionData={positionData}
          formData={formData}
          setFormData={setFormData}
          currentSection={currentSection}
        />
      </Card>{" "}
      <Box
        sx={{
          position: "absolute",
          bottom: componentDimensions.spacing.small.pixels,
          left: componentDimensions.spacing.large.pixels,
          right: componentDimensions.spacing.large.pixels,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          disabled={currentSection <= 1}
          onClick={() => {
            setCurrentSection(currentSection - 1);
          }}
          variant="text"
          startIcon={<BackArrow />}
        >
          Back
        </Button>
        {currentSection >= 4 ? (
          <Button
            onClick={() => {
              //handleAddNewPosition
              const d = new Date();
              addPosition(
                {
                  ...formData,
                  createdAt: new Date(),
                },
                () => {
                  setShowDialog(true);
                }
              );
            }}
            variant="contained"
            endIcon={<Check />}
          >
            SUBMIT
          </Button>
        ) : (
          <Button
            disabled={currentSection >= 4}
            onClick={() => {
              setCurrentSection(currentSection + 1);
            }}
            variant="contained"
            endIcon={<RightArrow />}
          >
            Next
          </Button>
        )}
      </Box>{" "}
    </Box>
  );
}

AddPosition.propTypes = {};

export default AddPosition;
