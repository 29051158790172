import React, { useContext, useEffect, useState } from "react";
import * as stripe from "../../Functions/stripeFunctions";
import { NavigatorContext } from "../../Context/NavigatorContext";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Stack,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { componentDimensions } from "../../config/dimensions";
import "../../Styles/Payment.css";
import mastercard from "../../assets/logos/mastercard.jpg";
import { colours } from "../../config/colours";
import UserDataContext from "../../Context/UserDataContext";
import DebugButtons from "../../Components/DebugButtons";
import {
  getStripeTimestampAsDate,
  getTimestampAsString,
  moneyFormatter,
} from "../../Functions/utilFunctions";
import { ScreenContainer } from "../../Components/ScreenContainer";
import CardBackground from "../../Components/CardBackground";
export default function Payments() {
  const { setPathTitle, showPathTitle } = useContext(NavigatorContext);
  const { userData, stripeID } = useContext(UserDataContext);
  const [invoices, setInvoices] = useState([]);
  useEffect(() => {
    setPathTitle("Payments");
  }, []);
  //Get the employers payment history currently gets all payment history so sorting what where the customer = customer ID is required
  useEffect(() => {
    if (stripeID !== null) {
      console.log(stripeID);
      // initializePaymentSheet();
      stripe.getInvoices(stripeID).then((invoices) => {
        setInvoices(invoices);
      });
    }
  }, [stripeID]);

  /*//const userContext = useContext(UserContext);
    const [pastTransactions, setPastTransactions] = useState([]);
  
   //Get the employers payment history currently gets all payment history so sorting what where the customer = customer ID is required
    useEffect(() => {
        stripe
        .getEmployerPaymentHistory("cus_NsqQXp2iOOMKWM")
        .then(({ payments }) => {
                //Sort out only payments that match the users stripe ID
                let paymentArray = [];
                for (let i = 0; i < 1; i++ ){
                    if((payments[i]["customer"]) == "cus_NsqQXp2iOOMKWM"){
                        paymentArray.push(payments[i]);
                    }          
                }
            setPastTransactions(paymentArray);
            console.log(pastTransactions[0]['amount_due']);
        });
    }, []);*/

  return (
    <ScreenContainer>
      <Grid container spacing={componentDimensions.spacing.medium.pixels}>
        <Grid item xs={12}>
          <Alert
            elevation={3}
            sx={{ zIndex: 10 }}
            severity={"info"}
            variant="filled"
          >
            To pay invoices, check the inbox for email address{" "}
            <i>{userData.email}</i>
          </Alert>
        </Grid>
        {false && (
          <Grid item xs={12} sx={{ position: "relative" }}>
            <Typography variant="body1">Your Plan</Typography>
            <Card sx={{ display: "flex", flexDirection: "row" }}>
              <div style={{ flex: "2" }}>
                <CardContent>
                  <ul className="checkUl">
                    <Typography variant="h4">Basic</Typography>
                    <li style={{ content: "✓" }}>$ per shift</li>
                    <li>Shift visible for ## days</li>
                    <li>### applicants maximum</li>
                    <Button
                      sx={{
                        marginTop: componentDimensions.spacing.small.pixels,
                      }}
                      variant="outlined"
                    >
                      Upgrade Plan
                    </Button>
                  </ul>
                </CardContent>
                <Divider />
                <CardContent sx={{ paddingLeft: "49px" }}>
                  <Typography variant="body3">Payment</Typography>
                  <Box
                    display={"flex"}
                    container
                    sx={{
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        marginRight: componentDimensions.spacing.small.pixels,
                      }}
                    >
                      <img src={mastercard}></img>
                    </div>
                    <div>
                      <Typography variant="small3" whiteSpace={"pre-line"}>
                        {" Mastercard ending in 2088\n"}
                      </Typography>
                      <Typography variant="small4">Expires 01/2025</Typography>
                    </div>
                  </Box>
                </CardContent>
              </div>
              <Box
                sx={{
                  flex: "1",
                  border: 1,
                  borderRadius: "4px",
                  borderColor: colours.white700,
                  margin: componentDimensions.spacing.small.pixels,
                  marginLeft: 0,

                  justifyContent: "center",
                  alignItems: "center",
                }}
              ></Box>
            </Card>
          </Grid>
        )}
        <Grid item xs={12}>
          {" "}
          <Typography variant="body1">Invoices</Typography>
          <Card>
            <DebugButtons>{invoices}</DebugButtons>
            <Table>
              <TableRow sx={{ backgroundColor: colours.white700 }}>
                <TableCell>Status</TableCell>

                <TableCell>Total</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Amount Remaining</TableCell>
              </TableRow>
              {invoices?.map(
                ({
                  total,
                  status,
                  period_start,
                  amount_remaining,
                  customer_email,
                }) => (
                  <TableRow>
                    {" "}
                    <TableCell>{status}</TableCell>
                    <TableCell> {moneyFormatter.format(total / 100)}</TableCell>
                    <TableCell>
                      {getTimestampAsString(
                        getStripeTimestampAsDate(period_start)
                      )}
                    </TableCell>
                    <TableCell>
                      {moneyFormatter.format(amount_remaining / 100)}
                    </TableCell>
                    {/* <TableCell>{customer_email}</TableCell> */}
                  </TableRow>
                )
              )}
              {!invoices ||
                (invoices?.length == 0 && (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Typography variant="body4">
                        No invoices found.
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
            </Table>
          </Card>
        </Grid>
      </Grid>
    </ScreenContainer>
  );
}
/*

function PaymentsScreen({ navigation }) {

  const displayTransactionInformation = (transaction) => {
    if (transaction.object == "invoice") {
      var overdue =
        !transaction.paid &&
        getStripeTimestampAsDate(transaction.due_date) < new Date();

      return (
        <View>
          <AppTextSubtitle>
            {moneyFormatter.format(transaction.amount_due / 100)}
          </AppTextSubtitle>
          <AppText>
            {transaction.paid ? "Payment Complete" : "Not Paid"}
          </AppText>

          <AppText>
            {"Payment due: " +
              getTimestampAsString(
                getStripeTimestampAsDate(transaction.due_date),
                "MMMM d, yyyy"
              )}
          </AppText>
          {overdue && (
            <AppText fontColor={colours.danger}>Payment Overdue</AppText>
          )}
        </View>
      );
    }
  };

  return (
    <>
      {pastTransactions && (
        <FlatList
          data={pastTransactions}
          ListFooterComponent={() => {
            return <View style={{ height: 200 }}></View>;
          }}
          ListHeaderComponent={() => {
            return (
              <AppTextHeader style={{ marginHorizontal: 10, marginBottom: 20 }}>
                {t("Transaction History")}
              </AppTextHeader>
            );
          }}
          renderItem={({ item: transaction }) => {
            return (
              <Pressable
                key={transaction.id}
                onPress={() => {
                  console.log(transaction);
                  popupContext.setPopup({
                    title: "Submit Help Request",
                    message:
                      "Would you like to submit a help request about this payment?",
                    buttons: [
                      {
                        title: "Cancel",
                        color: colours.medium,
                        icon: "close",
                        onPress: () => {
                          popupContext.setPopup(null);
                        },
                      },
                      {
                        title: "Proceed",
                        color: colours.secondary,
                        icon: "check",
                        onPress: () => {
                          popupContext.setPopup(null);
                          navigation.navigate(routes.sidebar.HELP, {
                            invoiceID: transaction.id,
                          });
                        },
                      },
                    ],
                  });
                }}
                style={{
                  backgroundColor: colours.foregroundColour,
                  marginBottom: 10,
                  borderRadius: 10,
                  marginHorizontal: 10,
                  padding: 10,
                  ...defaultStyles.elevated,
                }}
              >
                {displayTransactionInformation(transaction)}
              </Pressable>
            );
          }}
        ></FlatList>
      )}
    </>
  );
}
*/
