import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import { DialogTitle } from "@material-ui/core";
import UserDataContext from "../Context/UserDataContext";
import { getFirestoreDoc } from "../Functions/firebaseFunctions";
import Spacer from "./Spacer";

function TermsAndConditions() {
  const handleAgree = () => {
    agreeToTerms();
  };
  const textRef = useRef();
  const [btnEnabled, setBtnEnabled] = useState(false);
  const [textContent, setTextContent] = useState(null);
  const { userData, agreeToTerms } = useContext(UserDataContext);
  useEffect(() => {
    console.log("fetching doc");
    getFirestoreDoc("data", "terms").then(({ data }) => {
      data &&
        setTextContent({
          title: data.title,
          intro: data.intro,
          sections: data.sections,
        });
      console.log(data);
    });
  }, []);
  return (
    <>
      {textContent && (
        <Dialog open scroll={"paper"}>
          <DialogTitle>{textContent?.title}</DialogTitle>
          <DialogContent
            onScroll={(e) => {
              const closeToBottom =
                Math.abs(
                  e.target.scrollHeight -
                    e.target.scrollTop -
                    e.target.clientHeight
                ) < 200;

              if (closeToBottom) {
                setBtnEnabled(true);
              }
            }}
          >
            <div>
              <DialogContentText>{textContent.intro}</DialogContentText>
              {textContent.sections.map(({ title, content }, index) => {
                return (
                  <div key={index}>
                    <Spacer count={2} />
                    <b>{title}</b>
                    <Spacer />
                    <ul>
                      {content.map((text, index) => {
                        return (
                          <div key={index}>
                            <div
                              style={{
                                flexDirection: "row",
                                paddingRight: 30,
                              }}
                            >
                              <li>{text}</li>
                            </div>
                            <Spacer height={5} />
                          </div> //4466 0 579
                        );
                      })}
                    </ul>
                  </div>
                );
              })}
            </div>
          </DialogContent>
          <DialogActions>
            <Button disabled={!btnEnabled} onClick={handleAgree}>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

TermsAndConditions.propTypes = {};

export default TermsAndConditions;
