import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import { componentDimensions } from "../config/dimensions";

const FormDataDisplay = ({ leftLabel, rightLabel }) => {
  return (
    <>
      <Grid item xs={3}>
        <Typography
          fontSize={componentDimensions.textSize.small}
          fontWeight={500}
        >
          {leftLabel}{" "}
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography
          sx={{ whiteSpace: "pre-line" }}
          fontSize={componentDimensions.textSize.small}
          fontWeight={400}
        >
          {rightLabel}
        </Typography>
      </Grid>
    </>
  );
};
FormDataDisplay.propTypes = {};

export default FormDataDisplay;
