import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Button, Rating, Stack, Typography } from "@mui/material";
import {
  ShiftCardFilled,
  ShiftCardUnfilled,
} from "../../Components/Shifts/ShiftCard";
import { useNavigate } from "react-router-dom";
import CardBackground from "../../Components/CardBackground";
import { componentDimensions } from "../../config/dimensions";
import UserDataContext from "../../Context/UserDataContext";
import { getShifts } from "../../Functions/firebaseFunctions";
import DebugButtons from "../../Components/DebugButtons";
import { createNewChat } from "./NomadApplicationAccount";
import { getTimestampAsDate } from "../../Functions/utilFunctions";
import { checkInvoicePaid } from "../../Functions/stripeFunctions";
import { AlertContext } from "../../App";
import { Edit } from "@mui/icons-material";
import ReviewNomadPopup from "../../Components/ReviewNomadPopup";
import { NavigatorContext } from "../../Context/NavigatorContext";

function CompletedShifts() {
  const { shifts, userID, handleArchiveShift, userData } =
    useContext(UserDataContext);
  const { toggleDialog } = useContext(AlertContext);
  const navigate = useNavigate();
  const shiftVals = Object.values(shifts);
  let countShifts = 0;
  const [leaveReview, setLeaveReview] = useState(false);
  const { showPathTitle, setPathTitle } = useContext(NavigatorContext);
  useEffect(() => {
    setPathTitle("Filled Shifts");
  }, []);
  const [selectedShift, setSelectedShift] = useState(null);
  const handleReview = (shift) => {
    setSelectedShift(shift);
    setLeaveReview(true);
  };
  return (
    <CardBackground fullSize>
      <Stack spacing={componentDimensions.spacing.small.pixels}>
        <ReviewNomadPopup
          open={leaveReview}
          nomadName={selectedShift?.worker?.displayName}
          nomadUID={selectedShift?.worker?.uid}
          shiftDate={selectedShift?.startTime}
          shiftTitle={selectedShift?.title}
          shiftID={selectedShift?.id}
          onCancel={() => {
            setLeaveReview(false);
          }}
        />
        {Object.values(shifts)
          .reverse()
          .map((shift) => {
            if (getTimestampAsDate(shift.endTime) < new Date()) {
              countShifts += 1;
              return shift.worker ? (
                <ShiftCardFilled
                  shiftType="paid"
                  key={shift.id}
                  nomadDisplayName={shift.worker.displayName}
                  nomadPhotoURL={shift.worker.photoURL}
                  nomadUID={shift.worker.uid}
                  endDate={shift.endTime}
                  checkinCode={shift.checkinCode}
                  startDate={shift.startTime}
                  title={shift.title}
                  id={shift.id}
                  numApplicants={Object.keys(shift.applicants)?.length}
                  menuItems={[
                    {
                      label: "Review",
                      onClick: () => handleReview(shift),
                      Icon: () => <Edit />,
                    },
                  ]}
                  onClick={(id) => {
                    //Check if shift ID's invoice has been paid for if id paid navigate otherwise popup saying:
                    //"Invoice for this shift must be paid before you can view/message or hire Nomad's who have applied"
                    //navigate("./" + id);
                  }}
                  DropdownContent={() => (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Rating
                        sx={{
                          paddingRight:
                            componentDimensions.spacing.small.pixels,
                        }}
                        readOnly
                        value={shift.reviewOfNomad?.rating}
                      ></Rating>
                      <Typography variant="body2">
                        {shift.reviewOfNomad?.review}
                      </Typography>
                    </Box>
                  )}
                  dropdownOpen={shift.reviewOfNomad}
                ></ShiftCardFilled>
              ) : (
                <ShiftCardUnfilled
                  key={shift.id}
                  endDate={shift.endTime}
                  startDate={shift.startTime}
                  title={shift.title}
                  id={shift.id}
                  onDelete={() => handleArchiveShift(shift.id, shift.title)}
                  numApplicants={Object.keys(shift.applicants)?.length}
                  menuDisabled
                  onClick={() => {
                    //Check if shift ID's invoice has been paid for if id paid navigate otherwise popup saying:
                    //"Invoice for this shift must be paid before you can view/message or hire Nomad's who have applied"
                  }}
                />
              );
            }
          })}
      </Stack>
      {countShifts == 0 && (
        <Typography variant="body2">
          You do not have any completed shifts.
        </Typography>
      )}
    </CardBackground>
  );
}

CompletedShifts.propTypes = {};
export default CompletedShifts;
