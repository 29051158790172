import React, { useState, useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Box, Button, Grid, Popper, Stack, Typography } from "@mui/material";
import { PositionCard } from "../../Components/Shifts/ShiftCard";
import { Add } from "@mui/icons-material";
import { componentDimensions } from "../../config/dimensions";
import { useNavigate } from "react-router-dom";
import { addFirestoreDoc, searchData } from "../../Functions/firebaseFunctions";
import FormDataDisplay from "../../Components/FormDataDisplay";
import UserDataContext from "../../Context/UserDataContext";
import CardBackground from "../../Components/CardBackground";
import DebugButtons from "../../Components/DebugButtons";
import { NavigatorContext } from "../../Context/NavigatorContext";

var samplePositions = [
  { id: "AB1", title: "Barista", numFilled: 5, numUnfilled: 6 },
  { id: "AB2", title: "Cleaner", numFilled: 1, numUnfilled: 0 },
  { id: "AB3", title: "Chef", numFilled: 0, numUnfilled: 0 },
];

function Positions({}) {
  const { userID, positions, locations, shifts, handleArchivePosition } =
    useContext(UserDataContext);
  const { showPathTitle, setPathTitle } = useContext(NavigatorContext);

  const [showPopover, setShowPopover] = useState(null);
  const addPosRef = useRef();
  const navigate = useNavigate();
  const [openPositions, setOpenPositions] = useState({});
  useEffect(() => {
    setPathTitle("Positions");
  }, []);
  let renderedCount = 0;
  return (
    <CardBackground fullSize>
      {positions && (
        <Stack spacing={componentDimensions.spacing.small.pixels}>
          {Object.values(positions).map((position, index) => {
            if (!position.archived) {
              renderedCount += 1;
              return (
                <PositionCard
                  dropdownOpen={openPositions[position.id]}
                  key={position.id ? position.id : index}
                  DropdownContent={() => (
                    <Grid container>
                      <FormDataDisplay
                        leftLabel={"Category"}
                        rightLabel={position.category}
                      />
                      <FormDataDisplay
                        leftLabel={"Description"}
                        rightLabel={position.description}
                      />
                      <FormDataDisplay
                        leftLabel={"Position Type"}
                        rightLabel={position.positionType}
                      />
                      <FormDataDisplay
                        leftLabel={"Requirements"}
                        rightLabel={position.requirements}
                      />
                      <FormDataDisplay
                        leftLabel={"Skills"}
                        rightLabel={position.skills}
                      />
                    </Grid>
                  )}
                  title={position.title}
                  numFilled={position.filledShifts?.length}
                  numUnfilled={position.unfilledShifts?.length}
                  onToggleDropdown={() => {
                    setOpenPositions({
                      ...openPositions,
                      [position.id]: !openPositions[position.id],
                    });
                  }}
                  onClickAddShift={() => {
                    console.log(position);
                    navigate(position.id + "/addshift");
                  }}
                  onDelete={() => {
                    handleArchivePosition(position.id);
                  }}
                />
              );
            }
          })}
        </Stack>
      )}
      {renderedCount == 0 && (
        <>
          <h5>
            Looks like you are new here, here's how to create your first shift:
          </h5>
          <ol type="1">
            <li>
              First, create a position. Think of this as a template for future
              shifts of a specific job.
            </li>
            <li>
              Create a shift under the position. This is a specific posting for
              a potential Nomad (worker).
            </li>
            <li>
              Upon creation of a shift, an invoice will be emailed to you. This
              invoice must be paid in full before you will be able to view
              applicants or hire Nomads.
            </li>
            <li>
              View and sort through Nomad applicants, choose <b>HIRE</b> on the
              Nomad which fits best.
            </li>
          </ol>
        </>
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          /* onMouseOver={() => {
          setShowPopover("pos");
        }}
        onMouseOut={() => {
          setShowPopover(null);
        }}
        ref={addPosRef} */
          sx={{
            marginTop: componentDimensions.spacing.small.pixels,
            alignSelf: "center",
          }}
          startIcon={<Add />}
          variant="contained"
          onClick={() => {
            navigate("addposition");
          }}
        >
          Add Position
        </Button>
      </Box>
      {/* <Popper
        style={{ zIndex: 100 }}
        open={showPopover == "pos"}
        placement="bottom-start"
        anchorEl={addPosRef.current}
      >
        <Box sx={{ backgroundColor: "white" }}>
          Click here to add your first shift!
        </Box>
      </Popper> */}
      <br />
      {/*<Button
        sx={{ marginTop: componentDimensions.spacing.small.pixels }}
        startIcon={<Add />}
        variant="contained"
        onClick={() => {
          fetchPositions(userID);
        }}
      >
        Search Data 
      </Button>*/}
    </CardBackground>
  );
}

Positions.propTypes = {};

export default Positions;
