import React, { useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";

function Shifts(props) {
  const location = useLocation();

  const pathLabel = useMemo(() => {
    let tempPath = location.pathname.split("/", 3)[2];
    switch (tempPath) {
      case "completed":
        return "Completed";
      case "addshift":
        return "Add Shifts";
      case "unfilled":
        return "Unfilled Shifts";
      case "filled":
        return "Filled Shifts";
      default:
        return "Shifts";
    }
  }, [location.pathname]);

  return (
    <>
      <Outlet />
      {/*<h1>Add Shift</h1>
                <h2>Location</h2>
                <label for="fname">Location Type </label>
                <input type="text" id="fname" name="fname"/><br/>

                <label for="fname">Shift Address </label>
                <input type="text" id="fname" name="fname"/><br/>

                <h2>Shift Details</h2>
                <label for="fname">Shift Date </label>
                <input type="text" id="fname" name="fname"/><br/>

                <label for="fname">Start Time </label>
                <input type="text" id="fname" name="fname"/><br/>

                <label for="fname">End Time </label>
                <input type="text" id="fname" name="fname"/><br/>

                <label for="fname">Reoccuring </label>
                <input type="radio" id="fname" name="fname"/><br/>
                <label for="fname">One-Time </label>
                <input type="radio" id="fname" name="fname"/><br/>

                <label for="fname">Repeat every </label>
                <input type="text" id="fname" name="fname"/><br/>

                <label for="fname">Repeat on </label>
                <input type="text" id="fname" name="fname"/><br/>

                <label for="fname">Same time each shift </label>
                <input type="radio" id="fname" name="fname"/><br/>
                <label for="fname">Variable shifts </label>
                <input type="radio" id="fname" name="fname"/><br/><br/>

                <label for="fname">Fixed end date </label>
                <input type="radio" id="fname" name="fname"/><br/>
                <label for="fname">No end date </label>
                <input type="radio" id="fname" name="fname"/><br/><br/>

                <label for="fname">Pay rate </label>
                <input type="text" id="fname" name="fname"/><br/>*/}
    </>
  );
}

export default Shifts;
