import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

const Spacer = ({ count = 1 }) => {
  return <Box sx={{ height: count * 10 + "px" }}></Box>;
};

Spacer.propTypes = {};

export default Spacer;
