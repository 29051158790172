/**
 * help from https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript
 */
export function generateCheckInCode() {
  let code = "";
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const nums = "01234567899";
  code += chars.charAt(Math.floor(Math.random() * chars.length));
  code += nums.charAt(Math.floor(Math.random() * nums.length));
  code += chars.charAt(Math.floor(Math.random() * chars.length));
  code += nums.charAt(Math.floor(Math.random() * nums.length));

  return code;
}
