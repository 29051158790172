import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { ControlPointSharp } from "@mui/icons-material";
import { fromAddress, setDefaults } from "react-geocode";
import { componentDimensions } from "../config/dimensions";
import { defaultLocation } from "../data/defaultObejcts";
import UserDataContext from "../Context/UserDataContext";

function UserProfileForm({ control }) {
  // const [locationValues, setLocationValues] = useState(defaultLocation);

  const { locations } = useContext(UserDataContext);
  return (
    <Grid item container spacing={componentDimensions.spacing.small.pixels}>
      {/* <Grid item xs={12}>
        <Typography variant="body" sx={{ wordWrap: "pre-line" }}></Typography>
      </Grid> */}
      <Grid item xs={12} sm={12}>
        <FormLabel>Display Name</FormLabel>
        <Controller
          control={control}
          name="displayName"
          render={({ field: { ref, ...rest } }) => (
            <TextField
              required
              fullWidth
              placeholder="Display Name"
              {...rest}
            ></TextField>
          )}
        ></Controller>
      </Grid>
      <Grid item xs={12} sm={12}>
        <FormLabel>Bio</FormLabel>
        <Controller
          control={control}
          name="bio"
          render={({ field: { ref, ...rest } }) => (
            <TextField
              required
              fullWidth
              multiline
              //value={locationValues.address}
              placeholder="Tell us a bit about your company..."
              {...rest}
            ></TextField>
          )}
        ></Controller>
      </Grid>
    </Grid>
  );
}

UserProfileForm.propTypes = {};

export default UserProfileForm;
