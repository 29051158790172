import {
  format,
  formatDuration,
  intervalToDuration,
  isDate,
  isSameDay,
  isSameMonth,
  differenceInDays,
  differenceInWeeks,
  differenceInMinutes,
  differenceInCalendarDays,
  differenceInHours,
  differenceInCalendarYears,
} from "date-fns";

export function getTimestampAsDate(timestamp) {
  try {
    let dateToFmt = new Date();
    if (timestamp?.seconds) {
      dateToFmt = timestamp.toDate();
    } else if (isDate(timestamp)) {
      dateToFmt = timestamp;
    }
    return dateToFmt;
  } catch (error) {
    console.log(error);
  }
  return new Date();
}
export function getStripeTimestampAsDate(timestamp) {
  if (isDate(timestamp)) {
    return timestamp;
  } else {
    return new Date(timestamp * 1000);
  }
}

export function getTimestampAsString(
  timestamp,
  fmt = "EEEE, MMM d",
  timeSplitChar = null
) {
  //console.log(timestamp);
  const dateToFmt = getTimestampAsDate(timestamp);
  let returnVal = "";
  if (timeSplitChar) {
    try {
      return (
        format(dateToFmt, fmt) + timeSplitChar + format(dateToFmt, "h:mm aaaa")
      );
    } catch (error) {
      console.log(error);
    }
  }
  try {
    return format(dateToFmt, fmt);
  } catch (error) {
    console.log(error);
  }
}
export const moneyFormatter = new Intl.NumberFormat("en-CA", {
  style: "currency",
  currency: "CAD",
});
export function getTimestampAsOptimizedString(timestamp) {
  const dateToFmt = getTimestampAsDate(timestamp);
  const diffDays = differenceInCalendarDays(new Date(), dateToFmt);
  const diffWeeks = differenceInWeeks(new Date(), dateToFmt);
  const diffYears = differenceInCalendarYears(new Date(), dateToFmt);
  if (diffYears > 0) {
    return getTimestampAsString(dateToFmt, "MMM dd, yyyy");
  }
  if (diffDays < 1) {
    return getTimestampAsString(dateToFmt, "h:mm aaaa");
  } else if (diffDays == 1) {
    return getTimestampAsString(dateToFmt, "EEE, h:mm a");
  } else if (diffDays < 7) {
    return getTimestampAsString(dateToFmt, "MMM dd, h:mm a");
  } else {
    return getTimestampAsString(dateToFmt, "MMM dd");
  }
}

export function getDifferenceInHours(first, last) {
  return differenceInHours(getTimestampAsDate(last), getTimestampAsDate(first));
}
export function getDifferenceInDays(first, last) {
  return differenceInCalendarDays(
    getTimestampAsDate(last),
    getTimestampAsDate(first)
  );
}
/**
 *
 * @param {*} timestamp
 * @returns string
 */
export function getTimeSinceAsString(timestamp) {
  const dateToFmt = getTimestampAsDate(timestamp);
  const diffDays = differenceInDays(new Date(), dateToFmt);
  const diffWeeks = differenceInWeeks(new Date(), dateToFmt);

  if (diffDays < 1) {
    return getTimestampAsString(dateToFmt, "h:mm a");
  } else if (diffDays == 1) {
    return diffDays + " day ago";
  } else if (diffDays < 7) {
    return diffDays + " days ago";
  } else if (diffWeeks == 1) {
    return diffWeeks + " week ago";
  } else {
    return diffWeeks + " weeks ago";
  }
}

/**
 * get time until timestamp as a string
 * @param {*} timestamp
 * @returns
 */
export function getTimeUntilAsString(timestamp) {
  if (new Date() > getTimestampAsDate(timestamp)) {
    return "00:00";
  }
  const goalDate = getTimestampAsDate(timestamp);
  const diffMins = differenceInMinutes(goalDate, new Date());
  const diffDays = differenceInCalendarDays(goalDate, new Date());
  const diffHours = differenceInHours(goalDate, new Date());
  const diffWeeks = differenceInWeeks(goalDate, new Date());
  if (diffWeeks >= 1) {
    if (diffWeeks == 1) {
      return "1 week";
    } else {
      return diffWeeks + " weeks";
    }
  } else if (diffDays > 1) {
    return diffDays + " days";
  } else if (diffHours >= 24) {
    return "1 day, " + (diffHours - 24) + " hours";
  } else if (diffDays === 1) {
    return diffHours + " hours";
  } else {
    let hrs = Math.floor(diffMins / 60).toString();
    let mins = (diffMins % 60).toString();
    if (hrs < 10) hrs = "0" + hrs;
    if (mins < 10) mins = "0" + mins;
    return hrs + ":" + mins;
  }
}

/**
 * get time until timestamp as duration
 * @param {*} timestamp
 * @returns
 */
export function getMinutesUntil(timestamp) {
  if (new Date() > getTimestampAsDate(timestamp)) {
    return -1;
  }
  const goalDate = getTimestampAsDate(timestamp);
  const diffMins = differenceInMinutes(goalDate, new Date());
  return diffMins;
}

export function getTimeRangeAsString(
  startTime,
  endTime,
  includeHours = true,
  newLineChar = " "
) {
  let startDate = getTimestampAsDate(startTime);
  let endDate = getTimestampAsDate(endTime);
  if (isSameDay(startDate, endDate)) {
    if (includeHours) {
      return (
        getTimestampAsString(startDate, "EEE, MMMM d") +
        newLineChar +
        getTimestampAsString(startDate, "h:mm aaaa") +
        " - " +
        getTimestampAsString(endDate, "h:mm aaaa")
      );
    } else {
      return getTimestampAsString(startDate);
    }
  } else if (isSameMonth(startDate, endDate)) {
    return (
      getTimestampAsString(startDate, "MMM d") +
      " - " +
      getTimestampAsString(endDate, "d")
    );
  } else {
    return (
      getTimestampAsString(startDate, "MMM d") +
      " - " +
      getTimestampAsString(endDate, "MMM d")
    );
  }
}

export function calculateShiftCost(startTime, endTime, payRate) {
  try {
    return differenceInMinutes(endTime, startTime) * (payRate / 60);
  } catch (error) {
    console.log("Error calculating total shift cost", error);
  }
}
