export const colours = {
  blue700: "#134D82",
  blue600: "#2B69A0",
  blue500: "#4F8FC7",
  blue400: "#90BFE8",
  blue300: "#C3E0F9",
  blue200: "#EDF7FF",
  green700: "#17845E",
  green600: "#17A070",
  green500: "#18B472",
  black: "#282C35",
  black700: "#1B3554",
  black600: "#354C68",
  black500: "#4F627B",
  black400: "#828FA2",
  black300: "#B6BCC9",
  black200: "#E9E9F0",
  success700: "#4EAF51",
  success600: "#71BF74",
  success500: "#83C785",
  success400: "#A0D4A1",
  success300: "#B8DFB9",
  success200: "#E6F3E5",
  white700: "#EAEAF1",
  white600: "#ECECF2",
  white500: "#F1F1F5",
  white400: "#F5F6F8",
  white300: "#FAFBFB",
  white200: "#FFF",
  red700: "#F54336",
  red600: "#F6564A",
  red500: "#F87B72",
  red400: "#F87B72",
  red300: "#F87B72",
  red200: "#F87B72",
  orange700: "#F19E47",
  orange200: "#FDF1E3",
  brandingLimeGreen: "#00CA41",
  brandingMidGreen: "#00A764",
  brandingBlueGreen: "#008983",
  brandingBlueish: "#005BB1",
  brandingBlue: "#0041CD",
};
