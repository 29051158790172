import firebase from "firebase/compat/app";
import { getAuth } from "firebase/auth";
import {
  Firestore,
  getFirestore,
  initializeFirestore,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDI5-8JbdOnXEsnTGYmJhcpfJqzax0c-QQ",
  authDomain: "nomad-shifts.firebaseapp.com",
  projectId: "nomad-shifts",
  storageBucket: "nomad-shifts.appspot.com",
  messagingSenderId: "428252097843",
  appId: "1:428252097843:web:0e3197edab9b3659af2246",
  measurementId: "G-72FNKN3TDG",
};

const app = firebase.initializeApp(firebaseConfig);
initializeFirestore(app, { ignoreUndefinedProperties: true, experimentalForceLongPolling: true });
export default app;
export const auth = getAuth(app);
export const db = getFirestore(app);
