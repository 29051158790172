import { Button } from "@mui/material";
import logo from "../logo.png";
import "../Styles/App.css";
import { signOut } from "firebase/auth";
import { auth } from "../config/firebase";

function ComingSoon() {
  return (
    <div className="ComingSoon">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Nomad Shifts - Web Version Coming soon</p>
        <p>
          Thank you for your interest in using Nomad Shifts in your browser.
          Currently only Employers have access to the web version. Stay tuned
          for the upcoming Nomad Browser Version.
        </p>
      </header>
      <Button
        onClick={() => {
          signOut(auth);
        }}
      >
        Log Out
      </Button>
    </div>
  );
}

export default ComingSoon;
