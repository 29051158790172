import React, { useContext, useEffect } from "react";
import googlePlayLogo from "../../assets/logos/google-play-badge.png";
import { NavigatorContext } from "../../Context/NavigatorContext";
function EmployerDashboard() {
  const { setPathTitle } = useContext(NavigatorContext);
  useEffect(() => {
    setPathTitle("Dashboard");
  }, []);
  return (
    <div>
      <center>
        <h2>Welcome to Nomad Shifts on the web!</h2>
        <h4>
          Nomad Shifts is a on demand staffing platform which facilitates the
          connection between workers (Nomads) and employers.
        </h4>
        <h5>
          Currently web functionality is still in early access with new features
          being added and updated regularly!
        </h5>
        <h6>
          If you run into any bugs/glitches please reach out to us:{" "}
          <a href="https://www.NomadShifts.com/contact">
            www.nomadshifts.com/contact
          </a>
        </h6>
      </center>
      <div style={{ paddingLeft: "40%" }}>
        <h6>Current web functionality:</h6>
        <li>Posting Positions and Shifts</li>
        <li>Viewing Nomad Applicants</li>
        <li>Hiring Nomad's</li>
      </div>

      <center>
        <h6>
          For more advanced functionality check out our mobile App (Nomad
          Shifts) on both iOS and Android
        </h6>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <a href="https://apps.apple.com/ca/app/nomad-shifts/id6444500954">
            <img
              style={{ height: 50 }}
              src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
            />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.dunhamdigital.nomadshifts">
            <img style={{ height: 50 }} src={googlePlayLogo} />
          </a>
        </div>
      </center>
      <p>Google Play and the Google Play logo are trademarks of Google LLC.</p>
      <p>
        Apple Logo and Apple-owned Graphic Symbols are trademarks of Apple Inc.,
        registered in the U.S. and other countries.
      </p>
    </div>
  );
}

export default EmployerDashboard;
