import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ShiftCardFilled } from "../../Components/Shifts/ShiftCard";
import {
  Box,
  Card,
  Grid,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import { ExpandMore, UnfoldMore, Visibility } from "@mui/icons-material";
import UserDataContext from "../../Context/UserDataContext";
import CardBackground from "../../Components/CardBackground";
import { getTimestampAsDate } from "../../Functions/utilFunctions";
import { Outlet, useNavigate } from "react-router-dom";
import { NavigatorContext } from "../../Context/NavigatorContext";
import DebugButtons from "../../Components/DebugButtons";

function FilledShifts() {
  const { shifts, handleArchiveShift, notifications } =
    useContext(UserDataContext);
  const { showPathTitle, setPathTitle } = useContext(NavigatorContext);
  const navigate = useNavigate();
  const [nomadData, setNomadData] = useState();
  let countShifts = 0;
  useEffect(() => {
    setPathTitle("Filled Shifts");
  }, []);
  return (
    <>
      <Outlet context={[nomadData]} />
      <CardBackground fullSize>
        <Stack
          spacing={"10px"}
          sx={{
            //padding: "25px",
            display: "flexGrow",
            flexDirection: "column",
          }}
        >
          {/* <Grid
        columnSpacing={3}
        container
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
          Title
          <UnfoldMore />
        </Grid>
        <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
          Shift Type
          <UnfoldMore />
        </Grid>
        <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
          Nomad
          <UnfoldMore />
        </Grid>
        <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
          Status
          <UnfoldMore />
        </Grid>
        <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}></Grid>
      </Grid> */}
          {Object.values(shifts).map((shift) => {
            if (
              shift.worker &&
              getTimestampAsDate(shift.endTime) > new Date()
            ) {
              countShifts += 1;
              return (
                <ShiftCardFilled
                  shiftType="paid"
                  key={shift.id}
                  onClickNomad={() => {
                    navigate("" + shift.worker.uid);
                  }}
                  nomadDisplayName={shift.worker.displayName}
                  nomadPhotoURL={shift.worker.photoURL}
                  nomadUID={shift.worker.uid}
                  endDate={shift.endTime}
                  checkinCode={shift.checkinCode}
                  startDate={shift.startTime}
                  title={shift.title}
                  id={shift.id}
                  numApplicants={Object.keys(shift.applicants)?.length}
                  menuDisabled
                  onClick={(id) => {
                    //Check if shift ID's invoice has been paid for if id paid navigate otherwise popup saying:
                    //"Invoice for this shift must be paid before you can view/message or hire Nomad's who have applied"
                    //navigate("./" + id);
                  }}
                />
              );
            }
          })}
        </Stack>
        <DebugButtons>{notifications}</DebugButtons>
        {countShifts == 0 && (
          <Typography variant="body2">
            It looks like you don't have any active filled shifts. Head over to
            the 'Positions' page to create a new shift.
          </Typography>
        )}
      </CardBackground>
    </>
  );
}

FilledShifts.propTypes = {};

export default FilledShifts;
