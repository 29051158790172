import React from "react";
import PropTypes from "prop-types";
import { Chip } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DropDownIcon from "@mui/icons-material/KeyboardArrowDown";

const ActivityChip = ({ status = "active", onClick }) => {
  if (status == "active")
    return (
      <Chip
        onClick={onClick}
        color="success"
        label="Active"
        icon={<VisibilityIcon />}
        onDelete={() => {}}
        deleteIcon={<DropDownIcon />}
      ></Chip>
    );
};

ActivityChip.propTypes = {};

export default ActivityChip;
