import '../Styles/App.css'
import React from 'react';
import  { db } from '../config/firebase'    
import { searchData } from '../Functions/firebaseFunctions';
import { useState, useEffect } from 'react';
import { getDocs, collection } from 'firebase/firestore';
export default function RepeatedBox(){   
    const userID = "ujKhtmzy5PO1K3JISunmQxd4V8y1"
    const [userList, setUserList] = useState([]);
    const [locationInfo, setLocationInfo] = useState([]);

    useEffect(() =>{
        const userCollectionRef = collection(db, "locations");
        const getUserList = async () => {
            //READ the data
            const data = await getDocs(userCollectionRef);
            const filteredData = data.docs.map((doc) =>({...doc.data(), id: doc.id}));
            setUserList(filteredData);
            //Find specific location based on UserID as one of the admins
            userLoop:
            for (let i = 0; i < userList.length; i++){
                for (let j = 0; j < userList[i].admins.length; i++){
                    if (userList[i].admins[j] === userID){
                        console.log("We got him BOYS");
                        setLocationInfo(userList[i])
                        break userLoop;
                    } 
                }
            }
            console.log(locationInfo);
        };
        getUserList();
    }, []);

    return(
        <div>
            <button className="LargeGreenButton" style={{fontSize: 50, width: 400, height: 500}}>
                <h3>{locationInfo.name}</h3>
                <p>{locationInfo.address}</p>
                <p>{locationInfo.phoneNumber}</p>
                <img style={{height: 100, width: 100}}src={locationInfo.imageURL}/>
                <p style={{fontSize: 15, left: 0, position: 'relative'}}>Edit Location Info</p>
            </button>
            
        </div>
    );   
}
