import logo from '../logo.png';
import '../Styles/App.css';
import { Routes, Route, Link} from "react-router-dom";
import React from 'react';
import ErrorPage from '../Screens/ErrorPage';
import Logout from '../Screens/Logout';
import ComingSoon from '../Screens/ComingSoon';
import ViewAllAccounts from '../Components/ViewAllAccounts';


export default function AdminRoutes() {
    return (
    <>
        <div className="App">
        <header className="App-header">
                     
            <div className="sidenav">
                <img className="App-logo" src={logo} alt='Nomad Shifts Logo'/>
                <nav>
                    <Link to="/">Dashboard</Link>
                    <Link to="/Locations">Locations</Link>
                    <Link to="/Payments">Payments</Link>
                    <Link to="/AllAccounts">Accounts</Link>
                    <br/><br/><br/><br/><br/>
                    <Link to="/ChangeLog">Change Log</Link>
                    <Link to="/logout">Logout</Link>
                </nav>
            </div>
            {/**
             * All elements must be rendered inside of the main div to show up in the correct section
             */}
            <div className="main">
                <Routes>
                    <Route path='/' element={<ComingSoon/>}/>
                    <Route path='/AllAccounts' element={<ViewAllAccounts/>}/> 
                    <Route path='/Logout' element={<Logout/>}/> 
                    <Route path='*' element={<ErrorPage/>}/>{/*This is the catch all error page, must remain at the bottom of the routes list*/}
                </Routes>
            </div>
        </header>
            
        </div>
        </>
    )
}