import React, { useRef, useState, useContext } from "react";
import "../../Styles/EmployerShifts.css";
import {
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
  Checkbox,
  Typography,
  Card,
  CardContent,
  IconButton,
  Divider,
} from "@mui/material";
import { Add, Clear } from "@mui/icons-material";
import {
  componentDimensions,
  fullWidth,
  fullSize,
} from "../../config/dimensions";
import { addDocument } from "../../Functions/firebaseFunctions";
import UserDataContext from "../../Context/UserDataContext";

const FormDataDisplay = ({ leftLabel, rightLabel, multiline = false }) => {
  return (
    <>
      <Grid item xs={5}>
        <Typography
          fontSize={componentDimensions.textSize.small}
          fontWeight={500}
        >
          {leftLabel}{" "}
        </Typography>
      </Grid>

      <Grid item xs={7}>
        <Typography
          fontSize={componentDimensions.textSize.small}
          fontWeight={400}
          whiteSpace={multiline ? "pre-line" : "normal"}
        >
          {rightLabel}
        </Typography>
      </Grid>
    </>
  );
};

/**
 * @function AddPositionForm
 * @description This component is the form used to create a new position.
 * Should be used in a parent component that controls the current section
 */
export default function AddPositionForm({
  currentSection,
  formData,
  setFormData,
}) {
  const { userID } = useContext(UserDataContext);
  const textRef = useRef();
  const [bulletText, setBulletText] = useState("");
  const [autoth, setautoth] = useState([]);

  const documentOptions = [
    "Resume",
    "Cover Letter",
    "Transcript",
    "Work Samples",
    "Other",
    "None",
  ];
  return (
    <Box
      sx={{
        position: "absolute",
        right: componentDimensions.spacing.medium.pixels,
        left: componentDimensions.spacing.medium.pixels,
        bottom: componentDimensions.spacing.medium.default * 2 + 10,
        top: componentDimensions.spacing.medium.pixels,
      }}
    >
      {currentSection === 1 && (
        <Box component="form" sx={{ display: "flex" }}>
          <Grid container>
            <Grid item xs={12}>
              <Typography fontSize={"20px"}>Basic Information</Typography>
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Position Title*</InputLabel>
              <TextField
                value={formData.title}
                onChange={(event) => {
                  setFormData({
                    ...formData,
                    title: event.target.value,
                  });
                }}
                fullWidth
                variant="outlined"
                id="title"
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Category</InputLabel>
              <TextField
                value={formData.category}
                onChange={(event) => {
                  setFormData({
                    ...formData,
                    category: event.target.value,
                  });
                }}
                fullWidth
                variant="outlined"
                id="category"
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Position Description</InputLabel>
              <TextField
                value={formData.description}
                onChange={(event) => {
                  setFormData({
                    ...formData,
                    description: event.target.value,
                  });
                }}
                minRows={3}
                multiline
                fullWidth
                variant="outlined"
                id="description"
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Position Type*</InputLabel>
              <RadioGroup
                value={formData.type}
                onChange={(e) => {
                  setFormData({ ...formData, type: e.target.value });
                }}
              >
                <FormControlLabel
                  value={"paid"}
                  checked={formData.type === "paid"}
                  control={<Radio />}
                  label="Paid"
                ></FormControlLabel>
                <FormControlLabel
                  disabled
                  value={"volunteer"}
                  checked={formData.type === "volunteer"}
                  control={<Radio />}
                  label="Volunteer (Coming soon)"
                ></FormControlLabel>
              </RadioGroup>
            </Grid>
          </Grid>
        </Box>
      )}
      {currentSection === 2 && (
        <Box component="form">
          <Grid container>
            <Grid item xs={12}>
              <Typography fontSize={"20px"}>Position Requirements</Typography>
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Minimum Requirements</InputLabel>
              <TextField
                ref={textRef}
                value={formData.requirements}
                onFocus={(e) => {
                  if (e.target.value === "")
                    setFormData({
                      ...formData,
                      requirements: formData.requirements + "•\t",
                    });
                }}
                onKeyUp={(event) => {
                  if (event.code === "Enter")
                    setFormData({
                      ...formData,
                      requirements: formData.requirements + "•\t",
                    });
                }}
                onChange={(e) => {
                  setBulletText(e.target.value);
                  setFormData({ ...formData, requirements: e.target.value });
                }}
                minRows={3}
                multiline
                fullWidth
                variant="outlined"
                id="description"
              ></TextField>
            </Grid>
          </Grid>
        </Box>
      )}
      {currentSection === 8 && (
        <Box component="form" sx={{ display: "flex" }}>
          <Grid container>
            <Grid item xs={12}>
              <Typography fontSize={"20px"}>Position Requirements</Typography>
            </Grid>

            <Grid item xs={12}>
              <InputLabel>Minimum Requirements</InputLabel>
              <TextField
                ref={textRef}
                value={formData.requirements}
                onFocus={(e) => {
                  if (e.target.value === "")
                    setFormData({
                      ...formData,
                      requirements: formData.requirements + "•\t",
                    });
                }}
                onKeyUp={(event) => {
                  console.log(event.code === "Enter");
                  if (event.code === "Enter")
                    setFormData({
                      ...formData,
                      requirements: formData.requirements + "•\t",
                    });
                }}
                onChange={(e) => {
                  console.log(e.target.value);
                  setBulletText(e.target.value);
                  setFormData({ ...formData, requirements: e.target.value });
                }}
                minRows={3}
                multiline
                fullWidth
                variant="outlined"
                id="description"
              ></TextField>
            </Grid>
            {/* <Grid item xs={12}>
              <InputLabel>Position Type*</InputLabel>
              <Autocomplete
                multiple
                value={formData.skills}
                options={["Communication", "WHMIS", "Skill"]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    placeholder="Type"
                  ></TextField>
                )}
                getOptionLabel={(option) => {
                  return option;
                }}
                onChange={(e, value) => {
                  setFormData({ ...formData, skills: value });
                }}
              ></Autocomplete>
            </Grid> */}
          </Grid>
        </Box>
      )}
      {currentSection === 3 && (
        <Box component="form" sx={{ display: "flex" }}>
          <Grid container>
            <Grid item xs={12}>
              <Typography fontSize={"20px"}>
                General Application Questions
              </Typography>
            </Grid>

            {false && (
              <Grid item xs={12}>
                <InputLabel>Required Documents</InputLabel>

                <FormGroup>
                  {documentOptions.map((option) => {
                    return (
                      <FormControlLabel
                        //onChange={() => {}}
                        label={option}
                        control={
                          <Checkbox
                            checked={formData.documents[option]}
                            onChange={(e, value) => {
                              let temp = formData.documents;
                              temp[option] = value;
                              setFormData({ ...formData, documents: temp });
                            }}
                          ></Checkbox>
                        }
                      />
                    );
                  })}
                </FormGroup>
              </Grid>
            )}
            <Grid item xs={12}>
              <InputLabel>Application Questions</InputLabel>
              {formData.questions.map(({ prompt, inputType }, index) => {
                return (
                  <Card
                    sx={{
                      backgroundColor: "#F5F6F8",
                      paddingTop: "16px",
                      paddingX: "16px",
                      position: "relative",
                      marginBottom: "16px",
                    }}
                  >
                    <IconButton
                      onClick={() => {
                        setFormData({
                          ...formData,
                          questions: formData.questions.filter((value, i) => {
                            return i !== index;
                          }),
                        });
                      }}
                      sx={{ position: "absolute", right: "20px", top: "10px" }}
                    >
                      <Clear />
                    </IconButton>
                    <CardContent>
                      <Grid item xs={12}>
                        <InputLabel>Question {index + 1}</InputLabel>
                        <TextField
                          style={{
                            backgroundColor: "white",
                            marginBottom: "16px",
                          }}
                          value={formData.questions[index].prompt}
                          onChange={(event) => {
                            let temp = formData.questions;
                            temp[index] = {
                              ...temp[index],
                              prompt: event.target.value,
                            };
                            setFormData({
                              ...formData,
                              questions: temp,
                            });
                          }}
                          fullWidth
                          variant="outlined"
                          id="title"
                        ></TextField>
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel>Input Type</InputLabel>

                        <RadioGroup
                          value={formData.questions[index].inputType}
                          onChange={(event) => {
                            let temp = formData.questions;
                            temp[index] = {
                              ...temp[index],
                              inputType: event.target.value,
                            };
                            setFormData({
                              ...formData,
                              questions: temp,
                            });
                          }}
                        >
                          <FormControlLabel
                            value={"text"}
                            checked={
                              formData.questions[index].inputType === "text"
                            }
                            control={<Radio />}
                            label="Text"
                          ></FormControlLabel>
                          <FormControlLabel
                            value={"yesno"}
                            checked={
                              formData.questions[index].inputType === "yesno"
                            }
                            control={<Radio />}
                            label="Yes/No"
                          ></FormControlLabel>
                          <FormControlLabel
                            value={"number"}
                            checked={
                              formData.questions[index].inputType === "number"
                            }
                            control={<Radio />}
                            label="Number"
                          ></FormControlLabel>
                        </RadioGroup>
                      </Grid>
                    </CardContent>
                  </Card>
                );
              })}
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                startIcon={<Add />}
                sx={{ marginX: "auto" }}
                onClick={() => {
                  setFormData({
                    ...formData,
                    questions: [
                      ...formData.questions,
                      { prompt: "", inputType: "text" },
                    ],
                  });
                }}
              >
                Add Question
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
      {currentSection === 4 && (
        <Box component="form" sx={{ display: "flex" }}>
          <Grid container>
            <Grid item xs={12}>
              <Typography fontSize={"20px"}>Confirm Details</Typography>
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Basic Information</InputLabel>
            </Grid>
            <FormDataDisplay
              leftLabel={"Position Title"}
              rightLabel={formData.title}
            />
            <FormDataDisplay
              leftLabel={"Category"}
              rightLabel={formData.category}
            />
            <FormDataDisplay
              leftLabel={"Description"}
              rightLabel={formData.description}
            />
            <FormDataDisplay
              leftLabel={"Position Requirements"}
              rightLabel={formData.requirements}
              multiline
            />
            <FormDataDisplay
              leftLabel={"Position Type"}
              rightLabel={formData.type}
            />
          </Grid>
        </Box>
      )}
    </Box>
  );
}
