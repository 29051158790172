import * as React from "react";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import EmployerDashboard from "../Screens/Employer/EmployerDashboard";
import Shifts from "../Screens/Employer/Shifts";
import Payments from "../Screens/Employer/Payments";
import Applicants from "../Screens/Employer/Applicants";
import Messages from "../Screens/Employer/Messages";
import Logout from "../Screens/Logout";
import {
  AppBar,
  Avatar,
  Badge,
  Button,
  ButtonBase,
  Card,
  Container,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { ReactComponent as SmallLogo } from "../assets/logos/logo_small.svg";
import { ReactComponent as AppBarLogo } from "../assets/logos/appbar_logo.svg";
import { ReactComponent as DashboardTab } from "../assets/navigation/dashboard.svg";
import { ReactComponent as DashboardTabIcon } from "../assets/navigation/dashboard-icon.svg";
import { ReactComponent as NomadTab } from "../assets/navigation/nomad.svg";
import { ReactComponent as NomadTabIcon } from "../assets/navigation/nomad-icon.svg";
import { ReactComponent as PaymentTab } from "../assets/navigation/payment.svg";
import { ReactComponent as PaymentTabIcon } from "../assets/navigation/payment-icon.svg";
import { ReactComponent as ShiftTab } from "../assets/navigation/shift.svg";
import { ReactComponent as ShiftTabIcon } from "../assets/navigation/shift-icon.svg";
import { ReactComponent as LogoutTab } from "../assets/navigation/log-out-icon.svg";
import { ReactComponent as LogoutTabDarkText } from "../assets/navigation/logout-darkText.svg";
import { ReactComponent as MessagesTabWhiteText } from "../assets/navigation/messages-whiteText.svg";
import { ReactComponent as MessagesTabDarkText } from "../assets/navigation/messages-darkText.svg";
import "../Styles/App.css";
import { colours } from "../config/colours";
import { useLocation, useNavigate } from "react-router-dom";
import { ChevronLeft, ExitToApp, Notifications } from "@mui/icons-material";
import { componentDimensions } from "../config/dimensions";
import { useMemo } from "react";
import { useState } from "react";
import { useContext } from "react";
import { NavigatorContext } from "../Context/NavigatorContext";
import DebugButtons from "./DebugButtons";
import UserDataContext from "../Context/UserDataContext";

const tabs = [
  {
    label: "Dashboard",
    route: "/EmployerDashboard",
    element: <EmployerDashboard />,
    IconComponent: (props) => {
      return <DashboardTabIcon {...props} />;
    },

    TabComponent: (props) => {
      return <DashboardTab {...props} />;
    },
    active: true,
  },
  {
    label: "Shifts",
    route: "/Shifts",
    togglePanel: true,
    IconComponent: (props) => <ShiftTabIcon {...props} />,

    TabComponent: (props) => {
      return <ShiftTab {...props} />;
    },
    element: <Shifts />,
  } /*
  {
    label: "Schedule",
    route: "/Schedule",
    IconComponent: (props) => <ScheduleTabIcon {...props} />,

    TabComponent: (props) => {
      return <ScheduleTab {...props} />;
    },
    element: <Locations />,
  },*/,
  {
    label: "Payment",
    route: "/Payments",
    togglePanel: true,
    IconComponent: (props) => <PaymentTabIcon {...props} />,

    TabComponent: (props) => {
      return <PaymentTab {...props} />;
    },
    element: <Payments />,
  },
  /* {
    label: "Nomads",
    route: "/Nomads",
    IconComponent: (props) => {
      return <NomadTabIcon {...props} />;
    },

    TabComponent: (props) => {
      return <NomadTab {...props} />;
    },
    element: <Applicants />,
  }, */
  {
    label: "Messages",
    route: "/messages",
    IconComponent: (props) => {
      return <MessagesTabWhiteText {...props} />;
    },

    TabComponent: (props) => {
      return <MessagesTabDarkText {...props} />;
    },
    element: <Messages />,
  },
  {
    label: "Logout",
    route: "/Logout",
    IconComponent: (props) => <LogoutTab {...props} />,

    TabComponent: (props) => {
      return <LogoutTabDarkText {...props} />;
    },
    element: <Logout />,
  },
];
const subroutes = {
  Shifts: [
    { label: "Positions", path: "/Shifts" },
    { label: "Unfilled Shifts", path: "/Shifts/unfilled" },
    { label: "Filled Shifts", path: "/Shifts/filled" },
    { label: "Completed Shifts", path: "/Shifts/completed" },
  ],
  Payments: [
    { label: "Manage Payments", path: "/Payments" },
    { label: "Upcoming Payments", path: "/Payments/upcoming", disabled: true },
    { label: "Billing History", path: "/Payments/history", disabled: true },
  ],
};
//const drawerWidth = "104px";
//const appbarHeight = "67px";
export const NavContext = React.createContext();

function Main(props) {
  const { pathTitle, showPathTitle } = useContext(NavigatorContext);
  return (
    <Box
      sx={{
        marginLeft: componentDimensions.drawerWidth.pixels,
        marginTop: componentDimensions.appbarHeight.pixels,
        display: "flex",
        flexDirection: "row",
      }}
    >
      {/**Secondary Drawer */}
      {props.showSecondarySideNav && (
        <Card
          elevation={5}
          sx={{
            position: "fixed",
            width: componentDimensions.secondaryDrawerWidth.pixels,
            backgroundColor: "white",
            height: window.innerHeight,
            zIndex: 500,
          }}
        >
          <List disablePadding>
            {subroutes[props.pathLabel.main]?.map((subroute, index) => {
              return (
                <ListItem disableGutters key={index}>
                  <ListItemButton
                    disabled={subroute.disabled}
                    selected={props.routeName == subroute.path.split("/")[2]}
                    onClick={() => {
                      console.log(props.rootPath);
                      props.onClickRoute(subroute.path);
                    }}
                  >
                    <ListItemText primaryTypographyProps={{ fontSize: "16px" }}>
                      {subroute.label}
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
          <DebugButtons>{props.pathLabel}</DebugButtons>
        </Card>
      )}
      {/**Render Screen */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginLeft: props.showSecondarySideNav
            ? componentDimensions.secondaryDrawerWidth.pixels
            : 0,
        }}
      >
        {true /* showPathTitle */ && (
          <Box
            sx={{
              //backgroundColor: "white",
              minWidth:
                window.innerWidth -
                componentDimensions.secondaryDrawerWidth.default -
                componentDimensions.drawerWidth.default,

              margin: 0,
              display: "flex",
              height: "88px",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
              paddingLeft: "87px",
              backgroundColor: colours.white200,
              paddingY: componentDimensions.spacing.small.pixels,
            }}
          >
            {<Typography fontSize={"24px"}>{pathTitle}</Typography>}
            {props.showBackButton && (
              <Button startIcon={<ChevronLeft />}>Back</Button>
            )}
          </Box>
        )}
        <Box sx={{ margin: "24px" }}>{props.children}</Box>
      </Box>
    </Box>
  );
}

/*const routeLabels = {
  completed: { label: "Completed" },
  addposition: { label: "Add Shifts" },
  unfilled: { label: "Unfilled Shifts", showBackButton: true },
  filled: { label: "Filled Shifts" },
};*/
const getRouteLabel = (path) => {
  switch (path) {
    case "completed":
      return { label: "Completed" };
    case "addposition":
      return { label: "Add Shifts" };
    case "unfilled":
      return { label: "Unfilled Shifts", showBackButton: true };
    case "filled":
      return { label: "Filled Shifts" };
    default:
      return { label: "Shifts" };
    //break;
  }
};
export default function Navigator(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { newNotifications, viewedNotifications, messages, userData } =
    useContext(UserDataContext);
  const { secondaryDrawerOpen, setSecondaryDrawerOpen } =
    useContext(NavigatorContext);
  const pathLabel = useMemo(() => {
    try {
      if (location.pathname === "/Shifts") {
        return { main: "Shifts", secondary: "Positions" };
      }
      if (location.pathname === "/Payments") {
        return { main: "Payments", secondary: "Manage" };
      }
      let pathArr = location.pathname.split("/", 3);
      let tempPath = pathArr[2];
      let mainPath = pathArr[1];
      mainPath = pathArr[1][0].toUpperCase() + mainPath.slice(1);
      console.log({
        main: pathArr[1],
        secondary: getRouteLabel(tempPath)?.label,
      });
      return {
        main: mainPath,
        secondary: getRouteLabel(tempPath)?.label,
        route: tempPath,
      };
    } catch (error) {
      console.log(error);
      return " ";
    }
  }, [location.pathname]);
  const toggleSidePanel = (route, togglePanel) => {
    if (route.toLowerCase() === "/" + pathLabel.main?.toLowerCase()) {
      togglePanel && setSecondaryDrawerOpen(!secondaryDrawerOpen);
      console.log(secondaryDrawerOpen);
    } else {
      navigate(route);
      togglePanel && setSecondaryDrawerOpen(true);
      !togglePanel && setSecondaryDrawerOpen(false);
    }
    console.log("/" + pathLabel?.main?.toLowerCase());
  };
  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <AppBar
        style={{ backgroundColor: colours.black700 }}
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar end>
          <SmallLogo />
          <div style={{ width: 20 }}></div>
          <AppBarLogo />
          <Typography component={"div"} sx={{ flexGrow: 1 }}></Typography>
          <IconButton
            color="secondary"
            onClick={() => {
              navigate("/Notifications");
            }}
          >
            <Badge badgeContent={newNotifications?.length}>
              <Notifications />
            </Badge>
          </IconButton>
          <ButtonBase
            disableRipple
            sx={{ marginLeft: componentDimensions.spacing.small.pixels }}
            onClick={() => {
              navigate("./MyAccount");
            }}
          >
            <Avatar src={userData.photoURL}></Avatar>
          </ButtonBase>
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" sx={{ flexShrink: 0 }}>
        <Toolbar />

        {tabs.map(
          (
            {
              route,
              label,
              togglePanel,
              TabComponent,
              active = false,
              MyIcon = (props) => <ShiftTabIcon {...props} />,
              IconComponent,
            },
            index
          ) => (
            <>
              <Box
                key={index}
                sx={{
                  alignSelf: "flex-end",
                  right: 0,
                  padding: 0,
                  width: componentDimensions.drawerWidth.pixels,
                  marginY: "10px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <ButtonBase
                  onClick={() => {
                    console.log(route);
                    console.log(pathLabel);
                    toggleSidePanel(route, togglePanel);
                  }}
                  sx={{
                    borderTopLeftRadius: 10,
                    borderBottomLeftRadius: 10,
                    ":hover": { bgcolor: "red" },
                  }}
                >
                  <Box
                    sx={{
                      width: 94,
                      borderTopLeftRadius: 10,
                      borderBottomLeftRadius: 10,
                      height: 57,
                      backgroundColor:
                        "/" + pathLabel?.main?.toLowerCase() ===
                        route.toLowerCase()
                          ? "white"
                          : colours.black600,
                    }}
                  >
                    <IconComponent
                      style={{
                        //position: "absolute",
                        color:
                          "/" + pathLabel?.main?.toLowerCase() ===
                          route.toLowerCase()
                            ? colours.black600
                            : "white",
                      }}
                    />
                    {label === "Messages" && (
                      <Badge
                        badgeContent={null}
                        sx={{ position: "absolute", right: 38, top: 10 }}
                      />
                    )}
                  </Box>
                </ButtonBase>{" "}
              </Box>
            </>
          )
        )}
        <Divider sx={{ mt: 2 }} />
      </Drawer>
      {/* <div className="Secondary-side-nav">
        <h4 style={{ paddingTop: 70 }}>Shifts</h4>
        <h6 style={{ backgroundColor: "#EDF7FF", padding: 15 }}>Add Shifts</h6>
        <h6>Unfilled Shifts</h6>
        <h6>Filled Shifts</h6>
        <h6>Completed Shifts</h6>
      </div> */}

      <Main
        showBackButton={false}
        pathLabel={pathLabel}
        routeName={pathLabel.route}
        showSecondarySideNav={
          secondaryDrawerOpen && location.pathname.match("Shifts.*|Payments.*")
        }
        onClickRoute={(path) => {
          navigate(path);
        }}
      >
        {props.children}
      </Main>
    </Box>
  );
}
