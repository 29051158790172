import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Box, Card, Typography } from "@mui/material";
import { colours } from "../config/colours";
import { componentDimensions } from "../config/dimensions";
import { NavigatorContext } from "../Context/NavigatorContext";
import DebugButtons from "./DebugButtons";

function CardBackground({
  children,
  colouredTop = false,
  fullSize = false,
  showTitleBar,
  sx,
}) {
  const { showPathTitle, secondaryDrawerOpen } = useContext(NavigatorContext);
  return (
    <Box
      component={Card}
      sx={{
        borderTop: 1,
        borderTopColor: colours.blue700,
        borderWidth: colouredTop ? 8 : 0,
        padding: componentDimensions.spacing.medium.pixels,

        minHeight: fullSize
          ? window.innerHeight -
            componentDimensions.appbarHeight.default -
            componentDimensions.spacing.medium.default * 2 -
            (showPathTitle ? componentDimensions.titleBarHeight.default : 0)
          : null,
        minWidth: fullSize
          ? window.innerWidth -
            componentDimensions.drawerWidth.default -
            (secondaryDrawerOpen
              ? componentDimensions.secondaryDrawerWidth.default
              : 0) -
            componentDimensions.spacing.medium.default * 2
          : null,

        ...sx,
      }}
    >
      {children}
    </Box>
  );
}

CardBackground.propTypes = {};

export default CardBackground;
