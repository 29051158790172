import React from "react";
import PropTypes from "prop-types";
import { Chip } from "@mui/material";
import ClockIcon from "@mui/icons-material/AccessTime";

const CountdownChip = ({ hoursLeft = 6, label }) => {
  if (hoursLeft > 3) {
    return <Chip color="default" label={label} icon={<ClockIcon />}></Chip>;
  }
};

CountdownChip.propTypes = {};

export default CountdownChip;
