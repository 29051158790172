import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Button, Stack, Typography } from "@mui/material";
import { ShiftCardUnfilled } from "../../Components/Shifts/ShiftCard";
import { useNavigate } from "react-router-dom";
import CardBackground from "../../Components/CardBackground";
import { componentDimensions } from "../../config/dimensions";
import UserDataContext from "../../Context/UserDataContext";
import { getShifts } from "../../Functions/firebaseFunctions";
import DebugButtons from "../../Components/DebugButtons";
import { createNewChat } from "./NomadApplicationAccount";
import { getTimestampAsDate } from "../../Functions/utilFunctions";
import { checkInvoicePaid } from "../../Functions/stripeFunctions";
import { AlertContext } from "../../App";
import { NavigatorContext } from "../../Context/NavigatorContext";
const sampleShifts = [
  {
    id: 0,
    title: "Barista",
    startDate: new Date(),
    endDate: new Date(),
    subtitle: "Mon Wed Fri 9AM - 1PM",
    shiftType: "onetime",
    nomadName: "John Snow",
    cost: "135.06",
  },
  {
    id: 1,
    title: "Cashier",
    startDate: new Date(),
    endDate: new Date(),
    subtitle: "Mon Wed Fri 9AM - 1PM",
    shiftType: "onetime",
    nomadName: "John Snow",
    cost: "135.06",
  },
  {
    id: 2,
    title: "Server",
    startDate: new Date(),
    endDate: new Date(),
    subtitle: "Mon Wed Fri 9AM - 1PM",
    shiftType: "onetime",
    nomadName: "John Snow",
    cost: "135.06",
  },
  {
    id: 3,
    title: "Maintenance",
    startDate: new Date(),
    endDate: new Date(),
    subtitle: "Mon Wed Fri 9AM - 1PM",
    shiftType: "onetime",
    nomadName: "John Snow",
    cost: "135.06",
  },
];

function UnfilledShifts() {
  const { shifts, userID, handleArchiveShift, userData } =
    useContext(UserDataContext);
  const { toggleDialog } = useContext(AlertContext);
  const { showPathTitle, setPathTitle } = useContext(NavigatorContext);
  useEffect(() => {
    setPathTitle("Unfilled Shifts");
  }, []);
  const navigate = useNavigate();
  const shiftVals = Object.values(shifts);
  let countShifts = 0;
  return (
    <CardBackground fullSize>
      <DebugButtons
        onClick={() => {
          createNewChat(userID, "tk4yLkqJFigAe0EerE5YKzrdlgs2");
        }}
      >
        {shifts}
      </DebugButtons>

      <Stack spacing={componentDimensions.spacing.small.pixels}>
        {Object.values(shifts).map((shift) => {
          if (
            shift.available &&
            getTimestampAsDate(shift.endTime) > new Date()
          ) {
            countShifts += 1;
            return (
              <ShiftCardUnfilled
                key={shift.id}
                endDate={shift.endTime}
                startDate={shift.startTime}
                title={shift.title}
                id={shift.id}
                onDelete={() => handleArchiveShift(shift.id, shift.title)}
                numApplicants={Object.keys(shift.applicants)?.length}
                onClick={() => {
                  //Check if shift ID's invoice has been paid for if id paid navigate otherwise popup saying:
                  //"Invoice for this shift must be paid before you can view/message or hire Nomad's who have applied"
                  checkInvoicePaid(shift.invoiceID).then((isPaid) => {
                    if (isPaid || userData.devMode) {
                      navigate("./" + shift.id);
                    } else {
                      toggleDialog(
                        "Incomplete Payment",
                        "You need to fully pay the invoice for this shift before you can hire a Nomad."
                      );
                    }
                  });
                }}
              />
            );
          }
        })}
      </Stack>
      {countShifts == 0 && (
        <Typography variant="body2">
          It looks like you don't have any active shifts. Head over to the
          'Positions' page to create one!
        </Typography>
      )}
    </CardBackground>
  );
}

UnfilledShifts.propTypes = {};
export default UnfilledShifts;
