import React from "react";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Chip,
  Grid,
  Popover,
  Popper,
  Stack,
  Typography,
} from "@mui/material";
import {
  Favorite,
  FavoriteBorder,
  FavoriteOutlined,
  HeartBroken,
  Shield,
  ShieldMoon,
  Star,
  Work,
} from "@mui/icons-material";
import { colours } from "../config/colours";
import { componentDimensions } from "../config/dimensions";
import DebugButtons from "./DebugButtons";

const NomadDisplay = ({
  onClick,
  displayName,
  rating,
  numShifts,
  photoURL,
}) => {
  return (
    <Box
      component={ButtonBase}
      disableRipple
      onClick={onClick}
      sx={{ display: "flex", alignItems: "center" }}
    >
      <Avatar style={{ marginRight: "10px" }} src={photoURL}></Avatar>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box xs={12} sx={{ marginBottom: "4px" }}>
          <Typography
            noWrap
            sx={{ display: "flex", alignItems: "center" }}
            fontWeight={500}
            fontSize={componentDimensions.textSize.small}
          >
            {displayName}{" "}
            {/* <Shield
              style={{
                width: componentDimensions.textSize.small,
                color: colours.blue600,
              }}
            />
            <Shield
              style={{
                width: componentDimensions.textSize.small,
                color: colours.blue600,
              }}
            /> */}
          </Typography>
        </Box>
        {/*  <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box
            border={1}
            flex={1}
            sx={{
              borderTopLeftRadius: "4px",
              borderBottomLeftRadius: "4px",
              borderColor: colours.black200,
            }}
          >
            <Typography
              noWrap
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              fontSize={"12px"}
            >
              {rating}
              <Star style={{ width: "15px", color: colours.black400 }} />
            </Typography>
          </Box>
          <Box
            item
            border={1}
            flex={2}
            sx={{
              borderColor: colours.black200,
              borderTopRightRadius: "4px",
              borderBottomRightRadius: "4px",
              borderLeft: 0,
            }}
          >
            <Typography
              sx={{
                justifyContent: "center",

                display: "flex",
                alignItems: "center",
              }}
              fontSize={"12px"}
              noWrap
            >
              {numShifts + " Shifts "}
              <Work style={{ width: "15px", color: colours.black400 }} />
            </Typography>
          </Box>
        </Box> */}
      </Box>
    </Box>
  );
};
const NomadApplicationCard = ({
  uid,
  numMatchingSkills = 5,
  rating,
  worker,
  displayName,
  isNew,
  onClickNomad,
  photoURL,
  onHireNomad,
}) => {
  return (
    <Stack
      direction={"row"}
      spacing={3}
      sx={{
        minHeight: "88px",
        //borderRadius: "8px",
        //border: 1,
        //borderColor: colours.black300,
        //display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingX: 2,
      }}
    >
      <Grid xs={4}>
        <DebugButtons
          onClick={() => {
            console.log(worker);
          }}
        >
          Log worker
        </DebugButtons>
        <NomadDisplay
          onClick={onClickNomad}
          displayName={displayName}
          rating={rating}
          numShifts={500}
          photoURL={photoURL}
        />
      </Grid>
      {/* <Grid item sx={{ display: { xs: "none", md: "flex" } }}>
        <Chip
          color={numMatchingSkills > 3 ? "secondary" : "default"}
          label={numMatchingSkills + " Matching Skills"}
        ></Chip>
      </Grid> */}
      <Grid item>
        {isNew ? (
          <Chip label="New" color="success"></Chip>
        ) : (
          <Chip label="Viewed" />
        )}
      </Grid>
      <Grid item>
        <Button disabled startIcon={<FavoriteBorder />}>
          Shortlist
        </Button>
      </Grid>
      <Stack
        direction="row"
        spacing={1}
        sx={{ display: { xs: "none", md: "flex" } }}
      >
        <Stack direction="row" spacing={"4px"}></Stack>
        <Button
          disabled={worker != null}
          variant="contained"
          color="primary"
          onClick={() => {
            onHireNomad(uid);
          }}
        >
          Hire
        </Button>
        <Button disabled variant="contained" color="inverted">
          Interview
        </Button>
        <Button disabled variant="contained" color="error">
          Reject
        </Button>
      </Stack>
    </Stack>
  );
};

NomadApplicationCard.propTypes = {};

export default NomadApplicationCard;
