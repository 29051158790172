import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import UserDataContext from "../Context/UserDataContext";
import FormSection from "./FormSection";
import { colours } from "../config/colours";
import LocationForm from "./LocationForm";
import { defaultLocation, defaultUser } from "../data/defaultObejcts";
import { useForm } from "react-hook-form";
import {
  geocodeAddress,
  signOut,
  updateFirestoreDoc,
} from "../Functions/firebaseFunctions";
import Spacer from "./Spacer";
import DebugButtons from "./DebugButtons";
import { geohashForLocation } from "geofire-common";
import UserProfileForm from "./UserProfileForm";
import { componentDimensions } from "../config/dimensions";

function VerificationBlockerDialog() {
  const { userData, setUserData, addUserLocation, locations, userID } =
    useContext(UserDataContext);

  return (
    <Dialog open={!userData.verified}>
      <Box sx={{ paddingX: componentDimensions.spacing.small.pixels }}>
        <DialogTitle variant="h4">
          Thank you for your interest in using NomadShifts!
        </DialogTitle>
        <DialogContent>
          <Typography variant="body3">
            Your account is currently awaiting approval from our team. You can
            begin creating shifts once your account has been verified.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              signOut();
            }}
          >
            Sign Out
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

VerificationBlockerDialog.propTypes = {};

export default VerificationBlockerDialog;
