import React, { useEffect, useRef, useState } from "react";
import "../../Styles/EmployerShifts.css";
import {
  Autocomplete,
  Box,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  Radio,
  TextField,
  Checkbox,
  Typography,
  Button,
  Divider,
} from "@mui/material";
import { componentDimensions } from "../../config/dimensions";
import { addDocument } from "../../Functions/firebaseFunctions";
import FormSection from "../../Components/FormSection";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { NumericFormat } from "react-number-format";
import {
  calculateShiftCost,
  getTimestampAsString,
  moneyFormatter,
} from "../../Functions/utilFunctions";
import Spacer from "../../Components/Spacer";
import { differenceInMinutes, isDate } from "date-fns";
import DebugButtons from "../../Components/DebugButtons";
import FormDataDisplay from "../../Components/DataDisplay";

const typeOptions = {
  onsite: "On Site",
  hybrid: "Hybrid",
  remote: "Remote",
  mobile: "Mobile",
};
const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      fixedDecimalScale={2}
      decimalScale={2}
      thousandSeparator
      valueIsNumericString
      allowNegative={false}
      prefix="$"
    />
  );
});
const sampleLocations = [
  { storenum: "#04996", address: "123 Place Road" },
  { storenum: "#00001", address: "3355 College Street Lennoxville Quebec" },
];
export default function AddNewShift({
  positionData,
  formData,
  setFormData,
  currentSection = 1,
  locations,
  serviceFees = 0.2,
  onSubmitSection,
}) {
  //This is for uploading to the listings collection (old style) before we fully switch to using only positions/shifts collection
  useEffect(() => {
    boxRef.current.scrollTo(0, 0);
  }, [currentSection]);

  const documentOptions = [
    "Resume",
    "Cover Letter",
    "Transcript",
    "Work Samples",
    "Other",
    "None",
  ];
  const boxRef = useRef();

  return (
    <Box
      ref={boxRef}
      sx={{
        position: "absolute",
        right: componentDimensions.spacing.medium.pixels,
        left: componentDimensions.spacing.medium.pixels,
        bottom: componentDimensions.spacing.medium.default * 2 + 10,
        top: componentDimensions.spacing.medium.pixels,
        //overflow: "auto",
        scrollbarWidth: "none", // Hide the scrollbar for firefox
        "&::-webkit-scrollbar": {
          display: "none", // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
        },
        "&-ms-overflow-style:": {
          display: "none", // Hide the scrollbar for IE
        },
      }}
      overflow={"scroll"}
    >
      {currentSection === 1 && (
        <Box component="form" sx={{ display: "flex" }}>
          <Grid container>
            <Grid item xs={12}>
              <Typography fontSize={"20px"}>Position Details</Typography>
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Basic Information</InputLabel>
            </Grid>
            <FormDataDisplay
              leftLabel={"Position Title"}
              rightLabel={formData.title}
            />
            <FormDataDisplay
              leftLabel={"Category"}
              rightLabel={formData.category}
            />
            <FormDataDisplay
              leftLabel={"Description"}
              rightLabel={formData.description}
            />
            <FormDataDisplay
              leftLabel={"Position Type"}
              rightLabel={formData.type}
            />
          </Grid>
        </Box>
      )}
      <FormSection
        currentSection={currentSection}
        section={10}
        title="Shift Template"
      >
        <Grid item>
          <FormGroup>
            <FormControlLabel
              //onChange={() => {}}
              label="Start from scratch"
              control={
                <Checkbox checked={true} onChange={(e, value) => {}}></Checkbox>
              }
            />{" "}
            <FormControlLabel
              disabled
              label="Use previous shift as template"
              control={
                <Checkbox
                  checked={false}
                  onChange={(e, value) => {}}
                ></Checkbox>
              }
            />
          </FormGroup>
        </Grid>
      </FormSection>
      <FormSection
        currentSection={currentSection}
        section={2}
        title={"Location"}
      >
        <Grid item>
          <InputLabel>Location Type*</InputLabel>
          <FormGroup>
            <FormControlLabel
              label="On site"
              control={
                <Radio
                  checked={formData.locationType === "onsite"}
                  onChange={(e, value) => {
                    setFormData({ ...formData, locationType: "onsite" });
                  }}
                ></Radio>
              }
            />
            <FormControlLabel
              label="Hybrid"
              control={
                <Radio
                  checked={formData.locationType === "hybrid"}
                  onChange={(e, value) => {
                    setFormData({ ...formData, locationType: "hybrid" });
                  }}
                ></Radio>
              }
            />
            <FormControlLabel
              label="Remote"
              control={
                <Radio
                  checked={formData.locationType === "remote"}
                  onChange={(e, value) => {
                    setFormData({ ...formData, locationType: "remote" });
                  }}
                ></Radio>
              }
            />
            <FormControlLabel
              label="Mobile"
              control={
                <Radio
                  checked={formData.locationType === "mobile"}
                  onChange={(e, value) => {
                    setFormData({ ...formData, locationType: "mobile" });
                  }}
                ></Radio>
              }
            />
          </FormGroup>
        </Grid>

        <Grid item xs={12}>
          <InputLabel>Shift Address * </InputLabel>
          <Autocomplete
            required={true}
            value={formData.locationData ? formData.locationData : null}
            options={locations}
            /* renderOption={(option) => {
              return option.address;
            }} */
            isOptionEqualToValue={(option, value) => {
              return option.address === value.address;
            }}
            getOptionLabel={(option) => {
              if (option.name !== "") {
                return option.name + " - " + option.address;
              } else {
                return "Choose a location";
              }
            }}
            onChange={(e, val) => {
              console.log(val);
              setFormData({ ...formData, locationData: val });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                placeholder="Select..."
              ></TextField>
            )}
          ></Autocomplete>

          {/* <Autocomplete
            value={formData.address ? formData.address : null}
            options={sampleLocations}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                placeholder="Address"
              ></TextField>
            )}
            getOptionLabel={(option) => {
              return option.address;
            }}
            onChange={(e, value) => {
              setFormData({ ...formData, address: value });
            }}
          ></Autocomplete> */}
        </Grid>
      </FormSection>
      <FormSection
        currentSection={currentSection}
        section={3}
        title={"Shift Details"}
      >
        <Grid item xs={4}>
          <InputLabel>Shift Date*</InputLabel>
          <DatePicker
            sx={{ width: "100%" }}
            value={formData.date}
            onChange={(value) => {
              try {
                const startTime = new Date(
                  value.getFullYear(),
                  value.getMonth(),
                  value.getDate(),
                  formData.startTime.getHours(),
                  formData.startTime.getMinutes()
                );
                const endTime = new Date(
                  value.getFullYear(),
                  value.getMonth(),
                  value.getDate(),
                  formData.endTime.getHours(),
                  formData.endTime.getMinutes()
                );
                setFormData({
                  ...formData,
                  date: value,
                  startTime: startTime,
                  endTime: endTime,
                  shiftDates: [{ startTime: startTime, endTime: endTime }],
                });
              } catch (error) {
                console.log(error);
              }
            }}
          />
        </Grid>
        <Grid item>
          {" "}
          <DebugButtons>{formData}</DebugButtons>
        </Grid>
        <Grid item xs={4}>
          <InputLabel>Start Time*</InputLabel>
          <TimePicker
            sx={{ width: "100%" }}
            value={formData.startTime}
            onChange={(value) => {
              try {
                const newDate = new Date(
                  formData.date.getFullYear(),
                  formData.date.getMonth(),
                  formData.date.getDate(),
                  value.getHours(),
                  value.getMinutes()
                );
                setFormData({
                  ...formData,
                  startTime: newDate,
                  shiftDates: [
                    {
                      startTime: newDate,
                      endTime: formData.shiftDates?.endTime,
                    },
                  ],
                });
              } catch (error) {
                console.log("error setting time", error);
              }
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <InputLabel>End Time*</InputLabel>
          <TimePicker
            sx={{ width: "100%" }}
            value={formData.endTime}
            onChange={(value) => {
              try {
                const newDate = new Date(
                  formData.date.getFullYear(),
                  formData.date.getMonth(),
                  formData.date.getDate(),
                  value.getHours(),
                  value.getMinutes()
                );
                setFormData({
                  ...formData,
                  endTime: newDate,
                  shiftDates: [
                    {
                      endTime: newDate,
                      startTime: formData.shiftDates?.startTime,
                    },
                  ],
                });
              } catch (error) {
                console.log("error setting time", error);
              }
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel>Repeats?</InputLabel>
          <FormGroup>
            <FormControlLabel
              disabled
              label="Recurring"
              control={
                <Radio
                  checked={formData.recurring === true}
                  onChange={(e, value) => {
                    setFormData({ ...formData, recurring: true });
                  }}
                ></Radio>
              }
            />
            <FormControlLabel
              label="One-Time"
              control={
                <Radio
                  checked={formData.recurring === false}
                  onChange={(e, value) => {
                    setFormData({ ...formData, recurring: false });
                  }}
                ></Radio>
              }
            />
          </FormGroup>
        </Grid>
        {formData.recurring && (
          <>
            <Grid item xs={8}>
              <InputLabel>Repeats Every</InputLabel>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <TextField
                  value={formData.repeatEvery}
                  onChange={(e) => {
                    let tempVal = e.target.value;
                    tempVal = tempVal <= 0 ? 0 : tempVal;
                    tempVal = tempVal >= 10 ? 10 : tempVal;
                    setFormData({ ...formData, repeatEvery: tempVal });
                  }}
                  type="number"
                />
                <Autocomplete
                  sx={{ marginLeft: 2 }}
                  fullWidth
                  value={formData.repeatPeriod ? formData.repeatPeriod : null}
                  options={["Week", "Day"]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      placeholder="Address"
                    ></TextField>
                  )}
                  getOptionLabel={(option) => {
                    return option;
                  }}
                  onChange={(e, value) => {
                    setFormData({ ...formData, repeatPeriod: value });
                  }}
                ></Autocomplete>
              </Box>
            </Grid>
            <Grid item xs={4} />
          </>
        )}
        <Grid item xs={12}>
          <InputLabel>Pay Rate</InputLabel>
          <TextField
            value={formData.rate}
            InputProps={{ inputComponent: NumericFormatCustom }}
            onChange={(e) => {
              setFormData({ ...formData, rate: e.target.value });
            }}
          />
        </Grid>
      </FormSection>
      <FormSection currentSection={currentSection} section={4} title={""}>
        <Grid item xs={12}>
          <Typography variant="h6">Location</Typography>
        </Grid>
        <FormDataDisplay
          leftLabel={"Location Type"}
          rightLabel={
            typeOptions[formData.locationType]
              ? typeOptions[formData.locationType]
              : ""
          }
        />
        <FormDataDisplay
          leftLabel={"Business"}
          rightLabel={formData.locationData?.name}
        />
        <FormDataDisplay
          leftLabel={"Address"}
          rightLabel={formData.locationData?.address}
        />
        <Spacer count={3} />
        <Grid item xs={12}>
          <Typography variant="h6">Shift Details</Typography>
        </Grid>
        <FormDataDisplay
          leftLabel={"Start Date"}
          rightLabel={
            getTimestampAsString(formData.startTime, "h:mm aaaa") +
            " " +
            getTimestampAsString(formData.date)
          }
        />
        <FormDataDisplay
          leftLabel={"End Date"}
          rightLabel={
            getTimestampAsString(formData.endTime, "h:mm aaaa") +
            "   " +
            getTimestampAsString(formData.date)
          }
        />

        <FormDataDisplay
          leftLabel={"Repeat"}
          rightLabel={
            formData.recurring
              ? " Every " +
                formData.repeatEvery +
                " " +
                formData.repeatPeriod +
                "(s)"
              : "Never"
          }
        />
        <Spacer count={3} />
        <Grid item xs={12}>
          <Typography variant="h6">Payment Details</Typography>
        </Grid>
        <FormDataDisplay
          leftLabel={"Pay Rate"}
          rightLabel={moneyFormatter.format(formData.rate) + " / hr"}
        />

        <FormDataDisplay
          leftLabel={"Total Nomad Pay"}
          rightLabel={moneyFormatter.format(
            calculateShiftCost(
              formData.startTime,
              formData.endTime,
              formData.rate
            )
          )}
        />
        <FormDataDisplay
          leftLabel={"Shift Hours"}
          rightLabel={
            differenceInMinutes(formData.endTime, formData.startTime) / 60
          }
        />
        <FormDataDisplay
          leftLabel={"Service Fees"}
          rightLabel={moneyFormatter.format(
            calculateShiftCost(
              formData.startTime,
              formData.endTime,
              formData.rate
            ) * serviceFees
          )}
        />
        <FormDataDisplay
          bold
          leftLabel={"Total Cost"}
          rightLabel={moneyFormatter.format(
            calculateShiftCost(
              formData.startTime,
              formData.endTime,
              formData.rate
            ) *
              serviceFees +
              calculateShiftCost(
                formData.startTime,
                formData.endTime,
                formData.rate
              )
          )}
        />
      </FormSection>
      <Spacer />
    </Box>
  );
}
