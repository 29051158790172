import React from "react";
import PropTypes from "prop-types";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useEffect } from "react";
import { defaultChat, defaultNomad } from "../../data/defaultObejcts";
import { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { ReactComponent as BadgeSVG } from "../../assets/badges/Badge.svg";
import { ReactComponent as Badge1SVG } from "../../assets/badges/badge1.svg";
import {
  ArrowDownward,
  ArrowDropDown,
  Chat,
  Favorite,
  Star,
} from "@mui/icons-material";
import CardBackground from "../../Components/CardBackground";
import Spacer from "../../Components/Spacer";
import FormDataDisplay from "../../Components/FormDataDisplay";
import { colours } from "../../config/colours";
import { componentDimensions } from "../../config/dimensions";
import {
  addFirestoreDoc,
  getFirestoreDoc,
  setFirestoreDoc,
} from "../../Functions/firebaseFunctions";
import { useContext } from "react";
import UserDataContext from "../../Context/UserDataContext";
import { doc } from "firebase/firestore";
import { db } from "../../config/firebase";
import DebugButtons from "../../Components/DebugButtons";
const QuestionDisplay = ({ question, response }) => (
  <>
    <Typography fontSize={"14px"} color={colours.black400} fontWeight={400}>
      {question}{" "}
    </Typography>
    <Typography variant="body3">{response}</Typography>
  </>
);
export const getChatId = (user1, user2) => {
  const docId = user1 > user2 ? user1 + user2 : user2 + user1;
  return docId;
};
/**
 * Function that creates a chat document in the 'chats' collection
 * chat ID generated by user IDs
 * @function createNewChat
 * @async
 * @param {object} user1
 * @param {object} user2
 */
export function createNewChat(
  user1,
  user2,
  user1Data,
  user2Data,
  callback = () => {}
) {
  const docId = getChatId(user1, user2);
  console.log(docId);
  console.log(user1, user1Data, user2, user2Data);
  getFirestoreDoc("chats", docId).then((data) => {
    if (data.data) {
      console.log("chat already exists", data);
      callback(docId);
    } else {
      console.log("creating chat with users " + user1 + " and " + user2);
      const chatData = {
        ...defaultChat,
        membersData: {
          [user1]: {
            displayName: user1Data.displayName,
            photoURL:
              user1Data.photoURL === undefined ? null : user1Data.photoURL,
          },
          [user2]: {
            displayName: user2Data.displayName,
            photoURL:
              user2Data.photoURL === undefined ? null : user2Data.photoURL,
          },
        },
        members: [user1, user2],
      };
      console.log(chatData);

      setFirestoreDoc("chats", docId, chatData).then(callback(docId));
    }
  });
}

function NomadApplicationAccount({}) {
  const { applicantid, shiftId } = useParams();
  const [nomadData] = useOutletContext();
  const params = useParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { userID, displayName, photoURL } = useContext(UserDataContext);
  const [profileData, setProfileData] = useState({});
  const [tagged, setTagged] = useState("favourite");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const sampleUserData = {
    starredNomads: [0],
  };
  //dynamically fetch user information based on id
  useEffect(() => {
    if (applicantid) {
      console.log("applicantID", applicantid);
      getFirestoreDoc("users", applicantid).then(({ data }) => {
        setProfileData(data);
      });
    }
  }, [applicantid]);
  const navigate = useNavigate();
  function handleCreateNewChat() {
    createNewChat(
      userID,
      applicantid,
      { displayName: displayName, photoURL: photoURL },
      { displayName: nomadData.displayName, photoURL: nomadData.photoURL },
      (id) => {
        navigate("../../../messages/" + id);
      }
    );

    //console.log(["1", "3", "3"].join("."));
  }
  return (
    <Dialog
      open
      sx={{
        position: "absolute",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClose={() => {
        navigate("../");
      }}
    >
      <DialogTitle sx={{ boxShadow: 5, marginBottom: 1 }}>
        Application for listing
      </DialogTitle>

      <DialogContent>
        <Grid container rowSpacing={componentDimensions.spacing.medium.pixels}>
          <Grid item>
            <Avatar
              sx={{ width: "159px", height: "159px" }}
              src={nomadData.photoURL}
            />
          </Grid>
          <Grid item>
            <Box sx={{ padding: componentDimensions.spacing.small.pixels }}>
              <Stack
                direction={"row"}
                spacing={componentDimensions.spacing.small.pixels}
                sx={{ marginBottom: componentDimensions.spacing.small.pixels }}
              >
                <Typography
                  variant="h5"
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  {nomadData.displayName}
                  <BadgeSVG style={{ marginLeft: "5px" }} />
                  <Badge1SVG style={{ marginLeft: "5px" }} />
                </Typography>

                <Typography
                  sx={{ display: "flex", justifyContent: "center" }}
                  variant="body1"
                >
                  {nomadData.rating}
                  <Star />
                </Typography>
              </Stack>
              <Stack
                spacing={componentDimensions.spacing.small.pixels}
                direction="row"
              >
                {/*  <ButtonGroup variant="contained">
                  <Button
                    onClick={handleClick}
                    variant={tagged ? "text" : "contained"}
                    startIcon={tagged == "favourite" ? <Favorite /> : null}
                  >
                    {" "}
                    {tagged == "favourite" ? "Favourite" : "Make a Decision"}
                  </Button>
                  <Button onClick={handleClick} sx={{ padding: 0 }}>
                    <ArrowDropDown />
                  </Button>
                </ButtonGroup> */}
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      setTagged(tagged == "favourite" ? null : "favourite");
                      handleClose();
                    }}
                  >
                    <ListItemIcon>
                      <Favorite />
                    </ListItemIcon>{" "}
                    Favourite
                  </MenuItem>
                  <MenuItem onClick={handleClose}>...other action</MenuItem>
                  <MenuItem onClick={handleClose}>...other action</MenuItem>
                </Menu>
                <Button
                  variant={"contained"}
                  color={"secondary"}
                  startIcon={<Chat />}
                  onClick={handleCreateNewChat}
                >
                  Message
                </Button>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"h6"}>Bio</Typography>
            <Typography variant={"body4"}>{nomadData.bio}</Typography>

            <Spacer />
            <DebugButtons>{nomadData}</DebugButtons>
            <Typography variant={"h6"}>
              General Application Questions
            </Typography>
            {nomadData.responses?.map((response, index) => {
              return (
                <QuestionDisplay
                  question={nomadData.questions[index]?.prompt}
                  response={response}
                />
              );
            })}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

NomadApplicationAccount.propTypes = {};

export default NomadApplicationAccount;
