import React from 'react';

function Applicants(){
    return(
        <div>
            <h1>Applicants</h1>
        </div>
    )
}

export default Applicants;