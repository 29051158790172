import React from "react";
import PropTypes from "prop-types";
import { Box, Grid, Typography } from "@mui/material";

function FormSection({
  currentSection,
  section,
  title,
  children,
  onSubmit,
  ...otherProps
}) {
  return (
    <>
      {currentSection == section && (
        <Box sx={{ display: "flex" }} {...otherProps}>
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <Typography fontSize={"20px"}>{title}</Typography>
            </Grid>
            {children}
          </Grid>
        </Box>
      )}
    </>
  );
}

FormSection.propTypes = {};

export default FormSection;
